import React, { useState } from "react";
import {
  ExportButton,
  FilterButton,
  FilterSection,
  LoadingWrapper,
  NODATA,
  OrangeButton,
  SubHeader,
} from "../../style/Gobalstyle";
import IntlMessage from "../../Utils/IntlMessage";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ExportFeature } from "../../Utils/ExportFiles";
import { useSelector } from "react-redux";
import ToggleSwitch from "../../components/ToggleSwitch";
import { CircularProgress } from "@mui/material";
import MenuTable from "../Menu/MenuTable";
import DragDropTable from "../Menu/SectionItem/DragDropTable";
import { CardWrapper } from "../Menu/MenuStyle";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { advertisementList, changeAdBannerStatus, deleteAdBanner, updateAdBannerPosition } from "../../services/Collection";
import { useCallback } from "react";
import DragDropAdvertisement from "./DragDropAdvertisement";
import update from "immutability-helper";
import Delete from "../../components/Delete";

export default function Advertisement() {
  const navigate = useNavigate();
  const [appliedFilterCount, setAppliedFilterCount] = useState([]);
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const [DragDropIsEnable, setDragDropIsEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [advertisementData, setAdvertisementData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataPayload, setDataPayload] = useState(null);



  const getWord = async (word) => {
    // setLoading(true);
    // const req = {
    //   search: word,
    //   pageNumber: pageNumber,
    // };
    // let res = await searchSectionItem(req);
    // if (res.status === 200) {
    //   setsectionItemData(res.data);
    //   setsectionItemPage(res.extraData);
    //   setLoading(false);
    // } else {
    //   const message = getErrorMessage(res, "Failed to connection");
    //   toast.error(message, { theme: "colored" });
    //   setLoading(false);
    // }
  };

  const OpenFilterUser = () => {
    // setOpenFilterSection(true);
  };

  const ApiCall = async (e) => {
    // if (e) {
    //   setLoading(true);
    //   const req = {
    //     pageNumber: 0,
    //   };
    //   let res = await sectionItemListing(req);
    //   if (res.status === 200) {
    //     setsectionItemData(res.data);
    //     setsectionItemPage(res.extraData);
    //     setAllRecord(res.totalRecords);
    //     setDragDropIsEnable(e);
    //     setLoading(false);
    //   } else {
    //     const message = getErrorMessage(res, "Failed to connection");
    //     toast.error(message);
    //     setLoading(false);
    //   }
    // } else {
    //   setDragDropIsEnable(e);
    //   fetchData();
    // }
  };

  const infoMenu = (data) => {
    // setDataPayload(data);
    // setOpenInfo(true);
  };

  const editMenu = (data) => {
    navigate(`/aglut/advertisement/edit:${data.id}`, { state: data });
    // setDataPayload(data);
    // setOpenEdit(true);
  };

  const deletingMenu = (data) => {
    setDataPayload(data);
    setOpenDelete(true);
  };

  const handleConfirm = async (id) => {
    // setLoading(true);
    let res = await deleteAdBanner(id);
    if (res.status === 200) {
      setLoading(false);
      setOpenDelete(false);
      getAdvertisementList();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      setOpenDelete(false);
      toast.error(message);
    }
  };


  const activeMenu = async (e, name, id) => {
    console.log(e, name,id , "asdcohfdd")
    let req = {
      banner_id: id,
    };
    let res = await changeAdBannerStatus(req);
    if (res.status === 200) {
      setLoading(false);
      getAdvertisementList();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      toast.error(message);
    }
  };

  const updateDropPosition = async (dragIndex, DropIndex, data, item) => {
    setLoading(true);
    let req = {
      banner_id: item.id,
      order_number: data.order_number,
    };
    let res = await updateAdBannerPosition(req);
    if (res.status === 200) {
      getAdvertisementList();
    } else {
      getAdvertisementList();
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  const moveCard = useCallback((dragIndex, DropIndex, el, items) => {
    updateDropPosition(dragIndex, DropIndex, el, items);

    setAdvertisementData((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [DropIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderMenuCard = useCallback((cardData, index) => {
    return (
      <DragDropAdvertisement
        key={cardData?.id}
        index={index}
        id={cardData?.id}
        el={cardData}
        moveCard={moveCard}
        infoMenu={infoMenu}
        editMenu={editMenu}
        deletingMenu={deletingMenu}
        activeMenu={activeMenu}
      />
    );
  }, []);

  const getAdvertisementList = async () => {
    setLoading(true);
    let res = await advertisementList();
    if (res.status === 200) {
      setAdvertisementData(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAdvertisementList();
  }, []);

  return (
    <div>
      {openDelete && (
        <Delete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          payload={dataPayload}
          handleConfirm={handleConfirm}
        />
      )}

      <SubHeader>
        <p>
          {/* <IntlMessage id="Menu.sectionItem.heading" /> */}
          Advertisement banners
        </p>

        <OrangeButton onClick={() => navigate(`/aglut/advertisement/create`)}>
          <IntlMessage id="button.CREATE" />
        </OrangeButton>
      </SubHeader>

      <BoxContainer>
        {/* <FilterSection>
          <ShowButton></ShowButton>
          <SearchFEwrapper>
            <SearchButton>
              <i className="icon-Search" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => getWord(e.target.value)}
              />
            </SearchButton>
            <FilterWrapper>
              <FilterButton onClick={OpenFilterUser}>
                {appliedFilterCount.length > 0 && (
                  <RedDot>{appliedFilterCount.length}</RedDot>
                )}
                <i className="icon-Filter" />
                <p>
                  <IntlMessage id="button.Filters" />
                </p>
              </FilterButton>

              <ExportButton
                onClick={() =>
                  ExportFeature(
                    `https://aqlutstorage.blob.core.windows.net/exportfile/itemslist${RestaurentID}.xlsx`
                  )
                }
              >
                <i className="icon-Export" />
                <p>
                  <IntlMessage id="button.Export" />
                </p>
              </ExportButton>
            </FilterWrapper>
          </SearchFEwrapper>
        </FilterSection> */}

        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <CardWrapper>
            {advertisementData.map((el, index) => renderMenuCard(el, index))}
          </CardWrapper>
        )}
      </BoxContainer>
    </div>
  );
}

const BoxContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px 50px 30px;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
`;

const RedDot = styled.div`
  width: 18px;
  height: 18px;
  background: red;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
`;

const ShowButton = styled.div`
  display: flex;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
    width: 40px;
    margin-top: 25px;
  }

  input {
    width: 51px;
    height: 23px;
    background: #ffffff;
    margin: 20px 6px 0 6px;
    padding: 0 5px;
    border: 1px solid #e8e8e8;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const SearchButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 13px 10px;
  margin: 0 6px 0 6px;
  cursor: pointer;

  i {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  input {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 5px 0 6px;
    opacity: 0.9;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 745px) {
    margin: 10px 0px;
  }
`;

const SearchFEwrapper = styled.div`
  display: flex;
  @media (max-width: 745px) {
    display: unset;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MessageForDragDropFeature = styled.p`
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #f55a2c;
  padding: 10px 0 0 0;
  > div {
    margin: 0 10px;
  }
`;
