import React from "react";
import {
  BlackButton,
  BoxContainer,
  OrangeButton,
  SubHeader,
  WhiteButton,
} from "../../style/Gobalstyle";
import {
  InputPageWrapper,
  MiddleContent,
  UpdateImageSection,
} from "../Menu/MenuStyle";
import IntlMessage from "../../Utils/IntlMessage";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import InputField from "../../validation/InputField";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createAdvertisementBanner } from "../../services/Collection";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import * as yup from "yup";

export default function CreateAdvertisement() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [htmlEditor, setHtmlEditor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageRatio, setImageRatio] = useState(null);

  // Yup is used for validation.
  let schema = yup.object().shape({
    title: yup.string().required("Please enter tittle for advertisement"),
    urllink: yup.string().when("actionType", {
      is: "url",
      then: yup
        .string()
        .required("Please enter Url which you have to redirect at user side"),
    }),
  });

  const customConfig = {
    toolbar: [
      "undo",
      "redo",
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
  };

  function gcd(u, v) {
    if (u === v) return u;
    if (u === 0) return v;
    if (v === 0) return u;

    if (~u & 1)
      if (v & 1) return gcd(u >> 1, v);
      else return gcd(u >> 1, v >> 1) << 1;

    if (~v & 1) return gcd(u, v >> 1);

    if (u > v) return gcd((u - v) >> 1, v);

    return gcd((v - u) >> 1, u);
  }

  function ratio(w, h) {
    var d = gcd(w, h);
    return [w / d, h / d];
  }

  const validateRatio = (files) => {
    var file, img;
    if ((file = files)) {
      var reader = new FileReader();
      reader.onload = (r) => {
        img = new Image();
        img.src = r.target.result;
        img.onload = function () {
          var height = this.height;
          var width = this.width;
          let a = ratio(width, height);
          let passCase = [16, 9];

          if (a.toString() === passCase.toString()) {
            setImageRatio(null);
            setImage(files);
            setPreview(URL.createObjectURL(files));
          } else {
            let obj = {
              imageMsg: `Image which you selected is in Ratio of Width : ${width} X Height : ${height} = Ratio : [${a}]`,
              forCorrectMsg: `For Perfect Advertisement Banner you have to upload image of Ratio [16, 9]`,
            };

            setImageRatio(obj);
            setPreview(URL.createObjectURL(files));
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const changePhoto = (files) => {
    validateRatio(files);
  };

  const ValuesFromEditor = (event, editor) => {
    const data = editor.getData();
    setHtmlEditor(data);
    console.log({ event, editor, data });
  };

  const checkingValidation = (values) => {
    if (image) {
      handleSubmit(values);
    } else {
      toast.error("Image is required");
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    let fd = new FormData();
    fd.append("title", values?.title);
    fd.append("action_type", values?.actionType === "description" ? 2 : 1);
    if (values?.actionType === "url") {
      fd.append("action_url", values?.urllink);
    }
    if (values?.actionType === "description") {
      fd.append("action_description", htmlEditor);
    }
    if (image) {
      fd.append("image_url", image, image?.name);
    }

    let res = await createAdvertisementBanner(fd);
    if (res.status === 200) {
      setLoading(false);
      navigate(-1);
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      toast.error(message);
    }
  };

  return (
    <div>
      <SubHeader>
        <p>Create Advertisement</p>
      </SubHeader>

      <BoxContainer>
        <Formik
          initialValues={{
            title: "",
            actionType: "url",
            urllink: "",
          }}
          validationSchema={schema}
          onSubmit={checkingValidation}
          render={({ values, setFieldValue }) => (
            <Form>
              <UpdateImageSection>
                <Upload>
                  {preview ? (
                    <img src={preview} alt="Profile" />
                  ) : (
                    <>
                      <input
                        type="file"
                        accept=".jpg , .png"
                        onChange={(e) => changePhoto(e?.target?.files[0], e)}
                      />
                      <i className="icon-Upload" />
                    </>
                  )}
                </Upload>
                <div>
                  <WhiteButton>
                    <input
                      type="file"
                      accept=".jpg , .png , .jpeg"
                      onChange={(e) => changePhoto(e?.target?.files[0], e)}
                    />
                    <IntlMessage id="button.CHANGEPHOTO" />
                  </WhiteButton>
                </div>
              </UpdateImageSection>
              {imageRatio && (
                <div>
                  <ImageRationWarning>
                    {imageRatio?.imageMsg}
                  </ImageRationWarning>

                  <ImageRationWarning>
                    {imageRatio?.forCorrectMsg}
                  </ImageRationWarning>
                </div>
              )}

              <InputPageWrapper>
                <InputWrap>
                  <section>
                    <label>Title</label>
                  </section>

                  <Field
                    dir="ltr"
                    type="text"
                    placeholder="Title"
                    name="title"
                    component={InputField}
                  />
                </InputWrap>
                <ActionTypeWrapper>
                  <section>
                    <label className="label">Action Type</label>
                  </section>

                  <ActionTypeStyling>
                    <section>
                      <Field
                        value="url"
                        checked={values?.actionType == "url" ? true : false}
                        dir="ltr"
                        type="radio"
                        name="actionType"
                        component={InputField}
                      />
                      <label>URL</label>
                    </section>
                    <section>
                      <Field
                        value="description"
                        checked={values?.actionType != "url" ? true : false}
                        dir="ltr"
                        type="radio"
                        name="actionType"
                        component={InputField}
                      />
                      <label>Description</label>
                    </section>
                  </ActionTypeStyling>
                </ActionTypeWrapper>
                {values?.actionType == "url" && (
                  <InputWrap>
                    <section>
                      <label>URL</label>
                    </section>

                    <Field
                      dir="ltr"
                      type="text"
                      placeholder="URL"
                      name="urllink"
                      component={InputField}
                    />
                  </InputWrap>
                )}
              </InputPageWrapper>
              {values?.actionType != "url" && (
                <>
                  <InputWrap>
                    <section style={{ margin: "20px 0 5px 0" }}>
                      <label>Description</label>
                    </section>
                  </InputWrap>
                  <EditorWrapper>
                    <CKEditor
                      editor={ClassicEditor}
                      config={customConfig}
                      data="<p>Write about your advertisement product...</p>"
                      onChange={ValuesFromEditor}
                      // onReady={(editor) => {
                      //   // You can store the "editor" and use when it is needed.
                      //   console.log("Editor is ready to use!", editor);
                      // }}

                      // onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                      // }}
                      // onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                      // }}
                    />
                  </EditorWrapper>
                </>
              )}

              <MiddleContent style={{ marginTop: "20px" }}>
                {loading ? (
                  <CircularProgress sx={{ color: "#f55a2c" }} />
                ) : (
                  <BlackButton>
                    <IntlMessage id="button.CREATE" />
                  </BlackButton>
                )}
              </MiddleContent>
            </Form>
          )}
        />
      </BoxContainer>
    </div>
  );
}

const Upload = styled.div`
  width: 220px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  input {
    width: 220px;
    height: 120px;
    opacity: -1;
    position: absolute;
  }

  img {
    width: 220px;
    height: 120px;
    position: absolute;
    border: 1px solid #e6e6f1;
    border-radius: 20px;
  }
`;

const EditorWrapper = styled.div`
  .ck.ck-toolbar {
    border: 1px solid #f0f1f7 !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .ck-editor__editable[role="textbox"] {
    min-height: 200px;
    border: 1px solid #f0f1f7 !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
`;

const InputWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    width: 100%;
  }

  i {
    font-size: 18px;
  }

  label {
    margin: 0 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
  }

  input {
    width: 100%;
    height: 42px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 0 20px;
    margin: 5px 0 0 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  input:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }

  textarea {
    width: 100%;
    height: 58px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 8px 0 0 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  textarea:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }

  select {
    width: 100%;
    height: 42px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 0 10px;
    margin: 5px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  select:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }
`;

const ActionTypeWrapper = styled.div`
  .label {
    margin: 3px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    // line-height: 15px;
    color: #000000;
  }
`;

const ActionTypeStyling = styled.div`
  display: flex;
  gap: 50px;

  div {
    width: unset;
  }
  section {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    label {
      margin: 3px 0px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      color: #000000;
    }
  }

  input {
    width: 20px;
    height: 20px;
    // padding: 0 20px;
    // margin: 5px 0 0 0;
  }
`;

const ImageRationWarning = styled.li`
  color: red;
  padding: 0 0 10px 5px;
  font-family: "Montserrat";
  font-weight: 500px;
`;
