export const TickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 -960 960 960"
      width="20px"
      fill="#fff"
    >
      <path
        d="M389-267 195-460l51-52 143 143 325-324 51 51-376 375Z"
        stroke="#fff"
        stroke-width="50"
      />
    </svg>
  );
};
