import React, { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  BoxContent,
  Cancel,
  CreateWrapper,
  InputPageWrapper,
  InputWrap,
  MiddleContent,
  SelectInput,
} from "../MenuStyle";
import { Field, Form, Formik } from "formik";
import IntlMessage from "../../../Utils/IntlMessage";
import InputField from "../../../validation/InputField";
import { DependentField } from "../../../validation/DependentField";
import {
  BlackButtonMobile,
  LoadingWrapper,
  OrangeButtonForForm,
  SelectIcon,
} from "../../../style/Gobalstyle";
import TextArea from "../../../validation/TextArea";
import { CircularProgress } from "@mui/material";
import {
  alllistingSection,
  editSubSectionMenu,
  menuListing,
} from "../../../services/Collection";
import * as yup from "yup";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import styled from "styled-components";
import { CompressOutlined } from "@mui/icons-material";

export default function EditSubSection({
  open,
  handleClose,
  payload,
  fetchdata,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);
  const [menusList, setMenusList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  let sectionType = [
    {
      name: "Main Section",
      type: "1",
    },
    {
      name: "Sub Section",
      type: "2",
    },
  ];

  const changePhoto = (file) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const initialValues = useMemo(() => {
    if (payload) {
      return {
        name: payload.section_name,
        nameAr: payload.ar_section_name,
        sectiontype:
          payload.section_type === "1"
            ? {
                name: "Main Section",
                type: "1",
              }
            : {
                name: "Sub Section",
                type: "2",
              },
        // menus: {
        //   name: payload?.menu_name,
        //   ar_name: payload?.menu_name_ar,
        //   id: payload?.menu_id,
        // },
        sections: {
          section_name: payload?.main_section_name,
          ar_name: payload?.main_section_name_arb,
          id: payload?.main_section_id,
        },
        description: payload.desc,
        descriptionAr: payload.ar_desc,
      };
    }

    return {
      name: "",
      nameAr: "",
      menus: "",
      sectiontype: "",
      // menusAr: "",
      // sortOrderID: "",
      description: "",
      descriptionAr: "",
    };
  }, [payload]);

  const EnRegex = "([a-zA-Z]s*)+";
  const ArRegex = "[\u0600-\u06FF]";
  // Yup is used for validation.
  let schema = yup.object().shape({
    name: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter menu name"),
    nameAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter menu name"),
    // sections: yup.object().required("required"),
    // menusAr: yup.object().required("required"),
    // sortOrderID: yup.number().required("required"),
    description: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter menu description"),
    descriptionAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter menu description"),
  });

  // This function is called to submit the form.

  const handleSubmit = async (values) => {
    // console.log({main_section: values.sections.id, ...values}, "valuessssssssss");
    // return;
    setLoading(true);
    var fd = new FormData();
    fd.append("section_type", values?.sectiontype?.type);
    fd.append("section_id", payload.id);
    fd.append("section_name", values.name);
    fd.append("ar_section_name", values.nameAr);
    fd.append("desc", values.description);
    fd.append("ar_desc", values.descriptionAr);
    fd.append("menu_id", values?.sectiontype?.type === "1" ? values?.menus?.id : payload.menu_id);
    fd.append(
      "main_section_id",
      values?.sectiontype?.type === "1" ? null : values.sections.id
    );

    if (image) {
      fd.append("image", image, image?.name);
    }

    let res = await editSubSectionMenu(fd);
    if (res.status === 200) {
      setLoading(false);
      handleClose();
      fetchdata();
      toast.info(res.message);
    } else {
      handleClose();
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getAllSections = async () => {
    let res = await alllistingSection();
    if (res.status === 200) {
      console.log(res, "stattttttttttttttt")
      setSectionList(res.data);
    }
  };

  const getAllMenus = async () => {
    let res = await menuListing();
    if (res.status === 200) {
      setMenusList(res.data);
    }
  };

  useEffect(() => {
    getAllMenus();
    getAllSections();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <CreateWrapper>
          {!loading ? (
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
              render={({ values, setFieldValue }) => (
                <Form>
                  <BoxContent>
                    <MiddleContent>
                      <header>
                        {/* <IntlMessage id="Menu.sectionItem.EditSection" /> */}
                        Edit Sub Section
                      </header>

                      {values && values?.sectiontype?.type === "1" && (
                        <Upload>
                          {preview ? (
                            <>
                              <img src={preview} alt="Profile" />

                              <RemoveImage style={{ width: "220px" }}>
                                <i className="icon-Cross" />
                                <input
                                  type="file"
                                  accept=".jpg , .png"
                                  onChange={(e) =>
                                    changePhoto(e?.target?.files[0])
                                  }
                                />
                              </RemoveImage>
                            </>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept=".jpg , .png"
                                onChange={(e) =>
                                  changePhoto(e?.target?.files[0])
                                }
                              />
                              <i className="icon-Upload" />
                            </>
                          )}
                        </Upload>
                      )}
                    </MiddleContent>

                    <InputWrap dir="ltr">
                      <section>
                        <label>Section Type</label>
                      </section>

                      <Field
                        name="sectiontype"
                        component={DependentField}
                        getChanges={() => function Close() {}}
                        options={sectionType}
                        // defaultValue={{
                        //   name: "Main Section",
                        //   type: 1
                        // }}
                        getOptionLabel={(option) =>
                          option ? option?.name : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Section type"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </InputWrap>

                    <InputPageWrapper>
                      <InputWrap dir="ltr">
                        <section>
                          <label>Name</label>
                        </section>

                        <Field
                          type="text"
                          name="name"
                          placeholder="Name"
                          component={InputField}
                        />
                      </InputWrap>

                      <InputWrap dir="rtl">
                        <section>
                          <label> اسم</label>
                        </section>

                        <Field
                          type="text"
                          name="nameAr"
                          placeholder="اسم"
                          component={InputField}
                        />
                      </InputWrap>

                      {values && values?.sectiontype?.type === "1" ? (
                        <>
                          <InputWrap dir="ltr">
                            <section>
                              <label>Menus</label>
                            </section>

                            <Field
                              name="menus"
                              component={DependentField}
                              getChanges={() => function Close() {}}
                              options={menusList}
                              getOptionLabel={(option) =>
                                option ? option?.name : ""
                              }
                              renderInput={(params) => (
                                <div
                                  ref={params.InputProps.ref}
                                  style={{ position: "relative" }}
                                >
                                  <SelectInput
                                    placeholder="Menus"
                                    Dir={direction}
                                    type="text"
                                    {...params.inputProps}
                                  />

                                  <SelectIcon
                                    className="icon-DropDownArrow"
                                    dir="ltr"
                                  />
                                </div>
                              )}
                            />
                          </InputWrap>

                          <InputWrap dir="rtl">
                            <section>
                              <label> القوائم</label>
                            </section>
                            <Field
                              name="menus"
                              component={DependentField}
                              getChanges={() => function Close() {}}
                              options={menusList}
                              getOptionLabel={(option) =>
                                option ? option.ar_name : ""
                              }
                              renderInput={(params) => (
                                <div
                                  ref={params.InputProps.ref}
                                  style={{ position: "relative" }}
                                >
                                  <SelectInput
                                    placeholder="Menus"
                                    Dir={direction}
                                    type="text"
                                    {...params.inputProps}
                                  />

                                  <SelectIcon
                                    className="icon-DropDownArrow"
                                    dir="rtl"
                                  />
                                </div>
                              )}
                            />
                          </InputWrap>
                        </>
                      ) : (
                        <>
                          <InputWrap dir="ltr">
                            <section>
                              <label>Sections</label>
                            </section>

                            <Field
                              name="sections"
                              component={DependentField}
                              getChanges={() => function Close() {}}
                              options={sectionList}
                              getOptionLabel={(option) =>
                                option ? option?.section_name : ""
                              }
                              renderInput={(params) => (
                                <div
                                  ref={params.InputProps.ref}
                                  style={{ position: "relative" }}
                                >
                                  <SelectInput
                                    placeholder="Sections"
                                    Dir={direction}
                                    type="text"
                                    {...params.inputProps}
                                  />
                                  <SelectIcon
                                    className="icon-DropDownArrow"
                                    dir="ltr"
                                  />
                                </div>
                              )}
                            />
                          </InputWrap>

                          <InputWrap dir="rtl">
                            <section>
                              <label>الأقسام</label>
                            </section>
                            <Field
                              name="sections"
                              component={DependentField}
                              getChanges={() => function Close() {}}
                              options={menusList}
                              getOptionLabel={(option) =>
                                option
                                  ? option.ar_section_name
                                    ? option.ar_section_name
                                    : option.ar_name
                                  : ""
                              }
                              renderInput={(params) => (
                                <div
                                  ref={params.InputProps.ref}
                                  style={{ position: "relative" }}
                                >
                                  <SelectInput
                                    placeholder="الأقسام"
                                    Dir={direction}
                                    type="text"
                                    {...params.inputProps}
                                  />

                                  <SelectIcon
                                    className="icon-DropDownArrow"
                                    dir="rtl"
                                  />
                                </div>
                              )}
                            />
                          </InputWrap>
                        </>
                      )}

                      <InputWrap dir="ltr">
                        <section>
                          <label>Description</label>
                        </section>

                        <Field
                          type="description"
                          name="description"
                          placeholder="Description"
                          component={TextArea}
                        />
                      </InputWrap>

                      <InputWrap dir="rtl">
                        <section>
                          <label> وصف</label>
                        </section>

                        <Field
                          type="description"
                          name="descriptionAr"
                          placeholder="وصف"
                          component={TextArea}
                        />
                      </InputWrap>
                    </InputPageWrapper>
                  </BoxContent>
                  <MiddleContent style={{ marginTop: "20px" }}>
                    <div style={{ display: "flex" }}>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        <IntlMessage id="button.close" />
                      </BlackButtonMobile>
                      <OrangeButtonForForm type="submit">
                        <IntlMessage id="button.SUBMIT" />
                      </OrangeButtonForForm>
                    </div>
                  </MiddleContent>
                </Form>
              )}
            />
          ) : (
            <LoadingWrapper>
              <CircularProgress sx={{ color: "#f55a2c" }} />
            </LoadingWrapper>
          )}
        </CreateWrapper>
      </Dialog>
    </div>
  );
}

export const Upload = styled.div`
  width: 220px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  input {
    width: 220px;
    height: 120px;
    opacity: -1;
    position: absolute;
  }

  img {
    width: 220px;
    height: 120px;
    position: absolute;
    border: 1px solid #e6e6f1;
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const RemoveImage = styled.div`
  position: relative;
  width: 220px;
  height: 120px;
  i {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 15px;
    color: red;
    background: #000;
    border-radius: 50%;
    padding: 7px;
    // margin: 5px;
    cursor: pointer;
  }

  input {
    position: absolute;
  }
`;
