import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  BoxContainer,
  FilterButton,
  FilterSection,
  OrangeButton,
  SubHeader,
  LoadingWrapper,
  NODATA,
  ExportButton,
} from "../../../style/Gobalstyle";
import MenuTable from "../MenuTable";
import {
  changeStatusItemModule,
  deleteSectionItem,
  getFilterDataSectionItem,
  searchSectionItem,
  sectionItemListing,
  updateSectionItemPosition,
} from "../../../services/Collection";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import PaginationRounded from "../../../components/PaginationRounded";
import { useSelector } from "react-redux";
import DragDropTable from "./DragDropTable";
import { useCallback } from "react";
import update from "immutability-helper";
import IntlMessage from "../../../Utils/IntlMessage";
import { ExportFeature } from "../../../Utils/ExportFiles";
import FilterSectionItem from "./FilterSectionItem";
import ToggleSwitch from "../../../components/ToggleSwitch";
import ViewSectionItem from "./ViewSectionItem";
import Delete from "../../../components/Delete";
import DrapDropSectionItem from "./DrapDropSectionItem";

export default function SectionItem() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sectionItemData, setsectionItemData] = useState([]);
  const [sectionItemPage, setsectionItemPage] = useState(null);
  const [pageNumber, setPagenumber] = useState(1);
  const [filterPageNumber, setFilterPagenumber] = useState(1);

  const [perPageListing, setPerPageListing] = useState(15);
  const [allRecord, setAllRecord] = useState(null);
  const [openFilterSection, setOpenFilterSection] = useState(false);
  const [DragDropIsEnable, setDragDropIsEnable] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [appliedFilterCount, setAppliedFilterCount] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [openViewSectionItem, setOpenViewSectionItem] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const isPosEnable = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.pos_integration_status
  );

  const menuPermission = useSelector(
    (state) => state?.loginAuth?.permissions?.menu
  );
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );

  const manupilateData = (data) => {
    let tempArray = [];
    let preId = [];
    data?.forEach((element) => {
      if (!preId?.includes(element?.sectionId)) {
        preId.push(element?.sectionId);
        let filteredList = data?.filter(
          (ele) => ele?.sectionId === element?.sectionId
        );
        tempArray.push(filteredList);
      }
    });
    return tempArray;
  };

  const fetchData = async () => {
    setLoading(true);
    const req = {
      perPage: 9999999,
      pageNumber: pageNumber,
    };
    let res = await sectionItemListing(req);
    if (res?.status === 200) {
      let result = manupilateData(res?.data);
      setsectionItemData(result);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getWord = async (word) => {
    setLoading(true);
    const req = {
      search: word,
      pageNumber: pageNumber,
    };

    let res = await searchSectionItem(req);
    if (res?.status === 200) {
      let result = manupilateData(res?.data);
      setsectionItemData(result);
      // setsectionItemPage(res?.extraData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message, { theme: "colored" });
      setLoading(false);
    }
  };

  const updateDropPosition = async (
    dragIndex,
    DropIndex,
    data,
    item,
    perPageListing
  ) => {
    // setLoading(true);
    let req = {
      section_id: data?.sectionId,
      source_item_id: item?.id,
      destination_item_id: data?.id,
    };
    let res = await updateSectionItemPosition(req);
    if (res.status === 200) {
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  const setTimeRef = useRef();
  const moveCard = useCallback(
    (dragIndex, hoverIndex, body, item, perPageListing) => {
      setsectionItemData((prevCards) => {
        let index;
        prevCards?.forEach((e, i) => {
          if (e?.find((ele) => ele?.id === body?.id)) {
            index = i;
          }
        });
        const updatedArr = update(prevCards[index], {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[index][dragIndex]],
          ],
        });
        const filterUndefined = updatedArr?.filter((ele) => ele?.id);
        let result = [...prevCards];
        result[index] = filterUndefined;
        return result;
      });
      if (setTimeRef?.current) clearTimeout(setTimeRef?.current);
      setTimeRef.current = setTimeout(
        () =>
          updateDropPosition(dragIndex, hoverIndex, body, item, perPageListing),
        500
      );
    },
    []
  );

  const OpenFilterUser = () => {
    setOpenFilterSection(true);
  };

  const filterValues = async (values) => {
    setFilterLoading(true);
    setAppliedFilter(values);
    var count = [];
    for (const val in values) {
      if (values[val]) {
        count.push(`${val}: ${values[val]}`);
      }
    }
    setAppliedFilterCount(count);

    const params = new URLSearchParams();
    if (values?.menus) params.append("menu_id", values?.menus?.id);
    if (values?.sections) params.append("section_id", values?.sections?.id);
    if (values?.markSignature)
      params.append("signature", values?.markSignature);
    if (values?.markNewSection) params.append("new", values?.markNewSection);
    if (values?.recommended)
      params.append("recommended_id", values?.recommended?.id);
    if (values?.price) params.append("price", values?.price);
    if (values?.price && values?.symbol)
      params.append("price_action", values?.symbol?.id);
    if (values?.allergies && values?.allergies)
      params.append("allergies", values?.allergies?.id);
    if (values) params.append("perPage", perPageListing);
    if (values) params.append("pageNumber", filterPageNumber);

    let res = await getFilterDataSectionItem(params.toString());
    if (res?.status === 200) {
      let result = manupilateData(res?.data);
      setsectionItemData(result);
      setFilterLoading(false);
      setOpenFilterSection(false);
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setFilterLoading(false);
    }
  };

  const resetFilters = () => {
    setAppliedFilter(null);
    setAppliedFilterCount([]);
    setOpenFilterSection(false);
    fetchData();
  };

  const [actionTiggerData, setActionTiggerData] = useState(null);
  const handleActionSection = (data) => {
    setActionTiggerData(data);
    setOpenViewSectionItem(true);
  };

  const handleConfirm = async (id) => {
    setLoading(true);

    let res = await deleteSectionItem(id);
    if (res.status === 200) {
      setLoading(false);
      setOpenDelete(false);
      setDeleteItem(null);
      fetchData();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      setOpenDelete(false);
      setDeleteItem(null);
      toast.error(message);
    }
  };

  const handleEditItem = (ele) => {
    navigate(`/aglut/menu/sectionItems/edit/${ele?.id}`);
  };

  const handleDeleteItem = (ele) => {
    setOpenDelete(true);
    setDeleteItem(ele);
  };

  const handleStatusUpdate = async (id, handleLoading) => {
    let req = {
      itemId: id,
    };

    let res = await changeStatusItemModule(req);
    if (res.status === 200) {
      toast.info(res.message);
      let updateSectionItemData = sectionItemData?.map((ele) => {
        let updatedVal = ele?.map((val) => {
          if (val?.id === id) {
            let updatedVal = val;
            updatedVal["status"] = !updatedVal?.status;
            return updatedVal;
          } else {
            return val;
          }
        });
        return updatedVal;
      });
      setsectionItemData(updateSectionItemData);
      handleLoading();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      handleLoading();
    }
  };

  const renderMenuCard = useCallback(
    (cardData, index) => {
      return (
        <DrapDropSectionItem
          key={cardData?.id}
          index={index}
          id={cardData?.id}
          ele={cardData}
          moveCard={moveCard}
          Status={handleStatusUpdate}
          // infoMenu={infoMenu}
          editMenu={handleEditItem}
          deletingMenu={handleDeleteItem}
          // activeMenu={activeMenu}
          viewitem={handleActionSection}
        />
      );
    },
    [sectionItemData]
  );

  //Runs only on the first render
  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <div>
      {openViewSectionItem && (
        <ViewSectionItem
          open={openViewSectionItem}
          handleClose={() => setOpenViewSectionItem(false)}
          payload={actionTiggerData}
        />
      )}

      {openFilterSection && (
        <FilterSectionItem
          open={openFilterSection}
          handleClose={() => setOpenFilterSection(false)}
          filterValues={filterValues}
          appliedFilter={appliedFilter}
          filterLoading={filterLoading}
          resetFilters={resetFilters}
        />
      )}

      {openDelete && (
        <Delete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          payload={deleteItem}
          handleConfirm={handleConfirm}
        />
      )}
      <SubHeader>
        <p>
          <IntlMessage id="Menu.sectionItem.heading" />
        </p>
        {menuPermission && (
          <OrangeButton
            onClick={() => navigate(`/aglut/menu/sectionItems/create`)}
          >
            <IntlMessage id="button.CREATE" />
          </OrangeButton>
        )}
      </SubHeader>

      <FilterSection style={{ justifyContent: "end" }}>
        <SearchFEwrapper>
          <SearchButton>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => getWord(e.target.value)}
            />
          </SearchButton>
          <FilterWrapper>
            <FilterButton onClick={OpenFilterUser}>
              {appliedFilterCount.length > 0 && (
                <RedDot>{appliedFilterCount.length}</RedDot>
              )}
              <i className="icon-Filter" />
              <p>
                <IntlMessage id="button.Filters" />
              </p>
            </FilterButton>

            <ExportButton
              onClick={() =>
                ExportFeature(
                  `${aqlutstorage}${containerExport}itemslist${RestaurentID}.xlsx`
                )
              }
            >
              <i className="icon-Export" />
              <p>
                <IntlMessage id="button.Export" />
              </p>
            </ExportButton>
          </FilterWrapper>
        </SearchFEwrapper>
      </FilterSection>

      {sectionItemData?.map((section) => (
        <BoxContainer style={{ minHeight: "100%" }}>
          {loading ? (
            <LoadingWrapper>
              <CircularProgress sx={{ color: "#f55a2c" }} />
            </LoadingWrapper>
          ) : sectionItemData?.length === 0 ? (
            <NODATA>
              <IntlMessage id="noData" />
            </NODATA>
          ) : (
            <>
              <SubHeader style={{ textTransform: "capitalize" }}>
                <p>{section?.[0]?.section_name}</p>
              </SubHeader>
              <SectionItemBox>
                {section?.map((ele, index) => renderMenuCard(ele, index))}
              </SectionItemBox>
            </>
          )}
        </BoxContainer>
      ))}
    </div>
  );
}

const SectionItemBox = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  gap: 15px;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;

  @media (max-width: 1340px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 530px) {
    grid-template-columns: auto;
    justify-content: center;
  }

  .itemCard {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 12px 16px;
    box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    gap: 16px;
    align-items: center;
    overflow: hidden;

    img {
      border: 0.5px solid rgb(0 0 0 / 15%);
      border-radius: 8px;
      width: 105px;
      height: 105px;
      object-fit: cover;
      background: #fff;
      @media (max-width: 768px) and (min-width: 530px) {
        display: none;
      }
    }
    .itemDetails {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgb(12, 0, 5);
        width: 175px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #575962;
        width: 175px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      label {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgb(21, 7, 125);
        cursor: pointer;
      }
      .action {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        span {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 28px;
          border: 0.5px solid #00000036;
          padding: 5px;
        }
        i {
          cursor: pointer;
        }
      }
    }
  }
`;

const RedDot = styled.div`
  width: 18px;
  height: 18px;
  background: red;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
`;

export const ShowButton = styled.div`
  display: flex;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
    width: 40px;
    margin-top: 25px;
  }

  input {
    width: 51px;
    height: 23px;
    background: #ffffff;
    margin: 20px 6px 0 6px;
    padding: 0 5px;
    border: 1px solid #e8e8e8;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const SearchButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 13px 10px;
  margin: 0 6px 0 6px;
  cursor: pointer;

  i {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  input {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 5px 0 6px;
    opacity: 0.9;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 745px) {
    margin: 10px 0px;
  }
`;

const SearchFEwrapper = styled.div`
  display: flex;
  @media (max-width: 745px) {
    display: unset;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MessageForDragDropFeature = styled.p`
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #f55a2c;
  padding: 10px 0 0 0;
  > div {
    margin: 0 10px;
  }
`;
