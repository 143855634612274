import React, { useEffect, useState } from "react";
import {
  BoxContainer,
  OrangeButton,
  SubHeader,
  FilterButton,
  FilterSection,
  LoadingWrapper,
  NODATA,
} from "../../../style/Gobalstyle";
import QRTable from "../QRTable";
import CreateQR from "./CreateQR";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import {
  getAllAuthCodeDetails,
  qrCodeListing,
  searchAuthCode,
  searchQrcode,
  showQrCodeListing,
} from "../../../services/Collection";
import PaginationRounded from "../../../components/PaginationRounded";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IntlMessage from "../../../Utils/IntlMessage";
import { ExportFeature } from "../../../Utils/ExportFiles";
import QRMenuFilter from "./QRMenutFilter";
import AuthQRCode from "./AuthQrCode/AuthQRCode";
import AuthorizationOption from "./AuthQrCode/AuthorizationOption";
import {
  FilterWrapper,
  SearchButton,
  SearchFEwrapper,
  ShowButton,
  TabButton,
} from "../QRStyle";
import { selectTabUpdate } from "./QRCodeStore/QrCodeStore";
import { useMemo } from "react";

export default function QRCode() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;

  const [openCreateQR, setOpenCreateQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setqrCodeData] = useState([]);
  const [qrCodePage, setqrCodePage] = useState();
  const [pageNumber, setPagenumber] = useState(1);
  const [perPageListing, setPerPageListing] = useState(15);
  const [openFilterSection, setOpenFilterSection] = useState(false);
  const [authorizationOption, setAuthorizationOption] = useState(false);
  const [authCodePage, setAuthCodePage] = useState();
  const [authCodeData, setAuthCodeData] = useState([]);
  const [loadingAuthCode, setLoadingAuthCode] = useState(false);
  const [perPageListingAuthCode, setPerPageListingAuthCode] = useState(15);
  const [pageNumberAuthCode, setPagenumberAuthCode] = useState(1);

  const dispatch = useDispatch();

  const selectTab = useSelector((state) => state?.qrCode?.activeTab);

  const qrMenuPermission = useSelector(
    (state) => state?.loginAuth?.permissions?.qr_menu
  );
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );

  const changeActiveTab = (tabValue) => {
    dispatch(selectTabUpdate(tabValue));
  };

  const myValue = (pages) => {
    setPagenumber(pages);
  };

  const UserHeader = [
    {
      id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      key: "name",
    },
    {
      id: <IntlMessage id="qrMenu.qRCodes.qrSource" />,
      key: "group_type",
    },
    {
      id: <IntlMessage id="qrMenu.qRCodes.groupName" />,
      key: "group_name",
    },
    {
      id: <IntlMessage id="qrMenu.qRCodes.qrCode" />,
      key: "QR_Code",
    },
    {
      id: <IntlMessage id="qrMenu.qRCodes.Action" />,
      key: "Action",
    },

    {
      id: "",
      key: "Toggle",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    let req = {
      pageNumber: pageNumber,
      type: 1,
    };
    let res = await qrCodeListing(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
      setqrCodePage(res.extraData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getWord = async (word) => {
    setLoading(true);
    const req = {
      search: word,
      pageNumber: pageNumber,
    };

    let res = await searchQrcode(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
      setqrCodePage(res.extraData);
      setLoading(false);
    }
  };

  const OpenFilterUser = () => {
    setOpenFilterSection(true);
  };

  const getNumber = async (number) => {
    setPerPageListing(number);
    setLoading(true);
    const req = {
      perPage: number,
      pageNumber: pageNumber,
    };

    let res = await showQrCodeListing(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
      setqrCodePage(res.extraData);
      setLoading(false);
    }
  };

  const manupilateData = (payload) => {
    let arr = [];
    if (payload.length > 0) {
      payload?.map((data) =>
        data?.QrcodesAuthcodes?.map((innerData) =>
          arr.push({
            ...data,
            name: innerData?.QrCode?.name,
            qrcode_authcode: innerData.qrcode_authcode,
          })
        )
      );
    }

    setAuthCodeData(arr);
    setLoadingAuthCode(false);
  };

  // Auth Codes
  const getAllAuthCodes = async () => {
    setLoadingAuthCode(true);
    let params = new URLSearchParams();
    params.append("pageNumber", pageNumberAuthCode);
    params.append("perPage", perPageListingAuthCode);

    let res = await getAllAuthCodeDetails(params.toString());
    if (res.status === 200) {
      manupilateData(res.data);
      setAuthCodePage(res.extraData);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoadingAuthCode(false);
    }
  };

  // Search Auth Code

  const getAuthWord = async (word) => {
    if (word != "") {
      console.log(word, "worddd");
      let params = new URLSearchParams();
      params.append("pageNumber", pageNumberAuthCode);
      params.append("perPage", perPageListingAuthCode);
      setLoadingAuthCode(true);
      let res = await searchAuthCode(word, params.toString());
      if (res?.status === 200) {
        setAuthCodeData(res.data);
        setAuthCodePage(res.extraData);
        setLoadingAuthCode(false);
      } else {
        setLoadingAuthCode(false);
        const message = getErrorMessage(res, "Failed to connection");
        toast.error(message);
      }
    } else {
      getAllAuthCodes();
    }
  };

  const getNumberAuthCode = async (number) => {
    setLoadingAuthCode(true);
    setPerPageListingAuthCode(number);
    let params = new URLSearchParams();
    params.append("pageNumber", pageNumberAuthCode);
    params.append("perPage", number);

    let res = await getAllAuthCodeDetails(params.toString());
    if (res.status === 200) {
      setAuthCodeData(res.data);
      setAuthCodePage(res.extraData);
      setLoadingAuthCode(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoadingAuthCode(false);
    }
  };

  const TableAction = {
    apply: true,
    view: true,
    edit: qrMenuPermission,
    delete: qrMenuPermission,
    toggle: true,
    actionOfView: "OpenViewQR",
    actionOfEdit: "openEditQR",
    actionOfDelete: "openDeleteQR",
    fetchData: fetchData,
  };

  const TableActionAuthCodes = {
    apply: true,
    edit: true,
    delete: true,
    view: true,
    toggle: true,
    select: true,
    actionOfView: "OpenViewAuthCode",
    actionOfEdit: "OpenEditAuthCode",
    actionOfDelete: "OpenDeleteAuthCode",
    fetchDataAuthCodes: getAllAuthCodes,
  };

  const authSearchWord = useSelector((state) => state?.qrCode?.searchAuthWord);

  useMemo(() => {
    if (authSearchWord) {
      getAuthWord(authSearchWord);
    }else{
      getAllAuthCodes();
    }
  }, [authSearchWord]);

  useEffect(() => {
    getAllAuthCodes();
  }, [pageNumberAuthCode]);

  //Runs only on the first render
  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <div>
      {openCreateQR && (
        <CreateQR
          open={openCreateQR}
          handleClose={() => setOpenCreateQR(false)}
          fetch={fetchData}
        />
      )}

      {openFilterSection && (
        <QRMenuFilter
          open={openFilterSection}
          handleClose={() => setOpenFilterSection(false)}
          getAllsection={fetchData}
        />
      )}

      {authorizationOption && (
        <AuthorizationOption
          open={authorizationOption}
          handleClose={() => setAuthorizationOption(false)}
          getAllAuthCodes={getAllAuthCodes}
        />
      )}
      <SubHeader>
        <p>
          <IntlMessage id="qrMenu.qRCodes.heading" />
        </p>
        {qrMenuPermission &&
          (selectTab === 1 ? (
            <OrangeButton onClick={() => setOpenCreateQR(true)}>
              <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" />
            </OrangeButton>
          ) : (
            <OrangeButton onClick={() => setAuthorizationOption(true)}>
              {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" /> */}
              Generate Authorization Code
            </OrangeButton>
          ))}
      </SubHeader>

      <TabButton>
        <div
          onClick={() => changeActiveTab(1)}
          className={selectTab === 1 && "active"}
        >
          {/* <IntlMessage id="Orders.dineIn" /> */}
          Qr Code
        </div>

        <div
          onClick={() => changeActiveTab(3)}
          className={selectTab === 3 && "active"}
        >
          {/* <IntlMessage id="Orders.COMPLETED" /> */}
          Authorization Code
        </div>
      </TabButton>

      {selectTab === 1 ? (
        <BoxContainer>
          <FilterSection>
            <ShowButton>
              <p>
                <IntlMessage id="button.show" />
              </p>
              <input
                type="number"
                value={perPageListing}
                onChange={(e) => getNumber(e.target.value)}
              />
            </ShowButton>
            <SearchFEwrapper>
              <SearchButton>
                <i className="icon-Search" />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => getWord(e.target.value)}
                />
              </SearchButton>
              <FilterWrapper>
                {/* <FilterButton onClick={OpenFilterUser}>
                  <i className="icon-Filter" />
                  <p>
                    <IntlMessage id="button.Filters" />
                  </p>
                </FilterButton> */}

                <FilterButton
                  onClick={() =>
                    ExportFeature(
                      `${aqlutstorage}${containerExport}QrCodelist${RestaurentID}.xlsx` 
                    )
                  }
                >
                  <i className="icon-Export" />
                  <p>
                    <IntlMessage id="button.Export" />
                  </p>
                </FilterButton>
              </FilterWrapper>
            </SearchFEwrapper>
          </FilterSection>

          {loading ? (
            <LoadingWrapper>
              <CircularProgress sx={{ color: "#f55a2c" }} />
            </LoadingWrapper>
          ) : (
            <>
              {qrCodeData?.length === 0 ? (
                <NODATA>
                  <IntlMessage id="qrMenu.qRCodes.heading" />
                </NODATA>
              ) : (
                <QRTable
                  header={UserHeader}
                  tableData={qrCodeData}
                  action={TableAction}
                />
              )}
            </>
          )}

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <PaginationRounded
              PaginationRounded={qrCodePage}
              onChangePage={(_page) => myValue(_page)}
            />
          </div>
        </BoxContainer>
      ) : (
        <AuthQRCode
          loadingAuthCode={loadingAuthCode}
          authCodeData={authCodeData}
          authCodePage={authCodePage}
          TableActionAuthCodes={TableActionAuthCodes}
          setPagenumberAuthCode={setPagenumberAuthCode}
          getNumberAuthCode={getNumberAuthCode}
          perPageListingAuthCode={perPageListingAuthCode}
          getAllAuthCodes={getAllAuthCodes}
          getWord={getAuthWord}
        />
      )}
    </div>
  );
}
