import React from "react";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { Cancel, LoadingWrapper, NODATA } from "../../../../style/Gobalstyle";
import QRTable from "../../QRTable";
import { BoxContent } from "../../../Menu/MenuStyle";
import styled from "styled-components";
import { useEffect } from "react";
import PaginationRounded from "../../../../components/PaginationRounded";
import { useState } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";
import { QrcodeWithoutAuth } from "../../../../services/Collection";
import { CircularProgress } from "@mui/material";

export default function UnlinkedQrCode({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state.languageDirection.direction);
  const [pageNumber, setPagenumber] = useState(1);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const UserHeader = [
    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "Sno",
      key: "Sno",
    },

    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "UNLINK QR CODES",
      key: "name",
    },
  ];

  const authCodeData = [{}];

  const TableActionAuthCodes = {
    apply: true,
    edit: true,
    delete: true,
    view: true,
    toggle: true,
    select: true,
  };

  const getAllUnlinkedQrcodes = async () => {
    setLoading(true);

    let params = new URLSearchParams();
    params.append("pageNumber", pageNumber);
    params.append("perPage", 20);

    let res = await QrcodeWithoutAuth(params.toString());
    if (res.status === 200) {
      setData(res.data);
      setPage(res.extraData);

      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const myValue = (pages) => {
    setPagenumber(pages);
  };

  useEffect(() => {
    getAllUnlinkedQrcodes();
  }, [pageNumber]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <UnlinkedQrcodeWrapper>
          {loading ? (
            <LoadingWrapper>
              <CircularProgress sx={{ color: "#f55a2c" }} />
            </LoadingWrapper>
          ) : (
            <>
              {data.length === 0 ? (
                <NODATA>No data found</NODATA>
              ) : (
                <QRTable
                  header={UserHeader}
                  tableData={data}
                  action={TableActionAuthCodes}
                  pageNumber={pageNumber}
                />
              )}
            </>
          )}

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <PaginationRounded
              PaginationRounded={page}
              onChangePage={(_page) => myValue(_page)}
            />
          </div>
        </UnlinkedQrcodeWrapper>
      </Dialog>
    </div>
  );
}

const UnlinkedQrcodeWrapper = styled.div`
  width: 450px;
  height: 100%;
  max-height: 600px;
  overflow-y: scroll;
  padding: 30px 20px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }
`;
