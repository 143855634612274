/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useMemo, useState, Fragment, useRef } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "../../app/Auth/ChangePassword/ChangePassword";
import {
  BlackButton,
  LoadingWrapper,
  OrangeButton,
  OrangeButtonSpan,
  SelectIcon,
  SubHeader,
  WhiteButton,
} from "../../style/Gobalstyle";
import IntlMessage from "../../Utils/IntlMessage";
import {
  BoxContainer,
  InnerBoxContainer,
  InnerLeftWrapper,
  InputPageLeftWrapper,
  InputPageRightWrapper,
  InputPageRightWrapperMobile,
  InputPageWrapper,
  InputWrap,
  LeftSection,
  LocationWrapper,
  PaymentInputContainer,
  ProfileLocationBox,
  ProfilePaymentSection,
  ProfilePicturesWrapper,
  ProfileTransparentWrapper,
  ProfileWrapper,
  RightSection,
  WeekInput,
  WeekSelect,
  CurrentLocationBtn,
  InputSection,
} from "./Profilestyle";
import * as yup from "yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import InputField from "../../validation/InputField";
import { DependentField } from "../../validation/DependentField";
import { SelectInput } from "../Menu/MenuStyle";
import { CircularProgress, Switch } from "@mui/material";
import TextArea from "../../validation/TextArea";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import {
  changeProfileImage,
  getCurrencyList,
  getTimeZoneList,
  profileDetails,
  updateProfile,
  updateRestaurantTiming,
} from "../../services/Collection";
import { profileUpdated } from "./ProfileStore";
import moment from "moment";
import Icons from "../../icons";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  CircleF,
  Autocomplete,
} from "@react-google-maps/api";
import DialogBox from "../../components/DialogBox";
import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  SnapchatIcon,
  TikTocIcon,
} from "../../icons/svgIcons";

const circleOption = {
  strokeColor: "transparent",
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: "#F55A2C",
  fillOpacity: 0.3,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

export default function Profile() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;

  const direction = useSelector((state) => state.languageDirection.direction);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [restaurentTiming, setRestaurentTiming] = useState(null);
  const [allCurrency, setAllCurrency] = useState([]);
  const [timeZoneListing, setTimeZoneListing] = useState([]);
  const [currentLoc, setCurrentLoc] = useState({});
  const [radius, setRadius] = useState(0);
  const [searchResult, setSearchResult] = useState();
  const autocompleteRef = useRef();

  const dispatch = useDispatch();
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_APIKEY;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  });

  const getCurrentLocation = React.useCallback(() => {
    if ("geolocation" in navigator) {
      console.log("acces yes", navigator);
    } else console.log("not access");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentLoc({ ...currentLocation });
      });
    }
  }, [navigator.geolocation.getCurrentPosition]);

  const setCurrentLocation = () => {
    autocompleteRef.current.value = null;
    getCurrentLocation();
  };

  //userPermissionDecide.
  const profilePermission = useSelector(
    (state) => state.loginAuth?.permissions?.profile
  );

  const [disableOpeningTimes, setDisableOpeningTimes] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });
  const [disableOrderSwitch, setDisableOrderSwitch] = useState(false);
  const [prePaymenSwitch, setprePaymentSwitch] = useState(false);
  const [prePaymenModal, setprePaymentModal] = useState(false);
  const [prePaymenLoading, setprePaymentLoading] = useState(false);

  const getChangeOpenTiming = async (
    name,
    newValue,
    prevObject,
    checkboxStatus
  ) => {
    let NewMintues = new Date(newValue.toString()).getMinutes();
    let PrevMintuesForOpen = new Date(
      prevObject?.open_time?.toString()
    ).getMinutes();

    let PrevOpenTiming = moment(new Date(prevObject?.open_time)).format("LT");
    let NewOpenTiming = moment(new Date(newValue)).format("LT");

    if (name === "open") {
      let onlyTime = new Date(newValue?._d);

      if (NewOpenTiming !== PrevOpenTiming) {
        if (NewMintues !== PrevMintuesForOpen) {
          let HourMintuesSecond = moment(onlyTime).format("HH:mm:ss");

          let req = {
            day: prevObject.day,
            operation_hours_id: prevObject.id,
            open_time: HourMintuesSecond,
            close_time: prevObject.close_time,
            status: checkboxStatus ? 1 : 0,
          };
          let res = await updateRestaurantTiming(req);
          if (res.status === 200) {
            setLoading(false);
            toast.success(res.message);
            getProfileDetails();
          } else {
            const message = getErrorMessage(res, "Failed to connection");
            toast.error(message);
            setLoading(false);
          }
        }
      }
    }

    if (name === "close") {
      let onlyTime = new Date(newValue?._d);
      let HourMintuesSecond = moment(onlyTime).format("HH:mm:ss");

      if (HourMintuesSecond !== prevObject?.close_time) {
        let req = {
          day: prevObject.day,
          operation_hours_id: prevObject.id,
          open_time: prevObject.open_time,
          close_time: HourMintuesSecond,
          status: checkboxStatus ? 1 : 0,
        };
        let res = await updateRestaurantTiming(req);
        if (res.status === 200) {
          setLoading(false);
          toast.success(res.message);
          getProfileDetails();
        } else {
          const message = getErrorMessage(res, "Failed to connection");
          toast.error(message);
          setLoading(false);
        }
      }
    }

    if (checkboxStatus !== undefined) {
      let req = {
        day: newValue.day,
        operation_hours_id: newValue.id,
        open_time: newValue.open_time,
        close_time: newValue.close_time,
        status: checkboxStatus ? "1" : "0",
      };
      let res = await updateRestaurantTiming(req);
      if (res.status === 200) {
        setLoading(false);
        toast.success(res.message);
        getProfileDetails();
      } else {
        const message = getErrorMessage(res, "Failed to connection");
        toast.error(message);
        setLoading(false);
      }
    }
  };

  const changePhoto = (file) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
    ChangeProfileImage(file);
    getProfileDetails();
  };

  const initialValues = useMemo(() => {
    let currencyobject = {
      id: 1,
      currency_name: details?.currency,
      createdAt: "2022-09-15T07:27:19.000Z",
      updatedAt: "2022-09-15T07:27:19.000Z",
    };
    let timeZoneObject = { name: details?.timezone };

    if (details) {
      return {
        disableOrders: details.disable_order,
        pre_payment: details.pre_payment,
        restaurantName: details.name,
        restaurantNameAr: details.ar_name,
        currency: currencyobject,
        mail: details.email,
        vat: details.vat,
        serviceCharge: details.service_charge,
        address: details.address,
        timeZone: timeZoneObject,
        restaurent_phones: details.RestaurentPhones,
        Whatsapp: details.whats_app_notification,
        is_deliverable: details?.is_deliverable,
        cash_on_delivery: details?.cash_on_delivery,
        restaurantBio: details.restaurent_bio,
        restaurantBioAr: details.ar_restaurent_bio,
        delivery: details?.max_delivery_distance,
        pay_at_restaurant: details?.pay_at_restaurant,
        delivery_fee: details?.delivery_fee,
        min_order_amount_for_delivery: details?.min_order_amount_for_delivery,
        instagram_link: details?.instagram_link,
        snapchat_link: details?.snapchat_link,
        facebook_link: details?.facebook_link,
        tiktok_link: details?.tiktok_link,
        website_link: details?.website_link,
        phone_number: {
          country_code: details?.country_code,
          phone_number: details?.phone_number,
        },
        delivery_charges: details?.delivery_charges ?? [
          { min: "", max: "", charges_per_km: "" },
        ],
        is_dynamic_delivery_charges: details?.is_dynamic_delivery_charges,
        minimum_delivery_amount: details?.minimum_delivery_amount,
        takeaway_fees_name: details?.takeaway_fees_name,
        takeaway_fees_amount: details?.takeaway_fees_amount,
      };
    } else {
      return {
        disableOrders: "",
        restaurantName: "",
        restaurantNameAr: "",
        currency: "",
        mail: "",
        vat: "",
        serviceCharge: "",
        address: "",
        timeZone: "",
        restaurent_phones: [{ country_code: "", phone_number: "" }],
        is_deliverable: "",
        cash_on_delivery: "",
        Whatsapp: "",
        restaurantBio: "",
        restaurantBioAr: "",
        delivery: "",
        pay_at_restaurant: "",
        delivery_fee: "",
        min_order_amount_for_delivery: "",
        instagram_link: "",
        snapchat_link: "",
        facebook_link: "",
        tiktok_link: "",
        website_link: "",
        phone_number: { country_code: "", phone_number: "" },
        delivery_charges: [{ min: "", max: "", charges_per_km: "" }],
        is_dynamic_delivery_charges: "",
        minimum_delivery_amount: "",
        takeaway_fees_name: "",
        takeaway_fees_amount: "",
      };
    }
  }, [details]);

  const EnRegex = "([a-zA-Z]s*)+";
  const ArRegex = "[\u0600-\u06FF]";
  const Numbers = "^[0-9]*$";

  let schema = yup.object().shape({
    restaurantName: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter name"),
    restaurantNameAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter name"),

    // restaurent_phones: yup.array().of(
    //   yup.object().shape({
    //     phone_number: yup.number().required("Please enter name"),
    //   })
    // ),
    currency: yup.object().required("required"),
    mail: yup.string().email("Invaild format").required("required"),
    vat: yup
      .string()
      .matches(Numbers, "Only number allow")
      .required("required"),
    serviceCharge: yup
      .string()
      .matches(Numbers, "Only number allow")
      .required("required"),
    timeZone: yup.object().required("required"),

    restaurantBio: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter restaurant Bio"),
    restaurantBioAr: yup
      .string()
      .matches(ArRegex, "Only English letters allow")
      .required("Please enter restaurant BioArb"),

    delivery: yup
      .string()
      .required("Please enter Max Delivery Area")
      .matches(Numbers, "Only number allow"),
  });

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    if (values?.restaurent_phones?.[0]?.phone_number) {
      setLoading(true);
      let req = {
        name: values.restaurantName,
        ar_name: values.restaurantNameAr,
        email: values.mail,
        address: values.address,
        state: null,
        street: null,
        country: null,
        restaurent_phones: values.restaurent_phones,
        latitude: currentLoc.lat,
        longitude: currentLoc.lng,
        timezone: values.timeZone.name,
        restaurent_bio: values.restaurantBio,
        ar_restaurent_bio: values.restaurantBioAr,
        currency: values.currency.currency_name,
        vat: values.vat,
        service_charge: values.serviceCharge,
        whats_app_notification: values.Whatsapp,
        // payment_enable: true,
        disable_order: values.disableOrders,
        cash_on_delivery: values.cash_on_delivery,
        is_deliverable: values.is_deliverable,
        max_delivery_distance: values.delivery,
        pay_at_restaurant: values.pay_at_restaurant,
        delivery_fee: values.delivery_fee,
        min_order_amount_for_delivery: values.min_order_amount_for_delivery,
        // subscription_status: true,
        // pos_integration_status: true,
        // pos_name: values,
        instagram_link: values?.instagram_link,
        snapchat_link: values?.snapchat_link,
        facebook_link: values?.facebook_link,
        tiktok_link: values?.tiktok_link,
        website_link: values?.website_link,
        phone_number: values?.phone_number?.phone_number,
        country_code: values?.phone_number?.country_code,
        delivery_charges: values?.delivery_charges,
        is_dynamic_delivery_charges: values?.is_dynamic_delivery_charges,
        minimum_delivery_amount: values?.minimum_delivery_amount,
        takeaway_fees_name: values?.takeaway_fees_name,
        takeaway_fees_amount: values?.takeaway_fees_amount,
      };
      let res = await updateProfile(req);
      if (res.status === 200) {
        setLoading(false);
        dispatch(profileUpdated(req));
        toast.success(res.message);
        getProfileDetails();
      } else {
        const message = getErrorMessage(res, "Failed to connection");
        toast.error(message);
        setLoading(false);
      }
    } else {
      toast.error("Please add phone number", { theme: "colored" });
    }
  };

  const disableOrders = async (checked) => {
    setLoading(true);
    setDisableOrderSwitch(checked);
    let req = {
      disable_order: checked,
    };
    let res = await updateProfile(req);
    if (res.status === 200) {
      setLoading(false);
      dispatch(profileUpdated(req));
      toast.success(res.message);
      getProfileDetails();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };
  const prePayment = async (checked) => {
    setprePaymentSwitch(checked);
    setprePaymentModal(true);
  };

  const ChangeProfileImage = async (file) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("image", file, file?.name);

    let res = await changeProfileImage(fd);
    if (res.status === 200) {
      setDetails(res.data);
      toast.info(res.message, { theme: "colored" });
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getProfileDetails = async () => {
    setLoading(true);

    let res = await profileDetails();
    if (res.status === 200) {
      setDetails(res.data);
      setRadius(Number(res?.data?.max_delivery_distance) * 1000);
      setRestaurentTiming(res.data.RestaurentOperationalHours);
      dispatch(profileUpdated(res.data));
      let currentLocation = {
        lat: res?.data?.latitude,
        lng: res?.data?.longitude,
      };
      setCurrentLoc({ ...currentLocation });
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getAllCurrency = async () => {
    let res = await getCurrencyList();
    if (res.status === 200) {
      setAllCurrency(res.data);
    }
  };

  const getTimeZone = async () => {
    let res = await getTimeZoneList();
    if (res.status === 200) {
      setTimeZoneListing(res.data);
    }
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let coordinate = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setCurrentLoc(coordinate);
    } else {
    }
  };

  useEffect(() => {
    getProfileDetails();
    getAllCurrency();
    getTimeZone();
  }, []);

  console.log("details", details);

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  const handleModalSubmit = async (checked) => {
    console.log(checked, "checked");
    setprePaymentLoading(true);

    let req = {
      pre_payment: checked,
    };
    let res = await updateProfile(req);
    if (res.status === 200) {
      setprePaymentLoading(false);
      setprePaymentModal(false);
      dispatch(profileUpdated(req));
      toast.success(res.message);
      getProfileDetails();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setprePaymentLoading(false);
    }
  };

  return (
    <ProfileWrapper>
      {prePaymenModal && (
        <DialogBox
          open={prePaymenModal}
          handleClose={() => setprePaymentModal(false)}
          title={"Pre-Payment"}
          subTitle={"Are you sure?"}
          Content={`This is very sensitive action, It will change the proceeser of order payment method.`}
          handleSubmit={handleModalSubmit}
          loading={prePaymenLoading}
          SwitchAction={prePaymenSwitch}
        />
      )}
      {!profilePermission && <ProfileTransparentWrapper />}
      {profilePermission && openChangePassword && (
        <ChangePassword
          open={openChangePassword}
          handleClose={() => setOpenChangePassword(false)}
        />
      )}
      <SubHeader>
        <p>
          <IntlMessage id="sidebar.profile" />
        </p>
        {profilePermission && (
          <OrangeButton onClick={() => setOpenChangePassword(true)}>
            <IntlMessage id="button.CHANGEPASSWORD" />
          </OrangeButton>
        )}
      </SubHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }) => (
          <Form>
            <>
              <BoxContainer>
                <InnerBoxContainer>
                  <LeftSection>
                    <ProfilePicturesWrapper>
                      {preview ? (
                        <img src={preview} alt="Profile" />
                      ) : details?.logo ? (
                        <>
                          <img
                            src={
                              `${aqlutstorage}` +
                              `${containerProfile}` +
                              `${details?.logo}`
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept=".jpg , .png"
                            onChange={(e) => changePhoto(e?.target?.files[0])}
                          />
                          <i className="icon-Upload" />
                        </>
                      )}
                    </ProfilePicturesWrapper>
                    {profilePermission && (
                      <div>
                        <WhiteButton>
                          <input
                            type="file"
                            accept=".jpg , .png"
                            onChange={(e) => changePhoto(e?.target?.files[0])}
                          />
                          <IntlMessage id="button.CHANGEPHOTO" />
                        </WhiteButton>
                        <OrangeButtonSpan onClick={() => setPreview(null)}>
                          <IntlMessage id="button.REMOVEPHOTO" />
                        </OrangeButtonSpan>
                      </div>
                    )}
                  </LeftSection>

                  <RightSection>
                    <section>
                      <p>
                        <IntlMessage id="profileSection.disableOrders" />
                      </p>
                      <Switch
                        name="disableOrders"
                        checked={values.disableOrders}
                        onChange={(event, checked) => {
                          disableOrders(checked);
                          // setFieldValue(
                          //   "disableOrders",
                          //   checked ? true : false
                          // );
                        }}
                      />
                    </section>

                    {/* {!details?.payment_enable && (
                      <section>
                        <p>
                          <IntlMessage id="profileSection.disableOrders" />
                          Pre-Payment
                        </p>
                        <Switch
                          name="pre_payment"
                          checked={values.pre_payment}
                          onChange={(event, checked) => {
                            prePayment(checked);
                            // setFieldValue(
                            //   "pre_payment",
                            //   checked ? true : false
                            // );
                          }}
                        />
                      </section>
                    )} */}
                  </RightSection>
                </InnerBoxContainer>
              </BoxContainer>

              <BoxContainer>
                <InputPageWrapper>
                  <InputWrap dir={direction}>
                    <section>
                      <i className="icon-Shop" />
                      <label>Restaurant Name/اسم المطعم</label>
                    </section>

                    <Field
                      dir="ltr"
                      type="text"
                      placeholder="Restaurant Name"
                      name="restaurantName"
                      component={InputField}
                    />
                    <Field
                      dir="rtl"
                      type="text"
                      placeholder="اسم المطعم"
                      name="restaurantNameAr"
                      component={InputField}
                    />
                  </InputWrap>

                  <InputWrap dir={direction}>
                    <section>
                      <i className="icon-Message" />
                      <label>
                        <IntlMessage id="profileSection.mailAddress" />
                      </label>
                    </section>
                    <Field
                      dir="ltr"
                      type="text"
                      placeholder="Mail"
                      name="mail"
                      component={InputField}
                    />
                  </InputWrap>

                  <InputPageLeftWrapper>
                    <InnerLeftWrapper>
                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Address" />
                          <label>
                            <IntlMessage id="profileSection.address" />{" "}
                          </label>
                        </section>

                        <Field
                          dir="ltr"
                          type="text"
                          placeholder="Address"
                          name="address"
                          component={InputField}
                        />
                      </InputWrap>

                      <InputWrap dir={direction}>
                        <section>
                          <section>
                            <i className="icon-TimeZone" />
                            <label>
                              <IntlMessage id="profileSection.timeZone" />{" "}
                            </label>
                          </section>
                        </section>
                        <Field
                          name="timeZone"
                          component={DependentField}
                          getChanges={() => function noFun() {}}
                          options={timeZoneListing}
                          getOptionLabel={(option) =>
                            option ? option?.name : ""
                          }
                          renderInput={(params) => (
                            <div
                              ref={params.InputProps.ref}
                              style={{ position: "relative" }}
                            >
                              <SelectInput
                                placeholder="Time Zone"
                                Dir={direction}
                                type="text"
                                {...params.inputProps}
                              />

                              <SelectIcon
                                className="icon-DropDownArrow"
                                dir="ltr"
                              />
                            </div>
                          )}
                        />
                      </InputWrap>

                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-PhoneNumber" />
                          <label>
                            <IntlMessage id="profileSection.phoneNumber" />{" "}
                          </label>
                        </section>

                        <FieldArray name="restaurent_phones">
                          {({ insert, remove, push }) => (
                            <div>
                              {values?.restaurent_phones?.length > 0 &&
                                values?.restaurent_phones?.map((el, index) => (
                                  <div className="row" key={index}>
                                    <PhoneInput
                                      country={"qa"}
                                      value={
                                        el?.country_code + el?.phone_number
                                      }
                                      onChange={(value, data) => {
                                        setFieldValue(
                                          `restaurent_phones.${index}.country_code`,
                                          "+" + data.dialCode
                                        );
                                        setFieldValue(
                                          `restaurent_phones.${index}.phone_number`,
                                          value.substring(
                                            data.dialCode?.length,
                                            20
                                          )
                                        );
                                      }}
                                      containerStyle={{
                                        width: "100%",
                                        height: "42px",
                                        background: "#fcfdfe",
                                        border: "1px solid #f0f1f7",
                                        borderRadius: "8px",
                                        margin: "18px 25px 10px",
                                      }}
                                      inputStyle={{
                                        border: "none",
                                        width: "100%",
                                        height: "40px",
                                      }}
                                      placeholder="Phone Number"
                                    />

                                    {values?.restaurent_phones?.length > 1 && (
                                      <OrangeButton
                                        style={{
                                          height: "35px",
                                          marginTop: "7px",
                                          fontSize: "10px",
                                          padding: "10px 14px",
                                          margin: "15px 22px",
                                          color: "#fff",
                                        }}
                                        type="button"
                                        className="secondary"
                                        onClick={() => remove(index)}
                                      >
                                        <IntlMessage id="button.remove" />
                                      </OrangeButton>
                                    )}
                                  </div>
                                ))}

                              {values?.restaurent_phones?.length === 3
                                ? ""
                                : true && (
                                    <span
                                      style={{
                                        float: "right",
                                        margin: "0px -21px",
                                      }}
                                      type="button"
                                      onClick={() =>
                                        push({
                                          country_code: "",
                                          phone_number: "",
                                        })
                                      }
                                    >
                                      +
                                      <IntlMessage id="Menu.createItem.AddMore" />
                                    </span>
                                  )}
                            </div>
                          )}
                        </FieldArray>
                      </InputWrap>

                      <section>
                        <InputWrap dir={direction}>
                          <section>
                            <i className="icon-WhatsApp" />
                            <label>
                              <IntlMessage id="profileSection.whatsappNotifications" />{" "}
                            </label>
                          </section>
                        </InputWrap>
                        <div style={{ margin: "5px 0 0 28px" }}>
                          <Switch
                            // name="disableOrders"
                            // // value="Y"
                            // checked={values.disableOrders}
                            // onChange={(event, checked) => {

                            name="Whatsapp"
                            checked={values.Whatsapp}
                            onChange={(event, checked) => {
                              setFieldValue("Whatsapp", checked ? true : false);
                            }}
                          />
                        </div>
                      </section>

                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Bio" />
                          <label>Restaurant Bio / مطعم بيو</label>
                        </section>

                        <Field
                          dir="ltr"
                          type="text"
                          name="restaurantBio"
                          placeholder="Restaurant Bio"
                          component={TextArea}
                        />

                        <Field
                          dir="rtl"
                          type="text"
                          name="restaurantBioAr"
                          placeholder=" مطعم بيو<"
                          component={TextArea}
                        />
                      </InputWrap>
                    </InnerLeftWrapper>
                  </InputPageLeftWrapper>
                  <InputPageRightWrapper>
                    <InputWrap dir={direction}>
                      <section>
                        <i className="icon-OpeningTime" />
                        <label>
                          <IntlMessage id="profileSection.openingTimes" />
                        </label>
                      </section>
                    </InputWrap>
                    <WeekSelect>
                      {restaurentTiming?.map((el) => (
                        <WeekInput
                          dir={direction}
                          disable={disableOpeningTimes.sunday}
                        >
                          <label>{el.day}</label>

                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                              label="Basic example"
                              value={`Sat Jan 01 2022 ${el?.open_time}`}
                              onChange={() => {}}
                              onAccept={(newValue) => {
                                getChangeOpenTiming("open", newValue, el);
                              }}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    readOnly
                                    className="textInput"
                                    type="text"
                                    placeholder="Open time"
                                  />

                                  {disableOpeningTimes.sunday ? (
                                    <div style={{ width: "8px" }} />
                                  ) : (
                                    <>{InputProps?.endAdornment}</>
                                  )}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                              label="Basic example"
                              value={`Sat Jan 01 2022 ${el?.close_time}`}
                              onChange={() => {}}
                              onAccept={(newValue) =>
                                getChangeOpenTiming("close", newValue, el)
                              }
                              // ampm={false}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    readOnly
                                    className="textInput"
                                    type="text"
                                    placeholder="Close time"
                                  />
                                  {disableOpeningTimes.sunday ? (
                                    <div style={{ width: "8px" }} />
                                  ) : (
                                    <div>{InputProps?.endAdornment}</div>
                                  )}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>

                          <input
                            className="checkInput"
                            type="checkbox"
                            name={el.day}
                            // value={el.status}
                            checked={el.status === "1" && "checked"}
                            onClick={(e) =>
                              getChangeOpenTiming(
                                null,
                                el,
                                null,
                                e.target.checked
                              )
                            }
                          />
                          <span>
                            <IntlMessage id="button.close" />
                          </span>
                        </WeekInput>
                      ))}
                    </WeekSelect>
                  </InputPageRightWrapper>
                </InputPageWrapper>

                <InputPageRightWrapperMobile>
                  <InputWrap dir={direction}>
                    <section>
                      <i className="icon-OpeningTime" />
                      <label>
                        <IntlMessage id="profileSection.openingTimes" />
                      </label>
                    </section>
                  </InputWrap>
                  <WeekSelect>
                    {restaurentTiming?.map((el) => (
                      <WeekInput
                        dir={direction}
                        disable={disableOpeningTimes.sunday}
                      >
                        <label>{el.day}</label>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            label="Basic example"
                            value={`Sat Jan 01 2022 ${el?.open_time}`}
                            onChange={() => {}}
                            onAccept={(newValue) => {
                              getChangeOpenTiming("open", newValue, el);
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly
                                  className="textInput"
                                  type="text"
                                  placeholder="Open time"
                                />

                                {disableOpeningTimes.sunday ? (
                                  <div style={{ width: "8px" }} />
                                ) : (
                                  <>{InputProps?.endAdornment}</>
                                )}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            label="Basic example"
                            value={`Sat Jan 01 2022 ${el?.close_time}`}
                            onChange={() => {}}
                            onAccept={(newValue) =>
                              getChangeOpenTiming("close", newValue, el)
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly
                                  className="textInput"
                                  type="text"
                                  placeholder="Close time"
                                />
                                {disableOpeningTimes.sunday ? (
                                  <div style={{ width: "8px" }} />
                                ) : (
                                  <div>{InputProps?.endAdornment}</div>
                                )}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>

                        <input
                          className="checkInput"
                          type="checkbox"
                          name={el.day}
                          // value={el.status}
                          checked={el.status === "1" && "checked"}
                          onClick={(e) =>
                            getChangeOpenTiming(
                              null,
                              el,
                              null,
                              e.target.checked
                            )
                          }
                        />
                        <span>
                          <IntlMessage id="button.close" />
                        </span>
                      </WeekInput>
                    ))}
                  </WeekSelect>
                </InputPageRightWrapperMobile>
              </BoxContainer>

              <BoxContainer>
                <ProfilePaymentSection>
                  <label className="Payment-label">Payment & Charges</label>
                  <PaymentInputContainer>
                    <InputPageWrapper>
                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Dollor" />
                          <label>Takeaway Fee Name</label>
                        </section>
                        <Field
                          className="max-deliveryArea"
                          dir="ltr"
                          type="text"
                          placeholder="Enter Name"
                          name="takeaway_fees_name"
                          component={InputField}
                        />
                      </InputWrap>
                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Dollor" />
                          <label>Takeaway Fee Amount</label>
                        </section>
                        <Field
                          className="max-deliveryArea"
                          dir="ltr"
                          type="text"
                          placeholder="Enter Amount"
                          name="takeaway_fees_amount"
                          component={InputField}
                        />
                      </InputWrap>
                      <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Dollor" />
                          <label>Max Delivery Area (KM)</label>
                        </section>
                        <Field
                          className="max-deliveryArea"
                          dir="ltr"
                          type="text"
                          placeholder="25"
                          name="delivery"
                          component={InputField}
                        />
                      </InputWrap>

                      {/* <InputPageWrapper>
                        <InputWrap dir={direction}>
                          <section>
                            <i className="icon-Vat" />
                            <label>
                              <IntlMessage id="profileSection.vat" />%
                            </label>
                          </section>

                          <Field
                            dir="ltr"
                            type="text"
                            placeholder="VAT"
                            name="vat"
                            component={InputField}
                          />
                        </InputWrap>

                        <InputWrap dir={direction}>
                          <section>
                            <i style={{ width: "18px" }} />
                            <label>
                              <IntlMessage id="profileSection.serviceCharge" />{" "}
                              %
                            </label>
                          </section>

                          <Field
                            dir="ltr"
                            type="text"
                            placeholder="Service Charge"
                            name="serviceCharge"
                            component={InputField}
                          />
                        </InputWrap>
                      </InputPageWrapper> */}

                      {/* <InputWrap dir={direction}>
                        <section>
                          <i className="icon-Dollor" />
                          <label>
                            <IntlMessage id="profileSection.currency" />
                          </label>
                        </section>

                        <Field
                          name="currency"
                          component={DependentField}
                          getChanges={() => function noFun() { }}
                          options={allCurrency}
                          getOptionLabel={(option) =>
                            option ? option?.currency_name : ""
                          }
                          renderInput={(params) => (
                            <div
                              ref={params.InputProps.ref}
                              style={{ position: "relative" }}
                            >
                              <SelectInput
                                placeholder="Currency"
                                Dir={direction}
                                type="text"
                                {...params.inputProps}
                              />

                              <SelectIcon
                                className="icon-DropDownArrow"
                                dir="ltr"
                              />
                            </div>
                          )}
                        />
                      </InputWrap> */}
                      <section>
                        <InputWrap dir={direction}>
                          <section>
                            <i className="icon-Dollor" />
                            <label>
                              {/* <IntlMessage id="profileSection.whatsappNotifications" />{" "} */}
                              Dynamic Delivery Charges
                            </label>
                          </section>
                        </InputWrap>
                        <div style={{ margin: "0 0 0 28px" }}>
                          <Switch
                            // name="disableOrders"
                            // // value="Y"
                            // checked={values.disableOrders}
                            // onChange={(event, checked) => {

                            name="is_dynamic_delivery_charges"
                            checked={values?.is_dynamic_delivery_charges}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "is_dynamic_delivery_charges",
                                checked ? true : false
                              );
                            }}
                          />
                        </div>
                      </section>
                      <InputWrap
                        dir={direction}
                        style={{ margin: "10px 0 0 0" }}
                      >
                        <section>
                          <i className="icon-Dollor" />
                          <label>Minimum order amount</label>
                        </section>
                        <Field
                          className="deliveryfee"
                          dir="ltr"
                          type="text"
                          placeholder="Minimum order amount for delivery"
                          name="min_order_amount_for_delivery"
                          component={InputField}
                        />
                      </InputWrap>
                      {values?.is_dynamic_delivery_charges && (
                        <InputWrap
                          dir={direction}
                          style={{ margin: "10px 0 0 0" }}
                        >
                          <section>
                            <i className="icon-Dollor" />
                            <label>Minimum delivery amount</label>
                          </section>
                          <Field
                            className="deliveryfee"
                            dir="ltr"
                            type="text"
                            placeholder="Minimum delivery amount"
                            name="minimum_delivery_amount"
                            component={InputField}
                          />
                        </InputWrap>
                      )}

                      {!values?.is_dynamic_delivery_charges && (
                        <>
                          <InputWrap
                            dir={direction}
                            style={{ margin: "10px 0 0 0" }}
                          >
                            <section>
                              <i className="icon-Dollor" />
                              <label>Delivery Fee</label>
                            </section>
                            <Field
                              className="deliveryfee"
                              dir="ltr"
                              type="text"
                              placeholder="delivery fee"
                              name="delivery_fee"
                              component={InputField}
                            />
                          </InputWrap>
                        </>
                      )}
                    </InputPageWrapper>

                    <InputPageWrapper>
                      {/* <InputPageWrapper>

                        <section style={{ margin: "20px 0 0 0" }} >
                          <InputWrap dir={direction}>
                            <section>
                              <Icons name="payAtRestaurant" />
                              <label>Disable Takeaway</label>
                            </section>
                          </InputWrap>
                          <div style={{ margin: "5px 0 0 28px" }}>
                            <Switch
                              name="pay_at_restaurant"
                              checked={values.pay_at_restaurant}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "pay_at_restaurant",
                                  checked ? true : false
                                );
                              }}
                            />
                          </div>
                        </section>
                      </InputPageWrapper> */}
                      {/* <InputPageWrapper>
                        <section>
                          <InputWrap dir={direction}>
                            <section>
                              <Icons name="delivery" />
                              <label>Delivery</label>
                            </section>
                          </InputWrap>
                          <div style={{ margin: "5px 0 0 28px" }}>
                            <Switch
                              name="is_deliverable"
                              checked={values.is_deliverable}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "is_deliverable",
                                  checked ? true : false
                                );
                              }}
                            />
                          </div>
                        </section>

                        <section>
                          <InputWrap dir={direction}>
                            <section>
                              <Icons name="cashOnDelivery" />
                              <label>COD (Cash On Delivery)</label>
                            </section>
                          </InputWrap>
                          <div style={{ margin: "5px 0 0 28px" }}>
                            <Switch
                              name="cash_on_delivery"
                              checked={values.cash_on_delivery}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "cash_on_delivery",
                                  checked ? true : false
                                );
                              }}
                            />
                          </div>
                        </section>
                      </InputPageWrapper> */}
                    </InputPageWrapper>

                    {values?.is_dynamic_delivery_charges && (
                      <InputPageWrapper>
                        <InputWrap
                          dir={direction}
                          style={{ margin: "10px 0 0 0" }}
                        >
                          <section>
                            <i className="icon-Dollor" />
                            <label>
                              {/* <IntlMessage id="profileSection.address" />{" "} */}
                              Delivery Charges
                            </label>
                          </section>
                          <FieldArray name="delivery_charges">
                            {({ insert, remove, push }) => (
                              <div>
                                {values?.delivery_charges?.length > 0 &&
                                  values?.delivery_charges?.map(
                                    (item, index) => (
                                      <div className="row" key={index}>
                                        {index > 0 && (
                                          <section
                                            style={{ margin: "15px 20px 0" }}
                                          >
                                            <label>Charges :-</label>
                                          </section>
                                        )}
                                        <Field
                                          name={`delivery_charges.${index}.min`}
                                          value={item?.min}
                                          component={InputField}
                                          placeholder="min amount"
                                        />
                                        <Field
                                          name={`delivery_charges.${index}.max`}
                                          value={item?.max}
                                          component={InputField}
                                          placeholder="max amount"
                                        />
                                        <Field
                                          name={`delivery_charges.${index}.charges_per_km`}
                                          value={item?.charges_per_km}
                                          component={InputField}
                                          placeholder="charges per km"
                                        />
                                        {index > 0 && (
                                          <div style={{ margin: "10px 0" }}>
                                            <OrangeButton
                                              style={{
                                                height: "35px",
                                                marginTop: "7px",
                                                fontSize: "10px",
                                                padding: "10px 14px",
                                                margin: "15px 22px",
                                                color: "#fff",
                                              }}
                                              type="button"
                                              className="secondary"
                                              onClick={() => remove(index)}
                                            >
                                              <IntlMessage id="button.remove" />
                                            </OrangeButton>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {console.log(
                                  "values.delivery_charges",
                                  values.delivery_charges
                                )}
                                {values.delivery_charges?.length < 4 && (
                                  <span
                                    style={{
                                      float: "right",
                                      margin: "0px -21px",
                                    }}
                                    type="button"
                                    onClick={() =>
                                      push({
                                        min: "",
                                        max: "",
                                        charges_per_km: "",
                                      })
                                    }
                                  >
                                    +
                                    <IntlMessage id="Menu.createItem.AddMore" />
                                  </span>
                                )}
                              </div>
                            )}
                          </FieldArray>
                        </InputWrap>
                      </InputPageWrapper>
                    )}
                  </PaymentInputContainer>
                </ProfilePaymentSection>
              </BoxContainer>

              <BoxContainer>
                <ProfilePaymentSection>
                  <label className="Payment-label">Social Media</label>
                  <PaymentInputContainer>
                    <InputPageWrapper>
                      <InputWrap dir={direction}>
                        <section>
                          <i>
                            <InstagramIcon />
                          </i>
                          <label>Instagram</label>
                        </section>
                        <Field
                          className="max-deliveryArea"
                          dir="ltr"
                          type="text"
                          placeholder="Instagram"
                          name="instagram_link"
                          component={InputField}
                        />
                      </InputWrap>
                      <InputWrap dir={direction}>
                        <section>
                          <i>
                            <FacebookIcon />
                          </i>
                          <label>Facebook</label>
                        </section>
                        <Field
                          className="deliveryfee"
                          dir="ltr"
                          type="text"
                          placeholder="Facebook"
                          name="facebook_link"
                          component={InputField}
                        />
                      </InputWrap>
                    </InputPageWrapper>
                    <InputPageWrapper>
                      <InputWrap
                        dir={direction}
                        style={{ margin: "10px 0 0 0" }}
                      >
                        <section>
                          <i>
                            <SnapchatIcon />
                          </i>
                          <label>Snapchat</label>
                        </section>
                        <Field
                          className="deliveryfee"
                          dir="ltr"
                          type="text"
                          placeholder="Snapchat"
                          name="snapchat_link"
                          component={InputField}
                        />
                      </InputWrap>
                      <InputWrap
                        dir={direction}
                        style={{ margin: "10px 0 0 0" }}
                      >
                        <section>
                          <i>
                            <TikTocIcon />
                          </i>
                          <label>Tiktoc</label>
                        </section>
                        <Field
                          className="deliveryfee"
                          dir="ltr"
                          type="text"
                          placeholder="Tiktoc"
                          name="tiktok_link"
                          component={InputField}
                        />
                      </InputWrap>
                    </InputPageWrapper>
                    <InputPageWrapper>
                      <InputWrap
                        dir={direction}
                        style={{ margin: "10px 0 0 0" }}
                      >
                        <section>
                          <i>
                            <GlobeIcon />
                          </i>
                          <label>Website</label>
                        </section>
                        <Field
                          className="deliveryfee"
                          dir="ltr"
                          type="text"
                          placeholder="Website"
                          name="website_link"
                          component={InputField}
                        />
                      </InputWrap>
                      <InputWrap
                        dir={direction}
                        style={{ margin: "10px 0 0 0" }}
                      >
                        <section>
                          <i className="icon-PhoneNumber" />
                          <label>
                            {/* <IntlMessage id="profileSection.phoneNumber" />{" "} */}
                            Call the Restaurant Number
                          </label>
                        </section>
                        <div>
                          <PhoneInput
                            country={"qa"}
                            value={
                              values?.phone_number?.country_code +
                                values?.phone_number?.phone_number || ""
                            }
                            onChange={(value, data) => {
                              setFieldValue(
                                `phone_number.country_code`,
                                "+" + data.dialCode
                              );
                              setFieldValue(
                                `phone_number.phone_number`,
                                value.substring(data.dialCode?.length, 20)
                              );
                            }}
                            containerStyle={{
                              width: "100%",
                              height: "42px",
                              background: "#fcfdfe",
                              border: "1px solid #f0f1f7",
                              borderRadius: "8px",
                              margin: "18px 25px 10px",
                            }}
                            inputStyle={{
                              border: "none",
                              width: "100%",
                              height: "40px",
                            }}
                            placeholder="Phone Number"
                          />
                        </div>
                      </InputWrap>
                    </InputPageWrapper>
                  </PaymentInputContainer>
                </ProfilePaymentSection>
              </BoxContainer>

              <BoxContainer>
                <ProfileLocationBox>
                  <label className="Location-label">Location</label>
                  <LocationWrapper>
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={
                          currentLoc.lat === null && currentLoc.lng === null
                            ? {
                                lat: 25.3548,
                                lng: 51.1839,
                              }
                            : currentLoc
                        }
                        zoom={10}
                      >
                        <Fragment key="marker">
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                          >
                            <InputSection
                              ref={autocompleteRef}
                              type="text"
                              placeholder="Search Place"
                            />
                          </Autocomplete>
                          <CurrentLocationBtn onClick={setCurrentLocation}>
                            <Icons name="currentLocation" color="#1b8ad9" />
                          </CurrentLocationBtn>
                          <MarkerF position={currentLoc} />
                          <CircleF
                            center={{
                              lat: currentLoc.lat,
                              lng: currentLoc.lng,
                            }}
                            radius={radius}
                            options={circleOption}
                          />
                        </Fragment>
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </LocationWrapper>
                </ProfileLocationBox>
              </BoxContainer>
              {profilePermission && (
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                  <BlackButton type="submit">
                    <IntlMessage id="button.UPDATE" />
                  </BlackButton>
                </div>
              )}
            </>
          </Form>
        )}
      />
    </ProfileWrapper>
  );
}
