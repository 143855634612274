import React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BlackButtonMobile, Cancel } from "../../../style/Gobalstyle";
import { QRCode } from "react-qrcode-logo";
import { AglutLogoInPink } from "../../../Utils/Images";

import styled from "styled-components";
import IntlMessage from "../../../Utils/IntlMessage";
import { Tooltip } from "@mui/material";
import { useState } from "react";

export default function ViewQR({ open, handleClose, payload }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const UserBaseUrl = process.env.REACT_APP_USER_BASEURL;
  const [isURLCopied, setIsURLCopied] = useState("Copy");

  const handleCopiedURL = () => {
    setIsURLCopied("Copied");
    navigator.clipboard.writeText(`${UserBaseUrl}?qrcodeid=${payload.id}`);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <ViewQRWrapper isURLCopied={isURLCopied}>
          <QRCode
            value={`${UserBaseUrl}?qrcodeid=${payload.id}`}
            logoImage={AglutLogoInPink}
            padding={0}
            size={250}
            removeQrCodeBehindLogo={true}
            // qrStyle="dots"
            logoOpacity={1}
            logoWidth={40}
          />
          <h1>{payload.name}</h1>
          <p>{payload.group_type}</p>

          <section>
            <Tooltip title={isURLCopied} placement="top">
              <label
                onClick={handleCopiedURL}
              >{`${UserBaseUrl}?qrcodeid=${payload.id}`}</label>
            </Tooltip>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px 0px 0px",
            }}
          >
            <BlackButtonMobile onClick={() => handleClose()}>
              <IntlMessage id="button.close" />
            </BlackButtonMobile>
          </div>
        </ViewQRWrapper>
      </Dialog>
    </div>
  );
}

const ViewQRWrapper = styled.div`
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 20px;

  img {
    margin: 30px 0;
  }

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 10px 0;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 0 0;

    label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: ${({ isURLCopied }) =>
        isURLCopied === "Copy" ? "#f55a2c" : "Green"};
      text-align: center;
      cursor: pointer;
    }
  }

  @media (max-width: 599px) {
    width: 100%;
    height: 100%;
  }
`;
