import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PaymentNotificationApi } from "../../services/Collection";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import DashboardTable from "./DashboardTable";
import { FilterSection, LoadingWrapper } from "../../style/Gobalstyle";
import { SearchFEwrapper, SearchButton } from "../Feedback/Feedback";

const TableHeader = [
  {
    id: "Order Id",
    key: "order_id",
  },
  {
    id: "Payment Type",
    key: "payment_type",
  },
  {
    id: "Qr Code Name",
    key: "qr_code_name",
  },
  {
    id: "Order Type",
    key: "order_type",
  },
  {
    id: "Customer Name",
    key: "customer_name",
  },
  {
    id: "Restaurant Name",
    key: "restaurant_name",
  },
  {
    id: "Order Amount",
    key: "total_order_amount",
  },
  {
    id: "Paid Amount",
    key: "total_paid_amount",
  },
  {
    id: "Due Amount",
    key: "total_due_amount",
  },
];

const DashboardPayments = () => {
  const [listing, setListing] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePaymentNotification = async (search) => {
    setLoading(true);
    let res = await PaymentNotificationApi();
    if (res?.status == 200) {
      let obj = {};
      let payType = [
        "full payment",
        "partial payment",
        "payment failed",
        "post payment",
        "pre payment",
      ];
      res?.data?.forEach((order) => {
        const label = order?.label;
        if (!obj[label]) {
          obj[label] = [];
        }
        obj[label].push({
          ...order,
          payment_type: payType[order?.payment_type - 1],
        });
      });

      const resultArray = Object.keys(obj).map((label) => ({
        label: label,
        orders: obj[label],
      }));

      setListing(resultArray);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleSearch = (word) => {
    if (word) {
      let searchData = listing?.map((list) => ({
        label: list?.label,
        orders: list?.orders?.filter(
          (el) =>
            el?.qr_code_name?.toLowerCase()?.includes(word?.toLowerCase()) ||
            el?.restaurant_name?.toLowerCase()?.includes(word?.toLowerCase())
        ),
      }));
      setSearch(searchData);
      return;
    }
    setSearch([]);
  };

  useEffect(() => {
    handlePaymentNotification();
  }, []);

  return (
    <>
      <FilterSection style={{ justifyContent: "end" }}>
        <SearchFEwrapper>
          <SearchButton>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </SearchButton>
        </SearchFEwrapper>
      </FilterSection>

      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : search?.length > 0 ? (
        search?.map((data) => (
          <>
            {data?.orders?.length ? (
              <>
                <h4>{data?.label}</h4>
                <DashboardTable header={TableHeader} tableData={data?.orders} />
              </>
            ):""}
          </>
        ))
      ) : (
        listing?.map((data) => (
          <>
            <h4>{data?.label}</h4>
            <DashboardTable header={TableHeader} tableData={data?.orders} />
          </>
        ))
      )}
    </>
  );
};

export default DashboardPayments;
