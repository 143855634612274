import {
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BoxContent, Cancel, SelectInput } from "../../../Menu/MenuStyle";
import { CreateQRWrapper, InputWrap, SelectIconDiscount } from "../../QRStyle";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import IntlMessage from "../../../../Utils/IntlMessage";
import InputField from "../../../../validation/InputField";
import { DependentField } from "../../../../validation/DependentField";
import {
  BlackButtonMobile,
  OrangeButtonForForm,
} from "../../../../style/Gobalstyle";
import { useEffect } from "react";
import {
  GenerateAuthCode,
  qrCodeListing,
} from "../../../../services/Collection";
import OtpGenrater from "../../../../components/OtpGenrater";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";

export default function GenerateOneByOne({ open, handleClose, getAllAuthCodes }) {
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [qrCodeData, setqrCodeData] = useState([]);
  const [manuallyGenerate, setManuallyGenerate] = useState(false);
  const [otp, setOtp] = useState("");

  const inputValues = (values) => {
    setOtp(values);
  };

  // Yup is used for validation.
  let schema = yup.object().shape({
    qrCode: yup.object().required("Reqiured"),
  });

  console.log('   getAllAuthCodes()' ,    getAllAuthCodes)

  const handleSubmit = async (values) => {
    setLoadingGenerate(true);

    let AuthCode = null;
    if (manuallyGenerate) {
      if (otp.length == 5) {
        AuthCode = otp;
      } else {
        toast.warning("You should enter atleast 5 digits");
        setLoadingGenerate(false);
        return;
      }
    } else {
      AuthCode = Math.floor(Math.random() * 90000) + 10000;
    }

    let req = {
      auth_code: AuthCode,
      qr_code_id: values?.qrCode?.id,
    };
    let res = await GenerateAuthCode(req);
    if (res.status === 200) {
      setLoadingGenerate(false);
      toast.info(res.message);
      handleClose();
      getAllAuthCodes()
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoadingGenerate(false);
    }

    // console.log(AuthCode, "AuthCode");
  };

  const getAllQrCodes = async () => {
    let req = {
      pageNumber: 0,
      type: 1,
    };
    let res = await qrCodeListing(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
    }
  };

  useEffect(() => {
    getAllQrCodes();
  }, []);

  return (
    <CreateQRWrapper>
      <>
        <Formik
          initialValues={{
            qrCode: "",
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
          render={({ values }) => (
            <Form>
              <BoxContent>
                <header>
                  {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" /> */}
                  Please select QRCode to attach with
                </header>

                <InputWrap dir={direction}>
                  <section>
                    <label>
                      {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.menuGroup" /> */}
                      QR Code
                    </label>
                  </section>
                  <Field
                    name="qrCode"
                    component={DependentField}
                    options={qrCodeData}
                    getChanges={() => function Close() {}}
                    getOptionLabel={(option) =>
                      option ? option?.group_name + " - " + option?.name : ""
                    }
                    renderInput={(params) => (
                      <div
                        ref={params.InputProps.ref}
                        style={{ position: "relative" }}
                      >
                        <SelectInput
                          placeholder="QR Code"
                          Dir={direction}
                          type="text"
                          r
                          {...params.inputProps}
                        />

                        <SelectIconDiscount
                          className="icon-DropDownArrow"
                          dir="ltr"
                        />
                      </div>
                    )}
                  />
                </InputWrap>

                {/* <p
                  className="ManullyGenrate"
                  style={{ cursor: "pointer" }}
                  onClick={() => setManuallyGenerate(!manuallyGenerate)}
                >
                  Manually Generate
                </p> */}

                {/* <h4 className="AutoGenrate">Auto Generated Auth Code</h4> */}
                {/* 
                {manuallyGenerate && (
                  <OtpGenrater
                    numInputs={5}
                    inputValues={inputValues}
                    otp={otp}
                  />
                )} */}

                <div style={{ textAlign: "center" }}>
                  {loadingGenerate ? (
                    <CircularProgress sx={{ color: "#f55a2c" }} />
                  ) : (
                    <>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        <IntlMessage id="button.close" />
                      </BlackButtonMobile>
                      <OrangeButtonForForm type="submit">
                        {/* <IntlMessage id="button.SUBMIT" /> */}
                        Generate
                      </OrangeButtonForForm>
                    </>
                  )}
                </div>
              </BoxContent>
            </Form>
          )}
        />
      </>
    </CreateQRWrapper>
  );
}
