import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  BlackButton,
  BoxContainer,
  ButtonWrapper,
  LoadingWrapper,
  SubHeader,
} from "../../../style/Gobalstyle";
import { ColorPick } from "../../../Utils/Images";
import {
  BrandingContainer,
  BrandingInputWrap,
  HomePage,
  InputWrapText,
  Upload,
  WelcomePage,
} from "../QRStyle";
import * as yup from "yup";
import ColorPickerField from "../../../validation/ColorPickerField";
import { useEffect } from "react";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import {
  brandingTheme,
  updateBrandingTheme,
  profileDeleteTheme,
} from "../../../services/Collection";
import { useMemo } from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ProfileTransparentWrapper } from "../../Profile/Profilestyle";
import IntlMessage from "../../../Utils/IntlMessage";

export default function QRMenuBranding() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState([]);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const qrMenuPermission = useSelector(
    (state) => state?.loginAuth?.permissions?.qr_menu
  );

  const changePhoto = (e) => {
    const [file] = e.target.files;
    setPreview(URL.createObjectURL(file));
    setFile(file);
  };

  const initialValues = useMemo(() => {
    if (theme) {
      return {
        // displayImage: "true",
        button_primary_color: theme?.button_primary_color,
        button_secondary_color: theme?.button_secondary_color,
        button_text_color: theme?.button_text_color,
        backgroundColor: theme?.background_color,
        headerColor: theme?.header_color,
        textColor: theme?.text_color,
        basicThemeColor: theme?.theme_color,
        boxColor: theme?.placeholder_box_color
      };
    }
    return {
      button_primary_color: "",
      button_secondary_color: "",
      button_text_color: "",
      backgroundColor:"",
      headerColor:"",
      textColor:"",
      basicThemeColor:"",
      boxColor:""
    };
  }, [theme]);

  // Yup is used for validation.
  let schema = yup.object().shape({
    button_primary_color: yup.string().required("Required"),
    button_secondary_color: yup.string().required("Required"),
    button_text_color: yup.string().required("Required"),
  });

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("menu_branding_id", theme.id);

    fd.append("button_primary_color", values.button_primary_color);
    fd.append("button_secondary_color", values.button_secondary_color);
    fd.append("button_text_color", values.button_text_color);
    fd.append("background_color", values.backgroundColor);
    fd.append("header_color", values.headerColor);
    fd.append("text_color", values.textColor);
    fd.append("theme_color", values.basicThemeColor);
    fd.append("placeholder_box_color", values.boxColor);

    if (file) {
      fd.append("displayimage", file, file?.name);
    }

    let res = await updateBrandingTheme(fd);
    if (res.status === 200) {
      setLoading(false);
      toast.info(res.message);
      getTheme();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      toast.error(message);
    }
  };

  const getTheme = async () => {
    setLoading(true);
    let res = await brandingTheme();
    if (res.status === 200) {
      setTheme(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  console.log(theme , "themeeeeeeeeeeeeeeeeeeee")

  useEffect(() => {
    getTheme();
  }, []);

  const removeImage = async () => {
    setLoading(true);
    const req = {
      menu_branding_id: theme.id,
    };

    let res = await profileDeleteTheme(req);
    if (res.status === 200) {
      setLoading(false);
      toast.info(res.message);
      window.location.reload();
      getTheme();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      toast.error(message);
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      {!qrMenuPermission && <ProfileTransparentWrapper />}
      <SubHeader>
        <p>
          <IntlMessage id="qrMenu.qrMenuBranding.Heading" />
        </p>
      </SubHeader>

      <BoxContainer>
        <Formik
          initialValues={initialValues}
          // validationSchema={schema}
          onSubmit={handleSubmit}
          render={({ values, setFieldValue }) => (
            <Form>
              <BrandingContainer>
                <WelcomePage>
                  <header>
                    <IntlMessage id="qrMenu.qrMenuBranding.welcomePage" />
                  </header>
                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        <IntlMessage id="qrMenu.qrMenuBranding.displayImage" />
                      </label>
                    </BrandingInputWrap>
                    {/* <div style={{ margin: "10px 0" }}>
                      {qrMenuPermission && (
                        <Switch
                          name="displayImage"
                          value="true"
                          checked={values.displayImage === "true"}
                          onChange={(event, checked) => {
                            setFieldValue(
                              "displayImage",
                              checked ? "true" : "false"
                            );
                          }}
                        />
                      )}
                    </div> */}
                  </section>

                  <section>
                    <Upload>
                      <input
                        type="file"
                        accept=".jpg , .png"
                        onChange={(e) => changePhoto(e)}
                      />

                      {preview ? (
                        <img src={preview} alt="Profile" />
                      ) : theme?.display_image ? (
                        <>
                          <img
                            src={
                              `${aqlutstorage}` +
                              `${containerMenuBranding}` +
                              `${theme?.display_image}`
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <i className="icon-Upload" />
                          <span>
                            <IntlMessage id="qrMenu.qrMenuBranding.uploadImg" />
                          </span>
                        </>
                      )}
                    </Upload>
                    {qrMenuPermission && (
                      <>
                        <WhiteButtonchange>
                          <input
                            type="file"
                            accept=".jpg , .png"
                            onChange={(e) => changePhoto(e)}
                          />
                          <IntlMessage id="button.change" />
                        </WhiteButtonchange>
                        <OrangeButtonRemove onClick={() => removeImage()}>
                          <IntlMessage id="button.remove" />
                        </OrangeButtonRemove>
                      </>
                    )}
                  </section>
                </WelcomePage>

                <HomePage>
                  <header>
                    <IntlMessage id="qrMenu.qrMenuBranding.homePage" />
                  </header>

                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                       Button Color
                      </label>

                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background:
                              values.button_primary_color ?? "#FFF",
                          }}
                        >
                          <span>{values.button_primary_color}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="button_primary_color"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                        <span className="ForSpace"></span>

                        <section
                          style={{
                            background:
                              values.button_secondary_color ?? "#FFF",
                          }}
                        >
                          <span>{values.button_secondary_color}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="button_secondary_color"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>

                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Button text color
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.button_text_color ?? "#FFF",
                          }}
                        >
                          <span>{values.button_text_color}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="button_text_color"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>



                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Background color
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.backgroundColor ?? "#FFF",
                          }}
                        >
                          <span>{values.backgroundColor}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="backgroundColor"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>


                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Header color
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.headerColor ?? "#202020",
                          }}
                        >
                          <span>{values.headerColor}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="headerColor"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>

                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Text color
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.textColor ?? "#7b7f91",
                          }}
                        >
                          <span>{values.textColor}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="textColor"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>

                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Theme color
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.basicThemeColor ?? "#202020",
                          }}
                        >
                          <span>{values.basicThemeColor}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="basicThemeColor"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>


                  <section>
                    <BrandingInputWrap dir={direction}>
                      <label>
                        {/* <IntlMessage id="qrMenu.qrMenuBranding.homeButtonBackGroundColor" /> */}
                        Box background
                      </label>
                      <div style={{ display: "flex" }}>
                        <section
                          style={{
                            background: values.boxColor ?? "#202020",
                          }}
                        >
                          <span>{values.boxColor}</span>
                          <Field
                            dir="ltr"
                            type="color"
                            name="boxColor"
                            component={ColorPickerField}
                            className="Field"
                          />
                          <img src={ColorPick} alt="color" />
                        </section>
                      </div>
                    </BrandingInputWrap>
                  </section>



                </HomePage>
              </BrandingContainer>
              <ButtonWrapper>
                {qrMenuPermission && (
                  <BlackButton type="submit">
                    <IntlMessage id="button.generate" />
                  </BlackButton>
                )}
              </ButtonWrapper>
            </Form>
          )}
        ></Formik>
      </BoxContainer>
    </div>
  );
}

export const WhiteButtonchange = styled.button`
  background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #f55a2c;
  padding: 10px 14px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  @media (max-width: 620px) {
    padding: 10px 10px;
    margin: 5px 0px;
  }
`;

export const OrangeButtonRemove = styled.span`
  background: #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 14px;
  margin: 5px 10px;
  cursor: pointer;
  @media (max-width: 620px) {
    padding: 10px 10px;
    margin: 5px 20px;
  }
`;
