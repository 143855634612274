import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  getDashboardDetails,
  SupplierDashboardApi,
} from "../../services/Collection";
import {
  LoadingWrapper,
  DashboardSubHeader,
  NODATA,
  BoxContainer,
  JustifyEnd,
  FilterButton,
} from "../../style/Gobalstyle";
import {
  DashboardClosedOrder,
  DashboardDiscountAmount,
  DashboardMenuItems,
  DashboardPendingOrder,
  DashboardSales,
  DashboardUniqueCustomer,
} from "../../Utils/Images";
import DashboardCard from "./DashboardCard";
import { dashboardActiveTab } from "./DashboardStore";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import DashboardTable from "./DashboardTable";
import IntlMessage from "../../Utils/IntlMessage";
import { ExportFeature } from "../../Utils/ExportFiles";
import PaginationRounded from "../../components/PaginationRounded";
import DashboardPayments from "./DashboardPayments";

export default function DashboardAglut() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectTab = useSelector((state) => state?.dashboard?.activeTab);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const RestaurentDetail = useSelector(
    (state) => state?.profileDetails?.restaurantDetails
  );
  const [pageNumber, setPagenumber] = useState(1);
  const [sectionItemPage, setsectionItemPage] = useState(null);
  const CheckSelectiveTab = [4, 5];
  const [transFilter, setTransFilter] = useState(false);

  const myValue = (pages) => {
    setPagenumber(pages);
  };

  let data = [
    {
      key: "fadeInUp",
      name: "TotalActiveMenuDetails",
      logo: `${DashboardMenuItems}`,
      subheader: "Total Active ",
      header: "Menu Items",
      count: 24,
      backgroundColor: "#ae2c6633",
    },

    {
      key: "fadeInUp",
      name: "TotaluniqueCustmoers",
      logo: `${DashboardUniqueCustomer}`,
      subheader: "Total unique",
      header: "Customers",
      count: 24,
      backgroundColor: "#ee6c4d33",
    },

    {
      key: "fadeInUp",
      name: "TotalPendingOrders",
      logo: `${DashboardPendingOrder}`,
      subheader: "Total no of",
      header: "pending orders",
      count: 24,
      backgroundColor: "#f2963833",
    },

    {
      key: "fadeInUp",
      name: "TotalClosedOrders",
      logo: `${DashboardClosedOrder}`,
      subheader: "Total No of",
      header: "closed orders",
      count: 24,
      backgroundColor: "#388ef233",
    },

    {
      key: "fadeInUp",
      name: "TotalOrdersAmount",
      logo: `${DashboardSales}`,
      subheader: "Total",
      header: "Sales",
      count: 24,
      backgroundColor: "#7f38f233",
    },

    {
      key: "fadeInUp",
      name: "TotalOrdersDiscount",
      logo: `${DashboardDiscountAmount}`,
      subheader: "Total",
      header: "Discount Amount",
      count: 24,
      backgroundColor: "#38f28233",
    },
  ];
  let supplierdata = [
    {
      key: "TotalAwaitingBalance",
      name: "TotalAwaitingBalance",
      logo: `${DashboardSales}`,
      header: "Amount awaiting deposit",
      count: 24,
      backgroundColor: "#7f38f233",
    },
    
    {
      key: "TotalNumberOfTransactions",
      name: "TotalNumberOfTransactions",
      logo: `${DashboardMenuItems}`,
      header: "Total number of Transacitons",
      count: 24,
      backgroundColor: "#ae2c6633",
    },

    {
      key: "TotalValueOfTransactions",
      name: "TotalValueOfTransactions",
      logo: `${DashboardSales}`,
      header: "Total Transacitons Value",
      count: 24,
      backgroundColor: "#7f38f233",
    },

    {
      key: "TotalDepositedAmount",
      name: "TotalDepositedAmount",
      logo: `${DashboardMenuItems}`,
      header: "Amount deposited",
      count: 24,
      backgroundColor: "#ae2c6633",
    },
  ];

  const TableHeader = [
    {
      id: <IntlMessage id="dashboard.customerName" />,
      key: "CustomerName",
    },
    {
      id: <IntlMessage id="dashboard.orderId" />,
      key: "OrderID",
    },
    {
      id: <IntlMessage id="dashboard.itemName" />,
      key: "ItemName",
    },
    {
      id: <IntlMessage id="dashboard.amount" />,
      key: "Amount",
    },
    {
      id: <IntlMessage id="dashboard.quantity" />,
      key: "Quantity",
    },
    {
      id: <IntlMessage id="dashboard.modeOfPayment" />,
      key: "ModeOfPayment",
    },
    {
      id: <IntlMessage id="dashboard.customerMobile" />,
      key: "CustomerMobile",
    },
    {
      id: <IntlMessage id="dashboard.date" />,
      key: "Date",
    },
  ];

  const TableHeaderForTransactionsReconciliation = [
    {
      id: "QR code name",
      key: "qr_code_name",
    },
    {
      id: "Transaction Date",
      key: "Date",
    },
    {
      id: "POS Check No.",
      key: "pos_check_no",
    },
    {
      id: "Invoice ID",
      key: "transaction_number",
    },
    {
      id: "Order ID",
      key: "order_id",
    },
    {
      id: "Transaction Charge",
      key: "transaction_charge",
    },
    {
      id: "Amount Due Deposit",
      key: "amount_due_deposit",
    },
    {
      id: "Amount Paid",
      key: "total_payment_amount",
    },
    {
      id: "Tip paid",
      key: "total_tip_paid",
    },
    {
      id: "Grand Total",
      key: "grand_total",
    },
  ];

  const ManupilationData = (object) => {
    let finalArray = [];
    let newdata = selectTab == 7 ? supplierdata : data;
    for (const val of newdata) {
      let keys = Object.keys(object);

      for (const iteration of keys) {
        if (iteration == val.name) {
          val["count"] = object[iteration];
          finalArray.push(val);
        }
      }
    }
    setCardData(finalArray);
    setLoading(false);
  };

  const DashboradDetails = async () => {
    setLoading(true);
    let request = new URLSearchParams();
    request.append("type", selectTab);
    let res = await getDashboardDetails(request.toString());
    if (res.status === 200) {
      ManupilationData(res.data);
    } else {
      toast.error(res.response.data.message, { theme: "colored" });
      setLoading(false);
    }
  };

  const handleSupplierDashboard = async () => {
    setLoading(true);
    let res = await SupplierDashboardApi();
    if (res?.status === 200) {
      ManupilationData(res?.data);
      setLoading(false);
    } else {
      toast.error(res.response.data.message, { theme: "colored" });
      setLoading(false);
    }
  };

  const CustomerAndOrderList = async (isCustom, page) => {
    if (page) setPagenumber(page);
    setLoading(true);
    let param = new URLSearchParams();
    param.append("type", selectTab);
    if (selectTab === 5) {
      param.append("pageNumber", page ?? pageNumber);
      isCustom &&
        startDate &&
        param.append("start_date", moment(startDate).format("YYYY-MM-DD"));
      isCustom &&
        startDate &&
        param.append("end_date", moment(endDate).format("YYYY-MM-DD"));
    }
    let res = await getDashboardDetails(param);
    if (res.status === 200) {
      let data =
        res?.data?.CustomerReports ||
        res?.data?.GetTransactionReconsilation ||
        [];
      setTableData(data);
      if (selectTab === 5) {
        setsectionItemPage(res?.extraData);
      }
      setLoading(false);
    } else {
      toast.error(res.response.data.message, { theme: "colored" });
      setLoading(false);
      setLoading(true);
      try {
        let res = await getDashboardDetails(param);
        if (res.status === 200) {
          let data =
            res?.data?.CustomerReports ||
            res?.data?.GetTransactionReconsilation ||
            [];
          setTableData(data);
          setLoading(false);
        } else {
          toast.error(res.response.data.message, { theme: "colored" });
          setLoading(false);
        }
      } catch (e) {
        toast.error("Something went wrong");
      }
    }
  };
  const changeActiveTab = (tabValue) => {
    setPagenumber(1);
    dispatch(dashboardActiveTab(tabValue));
    if(selectTab == 7){
      setCardData([]);
    }

    if (selectTab != 5) {
      setTransFilter(false);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const resetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    selectTab === 5 && CustomerAndOrderList();
    if (startDate === null && endDate === null) dispatch(dashboardActiveTab(1));
  };

  const handleExportFeature = (tab) => {
    if (tab == 5) {
      ExportFeature(
        `${aqlutstorage}${containerExport}GetTransactionReconsilation${RestaurentID}.xlsx`
      );
    } else if (tab === 4) {
      ExportFeature(
        `${aqlutstorage}${containerExport}CustomerReports${RestaurentID}.xlsx`
      );
    }
  };

  const handleFilter = () => {
    setTransFilter(!transFilter);
    setStartDate(null);
    setEndDate(null);
    if (transFilter) {
      CustomerAndOrderList(false, 1);
    }
  };

  const applyFilter = async () => {
    if (startDate && endDate) {
      setLoading(true);
      let request = new URLSearchParams();
      request.append("type", selectTab);
      request.append("start_date", moment(startDate).format("YYYY-MM-DD"));
      request.append("end_date", moment(endDate).format("YYYY-MM-DD"));
      let res = await getDashboardDetails(request.toString());
      if (res.status === 200) {
        ManupilationData(res.data);
      } else {
        toast.error(res.response.data.message, { theme: "colored" });
        setLoading(false);
      }
    } else {
      toast.warning("You have to fill both dates", { theme: "colored" });
    }
  };

  useEffect(() => {
    if (!RestaurentDetail?.fatoorah_supplier_code && selectTab === 7) {
      dispatch(dashboardActiveTab(1));
    }
    {
      selectTab === 7 && handleSupplierDashboard();
    }
    let check = selectTab === 5 && startDate && endDate ? true : false;
    (selectTab === 4 || selectTab === 5) &&
      CustomerAndOrderList(check, pageNumber);
    selectTab !== 3 &&
      selectTab !== 4 &&
      selectTab !== 5 &&
      selectTab !== 6 &&
      selectTab !== 7 &&
      DashboradDetails();
  }, [selectTab, pageNumber]);

  return (
    <div>
      <DashboardSubHeader>
        <p>
          {/* <IntlMessage id="Menu.heading" /> */}
          Dashboard
        </p>

        <TabButton>
          <div
            onClick={() => changeActiveTab(1)}
            className={selectTab === 1 && "active"}
          >
            All Time
            {/* <IntlMessage id="Orders.dineIn" /> */}
          </div>
          <div
            onClick={() => changeActiveTab(2)}
            className={selectTab === 2 && "active"}
          >
            Today
            {/* <IntlMessage id="Orders.takeaway" /> */}
          </div>
          <div
            onClick={() => changeActiveTab(3)}
            className={selectTab === 3 && "active"}
          >
            Filter
            {/* <IntlMessage id="Orders.COMPLETED" /> */}
          </div>
          <div
            onClick={() => changeActiveTab(4)}
            className={selectTab === 4 && "active"}
          >
            Customer Order List
          </div>
          <div
            onClick={() => changeActiveTab(5)}
            className={selectTab === 5 && "active"}
          >
            Transactions Reconciliation
          </div>
          <div
            onClick={() => changeActiveTab(6)}
            className={selectTab === 6 && "active"}
          >
            Payments Notifications
          </div>
          {RestaurentDetail?.fatoorah_supplier_code && (
            <div
              onClick={() => changeActiveTab(7)}
              className={selectTab === 7 && "active"}
            >
              Supplier Dashboard
            </div>
          )}
        </TabButton>
      </DashboardSubHeader>

      {selectTab === 3 && (
        <WeekInput
        // dir={direction}
        >
          <div className="InputWrapper">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Basic example"
                onChange={() => {}}
                onAccept={(newValue) => setStartDate(newValue?._d)}
                value={startDate}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <input
                      ref={inputRef}
                      {...inputProps}
                      readOnly
                      className="textInput"
                      type="text"
                      placeholder="Start Date"
                    />

                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
              <p>-</p>
              <DatePicker
                label="Basic example"
                onChange={() => {}}
                onAccept={(newValue) => setEndDate(newValue?._d)}
                value={endDate}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <input
                      ref={inputRef}
                      {...inputProps}
                      readOnly
                      className="textInput"
                      type="text"
                      placeholder="End Date"
                    />

                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="filterBtnsWrapper">
            <span onClick={applyFilter} className="applyFilter">
              Apply
            </span>

            <span onClick={resetFilter} className="resetFilter">
              Reset
            </span>
          </div>
        </WeekInput>
      )}

      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <>
          {selectTab !== 6 ? (
            !CheckSelectiveTab?.includes(selectTab) ? (
              <DashboardAglutWrapper>
                {cardData?.length > 0 &&
                  cardData?.map((item) => <DashboardCard item={item} />)}
              </DashboardAglutWrapper>
            ) : tableData?.length === 0 ? (
              <NODATA>
                <IntlMessage id="dashboard.NoData" />
              </NODATA>
            ) : (
              <BoxContainer>
                <JustifyEnd>
                  {selectTab === 5 && (
                    <TabButton>
                      <div
                        onClick={handleFilter}
                        className={transFilter && "active"}
                      >
                        Filter
                      </div>
                    </TabButton>
                  )}
                  {![6].includes(selectTab) && (
                    <FilterButton
                      onClick={() => handleExportFeature(selectTab)}
                    >
                      <i className="icon-Export" />
                      <p>
                        <IntlMessage id="button.Export" />
                      </p>
                    </FilterButton>
                  )}
                </JustifyEnd>
                {selectTab == 5 && transFilter && (
                  <WeekInput
                  // dir={direction}
                  >
                    <div className="InputWrapper">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Basic example"
                          onChange={() => {}}
                          onAccept={(newValue) => setStartDate(newValue?._d)}
                          value={startDate}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly
                                className="textInput"
                                type="text"
                                placeholder="Start Date"
                              />

                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                        <p>-</p>
                        <DatePicker
                          label="Basic example"
                          onChange={() => {}}
                          onAccept={(newValue) => setEndDate(newValue?._d)}
                          value={endDate}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly
                                className="textInput"
                                type="text"
                                placeholder="End Date"
                              />

                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="filterBtnsWrapper">
                      <span
                        onClick={() => CustomerAndOrderList(true, 1)}
                        className="applyFilter"
                      >
                        Apply
                      </span>

                      <span onClick={resetFilter} className="resetFilter">
                        Reset
                      </span>
                    </div>
                  </WeekInput>
                )}
                <DashboardTable
                  header={
                    selectTab === 5
                      ? TableHeaderForTransactionsReconciliation
                      : TableHeader
                  }
                  tableData={tableData}
                />
                {selectTab === 5 && (
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <PaginationRounded
                      page={pageNumber}
                      PaginationRounded={sectionItemPage}
                      onChangePage={(_page) => myValue(_page)}
                    />
                  </div>
                )}
              </BoxContainer>
            )
          ) : (
            ""
          )}
        </>
      )}

      {selectTab === 6 && <DashboardPayments />}
    </div>
  );
}

const DashboardAglutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 510px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TabButton = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  div {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #f55a2c;
    box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    margin: 5px 10px;
    cursor: pointer;
    color: #f55a2c;
    &.active {
      color: #fff;
      background: #f55a2c;
    }
  }

  @media (max-width: 700px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

    div {
      text-align: center;
    }
  }

  @media (max-width: 459px) {
    div {
      font-size: 10px;
    }
  }

  @media (max-width: 392px) {
    div {
      font-size: 9px;
      padding: 10px 9px;
    }
  }
`;

export const WeekInput = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 510px) {
    flex-direction: column;
  }
  p {
    margin-right: 10px;
    font-weight: 500;
  }
  .textInput {
    display: flex;
    align-items: center;
    width: 100px;
    height: 42px;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 0 10px;
    margin: 10px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: ${({ disable }) => (disable ? "#E3E3E3" : "#fcfdfe")};
  }

  span {
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    margin: 5px 10px;
    cursor: pointer;
    color: #ffffff;
  }

  span:hover {
    display: flex;
    align-items: center;

    box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    margin: 5px 10px;
    cursor: pointer;
    color: #ffffff;
  }

  .filterBtnsWrapper {
    display: flex;
  }

  .InputWrapper {
    display: flex;
    align-items: center;
  }

  .applyFilter {
    background: lightblue;
  }
  .applyFilter:hover {
    background: rgba(56, 142, 242);
  }

  .resetFilter {
    background: rgb(244 30 30 / 40%);
  }
  .resetFilter:hover {
    background: #f41e1ef2;
  }
`;
