import React, { useEffect, useState } from "react";
import {
  BoxContainer,
  LoadingWrapper,
  OrangeButton,
  SubHeader,
} from "../../../../style/Gobalstyle";
import styled from "styled-components";
import IntlMessage from "../../../../Utils/IntlMessage";
import {
  GetAuthCodeDetails,
  deleteAuthCodes,
} from "../../../../services/Collection";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import DeleteAllMethods from "./DeleteAllMethods";

export default function ViewAuthCode() {
  const [authCodeData, setAuthCodeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState({
    type: null,
    status: false,
    data: null,
  });

  const AuthCodeID = useLocation()?.state;
  const navigate = useNavigate();

  const getAuthCodeDetais = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("auth_code_id", AuthCodeID);

    let res = await GetAuthCodeDetails(params.toString());
    if (res.status === 200) {
      setAuthCodeData(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleSelectedCard = (payload) => {
    if (selectedCards?.length > 0) {
      if (selectedCards.every((el) => el.id !== payload.id)) {
        setSelectedCards([...selectedCards, payload]);
      } else {
        let updatedArr = selectedCards?.filter((el) => el.id !== payload.id);
        setSelectedCards(updatedArr);
      }
    } else {
      setSelectedCards([payload]);
    }
  };

  const handleConfirm = async (payload, type) => {
    setLoading(true);

    let req = null;
    if (type === "singleDelete") {
      req = {
        type: 1,
        auth_code: [payload.id],
        auth_code_id: authCodeData?.id,
      };
    } else if (type === "selectedDelete") {
      let ids = [];
      if (payload?.length > 0) {
        payload?.map((ele) => ids.push(ele.id));
      }
      req = {
        type: 1,
        auth_code: ids,
        auth_code_id: authCodeData?.id,
      };
    } else if (type === "AllDelete") {
      req = {
        type: 1,
        auth_code_id: authCodeData?.id,
      };
    }

    let res = await deleteAuthCodes(req);
    if (res.status === 200) {
      setLoading(false);
      setOpenDeleteAlert({
        type: null,
        status: false,
        data: null,
      });
      if (res.data === 0) {
        navigate(-1);
      } else {
        getAuthCodeDetais();
      }
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleSingleDelete = (payload) => {
    setOpenDeleteAlert({
      type: "singleDelete",
      status: true,
      data: payload,
    });
  };
  const handleSelectedDelete = () => {
    setOpenDeleteAlert({
      type: "selectedDelete",
      status: true,
      data: selectedCards,
    });
  };
  const handleAllDelete = () => {
    setOpenDeleteAlert({
      type: "AllDelete",
      status: true,
      data: [],
    });
  };

  useEffect(() => {
    getAuthCodeDetais();
  }, []);

  if (loading) {
    return (
      <LoadingWrapper style={{ minHeight: "80vh" }}>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      {openDeleteAlert?.status && (
        <DeleteAllMethods
          open={openDeleteAlert?.status}
          handleClose={() =>
            setOpenDeleteAlert({
              type: null,
              status: false,
              data: null,
            })
          }
          handleConfirm={handleConfirm}
          payload={openDeleteAlert?.data}
          type={openDeleteAlert?.type}
          loading={loading}
        />
      )}
      <SubHeader>
        <p>Authorization Code - {authCodeData?.auth_code}</p>
        <div>
          {selectedCards?.length > 0 && (
            <OrangeButton onClick={() => handleSelectedDelete()}>
              {/* <IntlMessage id="button.DELETE" /> */}
              DELETE SELECTED
            </OrangeButton>
          )}

          <OrangeButton onClick={() => handleAllDelete()}>
            {/* <IntlMessage id="button.DELETE" /> */}
            DELETE ALL
          </OrangeButton>
        </div>
      </SubHeader>

      <AuthCodeViewWrapper>
        {authCodeData?.QrcodesAuthcodes?.length > 0 &&
          authCodeData?.QrcodesAuthcodes?.map((linkedQr) => (
            <AuthCodeCard>
              <input
                type="checkbox"
                onClick={() => handleSelectedCard(linkedQr)}
              />

              <section>
                <div>
                  <lable>QR Code Name</lable>
                  <span>{linkedQr?.QrCode?.name}</span>
                </div>

                <div>
                  <lable>QR Source</lable>
                  <span>{linkedQr?.QrCode?.QrCodeGroup?.group_type}</span>
                </div>

                <div>
                  <lable>Group Name</lable>
                  <span>{linkedQr?.QrCode?.QrCodeGroup?.group_name}</span>
                </div>
              </section>

              <div className="hoverSection">
                <OrangeButton onClick={() => handleSingleDelete(linkedQr)}>
                  <IntlMessage id="button.DELETE" />
                </OrangeButton>
              </div>
            </AuthCodeCard>
          ))}
      </AuthCodeViewWrapper>
    </div>
  );
}

const AuthCodeViewWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 40px 0;
`;

const AuthCodeCard = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  position: relative;

  input {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    position: absolute;
    z-index: 99;
    right: 0;
    margin: 10px;

    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  input:checked {
    background-color: #f55a2c;
  }

  section {
    padding: 40px 20px 20px 20px;

    div {
      display: flex;
      justify-content: space-between;

      label {
        font-family: "Jost";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #202020;
      }

      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #878787;
        padding: 5px 0;
      }
    }
  }

  .hoverSection {
    display: none;
  }

  &:hover .hoverSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: #00000099;
    top: 0;
    bottom: 0;
  }
`;
