import IntlMessage from "./IntlMessage";


export const ShowFieldValue = ({ field, value }) => {
    return (
      <div style={{ margin: "10px 0" }}>
        <label>
          <IntlMessage id={field} />:{" "}
        </label>
        <span>
          {value ? value : "---"}
        </span>
        <br />
      </div>
    )
  }