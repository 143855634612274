import {CurrentLocation,CashOnDelivery,PayAtRestaurant,Delivery} from "./svgIcons"

const Icons=(props)=>{
    const {name,...rest}=props
    switch(name){
        case "currentLocation":
            return <CurrentLocation {...rest}/>
        case "cashOnDelivery":
            return <CashOnDelivery {...rest}/>
        case "payAtRestaurant":
            return <PayAtRestaurant {...rest}/>
        case "delivery":
            return <Delivery {...rest}/>
        default :
            return <CurrentLocation {...rest}/>
    }
}
export default Icons