import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TickIcon } from "../../../Utils/Icons";
import IntlMessage from "../../../Utils/IntlMessage";

export default function DrapDropSectionItem({
  key,
  index,
  id,
  ele,
  moveCard,
  editMenu,
  deletingMenu,
  viewitem,
  Status,
}) {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);
  const dir = direction === "ltr";
  const ref = useRef(null);

  const ItemTypes = {
    CARD: "card",
  };

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      console.log(dragIndex, hoverIndex, ele, item, "dragIndex");
      moveCard(dragIndex, hoverIndex, ele, item);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {
        id,
        index,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const handleLoading = () => {
    setLoading(false);
  };

  return (
    <>
      <div
        className="itemCard"
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
        dir={direction}
      >
        <ItemCardStatus
          status={ele?.status}
          loading={loading}
          dir={dir}
          onClick={() => {
            Status(ele?.id, handleLoading);
            setLoading(true);
          }}
        >
          {loading ? (
            <CircularProgress
              size={18}
              thickness={8}
              sx={{ color: "#76ee59" }}
            />
          ) : (
            ele?.status && <TickIcon />
          )}
        </ItemCardStatus>
        <PricingItem dir={dir}>
          <p>QAR {ele?.Item_price}</p>
        </PricingItem>
        <img
          src={`${aqlutstorage}` + `${containerItem}` + `${ele?.image}`}
          alt=""
        />
        <div className="itemDetails">
          <h2>{dir ? ele?.name : ele?.ar_name}</h2>

          <p>{dir ? ele?.desc : ele?.ar_desc}</p>
          <label onClick={() => viewitem(ele)}>
            <IntlMessage id="sectionitem.button.seemore" />
          </label>
          <div className="action">
            <span>
              <i className="icon-View" onClick={() => viewitem(ele)} />
            </span>
            <span>
              <i className="icon-Edit" onClick={() => editMenu(ele)} />
            </span>
            <span>
              <i className="icon-Delete" onClick={() => deletingMenu(ele)} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

const ItemCardStatus = styled.div`
  position: absolute;
  top: 8px;
  right: ${({ dir }) => (dir ? "8px" : "none")};
  left: ${({ dir }) => (dir ? "none" : "8px")};
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: ${({ status, loading }) =>
    loading ? "#fff" : status ? "#76ee59" : "#a29d9d7d"};
  border: ${({ status, loading }) =>
    loading
      ? "none"
      : status
      ? "0.5px solid #52e452"
      : "0.5px solid #5d5d5d4a"};
  box-shadow: 0 0 8px #00000036;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PricingItem = styled.div`
  position: absolute;
  bottom: 0;
  right: ${({ dir }) => (dir ? "0" : "none")};
  left: ${({ dir }) => (dir ? "none" : "0")};
  background: #f55a2c;
  padding: 4px 12px;
  border-radius: ${({ dir }) => (dir ? "8px 0 0 0" : "0 8px 0 0")};

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`;
