import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LoadingWrapper, OrangeButton } from "../style/Gobalstyle";
import { CircularProgress } from "@mui/material";

export default function DialogBox({
  open,
  handleClose,
  title,
  subTitle,
  Content,
  handleSubmit,
  loading,
  SwitchAction
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  console.log(SwitchAction, "SwitchAction");
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {<h2>{title}</h2>} */}
          {title}
        </DialogTitle>
        <DialogContent>
          <h2 style={{ color: "#f55a2c", padding: "2px 0" }}>{subTitle}</h2>
          <DialogContentText>{Content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress sx={{ color: "#f55a2c", mx: 5, my: 2 }} />
          ) : (
            <OrangeButton onClick={()=>handleSubmit(SwitchAction)}>SUBMIT</OrangeButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
