import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MainSection } from "./OrderStyle";

function ExtraTable({
  intgration2,
  index,
  allData,
  allReadyExistExtra,
  moduleName,
  sendValues,
}) {
  const [defaultQuantity, setDefaultQuantity] = useState(1);
  const [findExtra, setFindExtra] = useState(false);
  const isPosEnable = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.pos_integration_status
  );
  const resCurrency = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.currency
  );

  // const increaseQuantity = () => {
  //   manageData.extraquantity = defaultQuantity + 1;
  //   manageData.extraprice = Number(intgration2.price) * (defaultQuantity + 1);
  //   setDefaultQuantity(defaultQuantity + 1);
  //   sendValues(checked, manageData);
  // };

  // const decreaseQuantity = () => {
  //   if (defaultQuantity > 0) {
  //     manageData.extraquantity = defaultQuantity - 1;
  //     manageData.extraprice =
  //       Number(intgration2.price) * manageData.extraquantity;
  //     setDefaultQuantity(defaultQuantity - 1);
  //     sendValues(checked, manageData);
  //   }
  // };


  const getCheckboxValue = (e, manageData) => {
    if (isPosEnable) {
      manageData.extraquantity = 1;
      manageData.extraprice = Number(intgration2.pos_option_item_price);
      manageData.realprice = Number(intgration2.pos_option_item_price);
      setDefaultQuantity(defaultQuantity + 1);
      sendValues(e, manageData);
    } else {
      manageData.extraquantity = 1;
      manageData.extraprice = Number(intgration2.price);
      manageData.realprice = Number(intgration2.price);
      setDefaultQuantity(defaultQuantity + 1);
      sendValues(e, manageData);
      console.log(e, manageData, "e, manageData");
    }
  };

  const ExistingExtra = () => {
    let result = allReadyExistExtra?.find((el) => {
      return el.option_item_id === intgration2.id;
    });
    setFindExtra(result ? true : false);
    if (result) {
      if (isPosEnable) {
        let sendNewObject = {
          extraprice: result.pos_option_item_price,
          realprice: result.pos_option_item_price / result.quantity,
          ...result,
        };
        sendValues(true, sendNewObject);
      } else {
        let sendNewObject = {
          extraprice: result.price,
          realprice: result.price / result.quantity,
          ...result,
        };
        sendValues(true, sendNewObject);
      }
    }
  };

  useEffect(() => {
    if (moduleName === "editItems") {
      ExistingExtra();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReadyExistExtra]);

  return (
    <div>
      <MainSection>
        <div>
          <input
            type="checkbox"
            name={intgration2?.name}
            checked={findExtra ? findExtra : null}
            onChange={(e) => getCheckboxValue(e.target.checked, intgration2)}
            className="addOnsInput"
          />
          <label>{intgration2?.name}</label>
        </div>

        <div style={{ display: "flex" }}>
          {/* <ExtraButton>
            <span onClick={increaseQuantity}>+</span>
            <p>{defaultQuantity}</p>
            <span onClick={decreaseQuantity}>-</span>
          </ExtraButton> */}
          {isPosEnable ? (
            <label className="labelPrice">
              QAR {intgration2.pos_option_item_price}
            </label>
          ) : (
            <label className="labelPrice">
              {resCurrency ? resCurrency : "QAR"} {intgration2.price}
            </label>
          )}
        </div>
      </MainSection>
    </div>
  );
}

export default ExtraTable;
