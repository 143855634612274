import React from "react";
import {
  CreateFilterWrapper,
  FilterFooter,
  SearchButton,
  WhiteButton,
} from "../../QRStyle";
import IntlMessage from "../../../../Utils/IntlMessage";
import {
  BlackButtonMobile,
  OrangeButtonForForm,
} from "../../../../style/Gobalstyle";
import { Cancel } from "../../../Menu/MenuStyle";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { searchForAuthCode } from "../QRCodeStore/QrCodeStore";
import styled from "styled-components";
import { useState } from "react";
import { useMemo } from "react";

export default function FilterAuthCode({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const dispatch = useDispatch();
  const authSearchWord = useSelector((state) => state?.qrCode?.searchAuthWord);
  const [getWord, setGetWord] = useState(authSearchWord);

  // const getWord = (e) => {
  //     dispatch(searchForAuthCode(e))
  // }

  const resetFilters = () => {
    dispatch(searchForAuthCode(""));
    setGetWord("");
    handleClose();
  };
  const applyFilters = () => {
    dispatch(searchForAuthCode(getWord));
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <CreateFilterWrapper>
          <header
            style={{ display: "flex", marginTop: "20px", padding: "0px 20px" }}
          >
            <i className="icon-Filters" />
            <p style={{ margin: "0px 10px" }}>
              <IntlMessage id="button.Filters" />
            </p>
          </header>
          <SearchAuthWrapper>
            <SearchButton>
              <i className="icon-Search" />

              <input
                type="text"
                placeholder="Search"
                // defaultValue={authSearchWord}
                value={getWord}
                onChange={(e) => setGetWord(e.target.value)}
              />
            </SearchButton>
          </SearchAuthWrapper>

          <FilterFooter style={{ padding: "10px 20px" }}>
            <WhiteButton
              style={{ color: "#F55A2C" }}
              onClick={() => resetFilters()}
            >
              Reset
            </WhiteButton>

            <OrangeButtonForForm
              type="sumbit"
              style={{ margin: "5px 0" }}
              onClick={() => applyFilters()}
            >
              Apply
            </OrangeButtonForForm>

            <BlackButtonMobile onClick={() => handleClose()}>
              <IntlMessage id="button.close" />
            </BlackButtonMobile>
          </FilterFooter>
        </CreateFilterWrapper>
      </Dialog>
    </div>
  );
}

const SearchAuthWrapper = styled.div`
  width: 410px;
  height: 100px;
  display: flex;
  padding: 0 20px;
  align-items: center;
`;
