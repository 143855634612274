import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Cancel } from "../../../Menu/MenuStyle";
import {
  CreateFilterWrapper,
  InputWrap,
  WhiteButton,
  WhiteButtons,
} from "../../QRStyle";
import GenerateOneByOne from "./GenerateOneByOne";
import GenerateInbulk from "./GenerateInbulk";

export default function AuthorizationOption({
  open,
  handleClose,
  getAllAuthCodes,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state.languageDirection.direction);
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState(null);
  const [generateOne, setGenerateOne] = useState(false);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <CreateFilterWrapper>
          <header
            style={{ display: "flex", marginTop: "20px", padding: "0px 20px" }}
          >
            <i className="icon-QRMenu" style={{ color: "#F55A2C" }} />
            <p style={{ width: "100%", fontSize: "16px", margin: "0px 10px" }}>
              Create authorization code
            </p>
          </header>

          <InputWrap dir={direction}>
            <div style={{ padding: "0px 20px", margin: "15px 0px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <WhiteButton
                  onClick={() => setUserFilter("2")}
                  color={userFilter}
                >
                  Generate One by One
                </WhiteButton>
                <WhiteButtons
                  onClick={() => setUserFilter("1")}
                  color={userFilter}
                >
                  Generate in bulk
                </WhiteButtons>
              </div>
            </div>
          </InputWrap>

          {userFilter === "2" && (
            <GenerateOneByOne
              handleClose={handleClose}
              getAllAuthCodes={getAllAuthCodes}
            />
          )}
          {userFilter === "1" && (
            <GenerateInbulk
              handleClose={handleClose}
              getAllAuthCodes={getAllAuthCodes}
            />
          )}
        </CreateFilterWrapper>
      </Dialog>
    </div>
  );
}
