import React, { useState } from "react";
import {
  BoxContainer,
  ButtonWrapper,
  LoadingWrapper,
  OrangeButton,
  OrangeButtonForForm,
  SelectIcon,
  SubHeader,
  WhiteButton,
} from "../../style/Gobalstyle";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DeleteButton,
  DetailBox,
  ExtraSection,
  ExtraSectionDetails,
  FieldGrid3,
  OptionSetWrap,
  SpecialNote,
  TotalSection,
} from "./OrderStyle";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { DependentField } from "../../validation/DependentField";
import { SelectInput } from "../Menu/MenuStyle";
import { useEffect } from "react";
import { activeMenuDetails, takeOrder } from "../../services/Collection";
import ExtraTable from "./ExtraTable";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { CircularProgress } from "@mui/material";
import TextArea from "../../validation/TextArea";
import InputField from "../../validation/InputField";
import IntlMessage from "../../Utils/IntlMessage";

export default function AddOrderDetail() {
  const [checkede, Setchecked] = useState(false);
  const [detailsActiveMenu, setDetailsActiveMenu] = useState([]);
  const [activeSection, setActiveSection] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const AddOrderValues = useSelector((state) => state?.RestaurantOrder);
  const [loading, setLoading] = useState(false);
  const isPosEnable = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.pos_integration_status
  );
  const resCurrency = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.currency
  );
  const navigate = useNavigate();

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    if (checked) {
      Setchecked(true);
    } else {
      Setchecked(false);
    }
  };

  // Yup is used for validation.
  let schema = yup.object().shape({
    order_items: yup.array().of(
      yup.object().shape({
        sections: yup.object().required("required"),
        sectionItems: yup.object().required("required"),
        quantity: yup.number().min(1, "required"),
      })
    ),
  });

  const TotalPrice = (payload) => {
    let sum = 0;
    if (payload?.quantity > 0) {
      payload?.extra.length > 0 &&
        payload?.extra?.map(
          (el) =>
            el?.extraprice &&
            (sum =
              sum +
              (isPosEnable ? el?.pos_option_item_price : el?.extraprice) *
                payload?.quantity)
        );
      if (isPosEnable) {
        return (
          payload.quantity *
            payload?.sectionItems?.ItemPrices?.[0].pos_item_price +
          sum
        );
      } else {
        return (
          payload.quantity * payload?.sectionItems?.ItemPrices?.[0].price + sum
        );
      }
    }
  };

  // This function is used to show Toast message if selected add-ons are less than or max than their limits.
  const handleAddOnMsg=(el,index)=>{
    const {sectionItems,extra}=el
    let toastObj={}
      for(let i=0;i<sectionItems?.MenuItemOptions.length;i++){
        let menuItemOptions=sectionItems?.MenuItemOptions[i]
        if(menuItemOptions["required"]==="1"){
          if(extra.length < menuItemOptions["min"]){
            toast.info(`minimum ${menuItemOptions["min"]} Add-on are required for ${sectionItems["name"]} item on ${index+1} position`);
            toastObj[sectionItems["id"]]=true
          }
          else if(extra.length > menuItemOptions["max"]){
            toast.info(`maximum ${menuItemOptions["max"]} Add-on are required for ${sectionItems["name"]} item on ${index+1} position`);
            toastObj[sectionItems["id"]]=true
          }
          else toastObj[sectionItems["id"]]=true
        }
      }
      return toastObj
  }

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    // setLoading(true);
    let newArr = [],toastObj={};
    values.order_items.map(async(el,index) => {
      let innerArr = [];
      newArr.push({
        section_id: el.sections.id,
        item_id: el.sectionItems.id,
        rough: !el?.extra
          ? []
          : el?.extra?.length > 0 &&
            el?.extra?.map((ele) =>
              innerArr?.push({
                option_group_id: ele.option_group_id,
                option_item_id: ele.id,
                price:
                  (isPosEnable ? ele.pos_option_item_price : ele.extraprice) *
                  el.quantity,
                quantity: ele.extraquantity * el.quantity,
              })
            ),

        order_items_addon: innerArr,

        special_notes: el.specialNote,
        ar_special_notes: "some random notes",
        amount: isPosEnable
          ? Number(el.sectionItems.ItemPrices[0].pos_item_price) *
            Number(el.quantity)
          : Number(el.sectionItems.ItemPrices[0].price) * Number(el.quantity),
        quantity: el.quantity,
      });
      // toastError=await handleAddOnMsg(el,index);

      const {sectionItems,extra}=el
      for(let i=0;i<sectionItems?.MenuItemOptions.length;i++){
        let menuItemOptions=sectionItems?.MenuItemOptions[i]
        if(menuItemOptions["required"]==="1"){
          if(extra.length < menuItemOptions["min"]){
            toast.info(`minimum ${menuItemOptions["min"]} Add-on are required for ${sectionItems["name"]} item on ${index+1} position`);
            toastObj[sectionItems["id"]]=true
            toastObj={...toastObj,[sectionItems["id"]]:true}
          }
          else if(extra.length > menuItemOptions["max"]){
            toast.info(`maximum ${menuItemOptions["max"]} Add-on are required for ${sectionItems["name"]} item on ${index+1} position`);
            toastObj={...toastObj,[sectionItems["id"]]:true}
          }
          else toastObj={...toastObj,[sectionItems["id"]]:false}
        }
      }

    });
    const objectsEqual = (o1, o2) =>
      typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          objectsEqual(o1["option_item_id"], o2["option_item_id"])
        : o1 === o2;

    const arraysEqual = (a1, a2) => {
      if (a1.length === 0 && a2.length === 0) return true;
      return (
        a1.length === a2.length &&
        a1.every((o, idx) => objectsEqual(o, a2[idx]))
      );
    };

    const sumModifiers = (a1, a2) => {
      return a1.map((el) => {
        const el2 = a2.find((e) => e.option_item_id === el.option_item_id);
        if (el2) {
          return {
            ...el,
            qty: Number(el.quantity) + Number(el2.quantity),
            ...(el.price && {
              price: Number(el.price) + Number(el2.price),
            }),
            ...(el.amount && {
              Total: Number(el.amount) + Number(el2.amount),
            }),
          };
        }
        return el;
      });
    };

    var result = [];

    newArr.forEach(function (a) {
      const mIds = a.order_items_addon
        .sort((a, b) => Number(a.option_item_id) - Number(b.option_item_id))
        .map((e) => e.option_item_id)
        .join("-");
      const id = `${a.item_id}-${mIds}`;
      if (!this[id]) {
        this[id] = a;
        result.push(this[id]);
      } else if (
        this[id] &&
        !arraysEqual(this[id].order_items_addon, a.order_items_addon)
      ) {
        result.push(a);
      } else {
        this[id].quantity = Number(this[id].quantity) + Number(a.quantity);
        this[id].amount = Number(this[id].amount) + Number(a.amount);
        this[id].order_items_addon = sumModifiers(
          this[id].order_items_addon,
          a.order_items_addon
        );
      }
    }, Object.create(null));

    let req = {
      customer_name: AddOrderValues?.addOrder?.customerName,
      remark: null,
      qr_code_id: AddOrderValues?.addOrder?.qrCodeName.id,
      qr_code_group_id: AddOrderValues?.addOrder?.qrCodeGroup.id,
      order_type: AddOrderValues?.addOrder?.qrCodeGroup?.group_type,
      phone_number: AddOrderValues?.addOrder?.phone_number,
      order_items: result,
    };

    const check = Object.values(toastObj).some(val => val === true);
    if(!check){
      setLoading(true);
      let res = await takeOrder(req);
      if (res.status === 200) {
        setLoading(false);
        navigate("/aglut/orders");
        toast.info(res.message);
      } else {
        const message = getErrorMessage(res, "Failed to connection");
        toast.error(message);
        setLoading(false);
      }
    }
  };

  const getactiveMenuDetails = async () => {
    let res = await activeMenuDetails();
    if (res.status === 200) {
      setDetailsActiveMenu(res.data);
      setActiveSection(res.data.Sections);
    }
  };

  const selectedSectionDetails = (name, value) => {
    setSelectedItems(value?.Items);
  };

  const selectedSectionItemDetails = (name, value) => {
    return value?.MenuItemOptions;
  };

  const getsectionItemsList = (value) => {
    return value?.Items || [];
  };

  const updateFiledData = useCallback((obj, arr, tick) => {
    if (tick) {
      if (Array.isArray(arr)) {
        if (arr.find((el) => el.id === obj.id)) {
          return arr.map((el) => {
            if (el.id === obj.id) {
              return obj;
            }
            return el;
          });
        } else {
          return [...arr, obj];
        }
      } else {
        return [obj];
      }
    } else {
      return arr.filter((el) => el.id !== obj.id);
    }
  }, []);

  useEffect(() => {
    getactiveMenuDetails();
  }, []);

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      <SubHeader>
        <p>
          <IntlMessage id="Orders.createOrder.addOrderDeatils.heading" />
        </p>
      </SubHeader>

      <Formik
        initialValues={{
          order_items: [
            {
              sections: "",
              sectionItems: "",
              quantity: 0,
              extra: [],
              specialNote: "",
            },
          ],
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }) => (
          <Form>
            <BoxContainer>
              <FieldArray name="order_items">
                {({ insert, remove, push, replace }) => (
                  <div>
                    {values?.order_items?.length > 0 &&
                      values?.order_items?.map((el, index) => (
                        <div className="row" key={index}>
                          <DetailBox>
                            <section>
                              <FieldGrid3>
                                <OptionSetWrap>
                                  <div>
                                    <p>
                                      <IntlMessage id="Orders.createOrder.addOrderDeatils.section" />
                                    </p>
                                    <Field
                                      name={`order_items.${index}.sections`}
                                      component={DependentField}
                                      getChanges={selectedSectionDetails}
                                      options={activeSection}
                                      index={index}
                                      getOptionLabel={(option) =>
                                        option ? option?.section_name : ""
                                      }
                                      renderInput={(params) => (
                                        <div
                                          ref={params.InputProps.ref}
                                          style={{ position: "relative" }}
                                        >
                                          <SelectInput
                                            placeholder="Sections"
                                            type="text"
                                            {...params.inputProps}
                                          />

                                          <SelectIcon
                                            className="icon-DropDownArrow"
                                            dir="ltr"
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>
                                </OptionSetWrap>

                                <OptionSetWrap>
                                  <div>
                                    <p>
                                      <IntlMessage id="Orders.createOrder.addOrderDeatils.sectionItems" />
                                    </p>

                                    <Field
                                      name={`order_items.${index}.sectionItems`}
                                      component={DependentField}
                                      getChanges={selectedSectionItemDetails}
                                      options={getsectionItemsList(
                                        values?.order_items[index]?.sections
                                      )}
                                      getOptionLabel={(option) =>
                                        option ? option?.name : ""
                                      }
                                      resetExtra={(v) => {
                                        setFieldValue(
                                          `order_items.${index}.extra`,
                                          []
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <div
                                          ref={params.InputProps.ref}
                                          style={{ position: "relative" }}
                                        >
                                          <SelectInput
                                            placeholder="Section Items"
                                            type="text"
                                            {...params.inputProps}
                                          />

                                          <SelectIcon
                                            className="icon-DropDownArrow"
                                            dir="ltr"
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>
                                </OptionSetWrap>

                                <OptionSetWrap>
                                  <div>
                                    <p>
                                      <IntlMessage id="Orders.createOrder.addOrderDeatils.quantity" />
                                    </p>
                                    <Button>
                                      <span
                                        onClick={() =>
                                          setFieldValue(
                                            `order_items.${index}.quantity`,
                                            el.quantity + 1
                                          )
                                        }
                                      >
                                        +
                                      </span>

                                      <Field
                                        className="quantityInput"
                                        type="text"
                                        value={el.quantity}
                                        name={`order_items.${index}.quantity`}
                                        component={InputField}
                                        readOnly
                                      />

                                      {el.quantity > 1 ? (
                                        <span
                                          onClick={(e) =>
                                            setFieldValue(
                                              `order_items.${index}.quantity`,
                                              el.quantity - 1
                                            )
                                          }
                                        >
                                          -
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </Button>
                                  </div>
                                </OptionSetWrap>
                              </FieldGrid3>
                              <OptionSetWrap>
                                <p style={{ marginBottom: "5px" }}>
                                  <IntlMessage id="Orders.createOrder.addOrderDeatils.extra" />
                                </p>
                              </OptionSetWrap>

                              <ExtraSection>
                                <ExtraSectionDetails>
                                  {values?.order_items[index]?.sectionItems
                                    ?.MenuItemOptions?.length > 0 &&
                                    values?.order_items[
                                      index
                                    ].sectionItems?.MenuItemOptions?.map(
                                      (intgration1) => (
                                        <>
                                          <p className="modifierGroup-label">
                                            {
                                              intgration1?.OptionGroupModule
                                                ?.name
                                            }
                                          </p>
                                          {intgration1?.OptionGroupModule?.OptionItemModules?.map(
                                            (intgration2) =>
                                              intgration2.status && (
                                                <Field
                                                  name={`order_items.${index}.extra`}
                                                  component={ExtraTable}
                                                  getChanges={
                                                    selectedSectionItemDetails
                                                  }
                                                  intgration2={intgration2}
                                                  index={index}
                                                  allData={
                                                    intgration1
                                                      ?.OptionGroupModule
                                                      ?.OptionItemModules
                                                  }
                                                  allReadyExistExtra={
                                                    values?.order_items[index]
                                                      ?.extra
                                                  }
                                                  sendValues={(e, object) =>
                                                    setFieldValue(
                                                      `order_items.${index}.extra`,
                                                      updateFiledData(
                                                        object,
                                                        values?.order_items[
                                                          index
                                                        ]?.extra,
                                                        e
                                                      )
                                                    )
                                                  }
                                                />
                                              )
                                          )}
                                        </>
                                      )
                                    )}
                                </ExtraSectionDetails>
                              </ExtraSection>

                              <TotalSection>
                                <div>
                                  <input
                                    type="checkbox"
                                    name="special"
                                    value="specialnote"
                                    onChange={handleChange}
                                  />
                                  <label>
                                    <IntlMessage id="Orders.createOrder.addOrderDeatils.specialNote" />
                                  </label>
                                </div>
                                <span>
                                  <IntlMessage id="Orders.createOrder.addOrderDeatils.itemAmount" />{" "}
                                  : {resCurrency ? resCurrency : "QAR"}{" "}
                                  {TotalPrice(values?.order_items[index])}
                                </span>
                              </TotalSection>

                              {checkede && (
                                <SpecialNote>
                                  <Field
                                    type="text"
                                    name={`order_items.${index}.specialNote`}
                                    placeholder="Special Note"
                                    component={TextArea}
                                  />
                                </SpecialNote>
                              )}
                            </section>
                            {values?.order_items?.length > 1 && (
                              <DeleteButton onClick={() => remove(index)}>
                                <i className="icon-Delete" />
                              </DeleteButton>
                            )}
                          </DetailBox>
                        </div>
                      ))}

                    <div className="justifyLeft">
                      <WhiteButton
                        type="button"
                        onClick={() =>
                          push({
                            sections: "",
                            sectionItems: "",
                            quantity: 0,
                            extra: "",
                          })
                        }
                      >
                        + <IntlMessage id="button.ADDORDER" />
                      </WhiteButton>
                    </div>
                  </div>
                )}
              </FieldArray>

              <ButtonWrapper>
                <OrangeButtonForForm
                  // onClick={() => navigate("/aglut/orders/OrderDetails/:123")}
                  type="submit"
                >
                  <IntlMessage id="button.SUBMIT" />
                </OrangeButtonForForm>
              </ButtonWrapper>
            </BoxContainer>
          </Form>
        )}
      />
    </div>
  );
}
