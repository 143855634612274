import React, { createRef, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButton,
  OrangeButtonForForm,
  SelectIcon,
} from "../../../style/Gobalstyle";
import { useSelector } from "react-redux";
import { CreateQRWrapper, InputWrap } from "../QRStyle";
import { QRCode } from "react-qrcode-logo";
import { AglutLogo } from "../../../Utils/Images";
import { Field, Form, Formik } from "formik";
import InputField from "../../../validation/InputField";
import { DependentField } from "../../../validation/DependentField";
import { BoxContent, SelectInput } from "../../Menu/MenuStyle";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { createQrCode, getQrGroupListing } from "../../../services/Collection";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../../Utils/IntlMessage";

export default function CreateQR({ open, handleClose, fetch }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [qrtable, setQrtable] = useState("");
  const [loading, setLoading] = useState(false);
  const [menuGroupData, setMenuGroupData] = useState([]);
  const ref = createRef(null);

  const generateQR = () => {
    let UserBaseUrl = process.env.REACT_APP_USER_BASEURL;
    setQrtable(UserBaseUrl);
  };

  const EnRegex = "([a-zA-Z]s*)+";
  const ArRegex = "[\u0600-\u06FF]";

  // Yup is used for validation.
  let schema = yup.object().shape({
    qrCodeName: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Reqiured"),
    qrCodeNameAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Reqiured"),
    menuGroup: yup.object().required("Reqiured"),
  });

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    // const screenshotImage = await takeScreenshot(ref.current);
    setLoading(true);
    let req = {
      qr_code_group_id: values.menuGroup.id,
      name: values.qrCodeName,
      ar_name: values.qrCodeNameAr,
      // image: btoa(screenshotImage),
    };

    let res = await createQrCode(req);
    if (res.status === 200) {
      setLoading(false);
      handleClose();
      fetch();
      toast.info(res.message);
    } else {
      handleClose();
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getAllQrGroup = async () => {
    let res = await getQrGroupListing("1");
    if (res.status === 200) {
      setMenuGroupData(res.data);
    }
  };

  useEffect(() => {
    getAllQrGroup();
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Cancel onClick={handleClose} dir={direction}>
        <i className="icon-CloseCross" />
      </Cancel>

      <CreateQRWrapper>
        <>
          <Formik
            initialValues={{
              qrCodeName: "",
              qrCodeNameAr: "",
              menuGroup: "",
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values }) => (
              <Form>
                <BoxContent>
                  <header>
                    <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" />
                  </header>

                  <InputWrap dir={direction}>
                    <section>
                      <label>QR Code Name / اسم رمز الاستجابة السريعة </label>
                    </section>

                    <Field
                      dir="ltr"
                      type="text"
                      name="qrCodeName"
                      placeholder="QR Code Name"
                      component={InputField}
                    />

                    <Field
                      dir="rtl"
                      type="text"
                      name="qrCodeNameAr"
                      placeholder="سم رمز الاستجابة السريعة "
                      component={InputField}
                    />
                  </InputWrap>

                  <InputWrap dir={direction}>
                    <section>
                      <label>
                        <IntlMessage id="qrMenu.qRCodes.createQrCode.menuGroup" />
                      </label>
                    </section>
                    <Field
                      name="menuGroup"
                      component={DependentField}
                      options={menuGroupData}
                      getChanges={() => function Close() {}}
                      getOptionLabel={(option) =>
                        option ? option?.group_name : ""
                      }
                      renderInput={(params) => (
                        <div
                          ref={params.InputProps.ref}
                          style={{ position: "relative" }}
                        >
                          <SelectInput
                            placeholder="Menu Group"
                            Dir={direction}
                            type="text"
                            r
                            {...params.inputProps}
                          />

                          <SelectIcon
                            className="icon-DropDownArrow"
                            dir="ltr"
                          />
                        </div>
                      )}
                    />
                  </InputWrap>

                  {values?.qrCodeName &&
                    values?.qrCodeNameAr &&
                    values?.menuGroup && (
                      <p onClick={generateQR} style={{ cursor: "pointer" }}>
                        <IntlMessage id="button.genrateQrCode" />
                      </p>
                    )}
                  <h4>
                    <IntlMessage id="qrMenu.qRCodes.createQrCode.autoGen" />
                  </h4>

                  <div ref={ref}>
                    {qrtable && (
                      <QRCode
                        value={qrtable}
                        logoImage={AglutLogo}
                        padding={0}
                        size={200}
                        removeQrCodeBehindLogo={true}
                        // qrStyle="dots"
                        logoOpacity={0.7}
                        logoWidth={20}
                      />
                    )}
                  </div>

                  <div style={{ textAlign: "center" }}>
                    {loading ? (
                      <CircularProgress sx={{ color: "#f55a2c" }} />
                    ) : (
                      <>
                        <BlackButtonMobile onClick={() => handleClose()}>
                          <IntlMessage id="button.close" />
                        </BlackButtonMobile>
                        <OrangeButtonForForm type="submit">
                          <IntlMessage id="button.SUBMIT" />
                        </OrangeButtonForForm>
                      </>
                    )}
                  </div>
                </BoxContent>
              </Form>
            )}
          />
        </>
      </CreateQRWrapper>
    </Dialog>
  );
}
