import React, { useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import AppLocale from "./language/languageIndex";
import AglutRoutes from "./routes/AglutRoutes";
import "./style/CustomClasses.css";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { useCallback } from "react";
import { getErrorMessage } from "./Utils/ErrorMessage";
import { useEffect } from "react";
import { pusher } from "./pusher/PusherConfig";
import { pusherRealtimeSuccess } from "./pusher/PusherStore";
import { checkposPayment, getMessageIdentifier } from "./services/Collection";
import { useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { loginSuccess } from "./app/Auth/Login/LoginStore";
import axios from "axios";

function App() {
  const playButton = useRef();
  const language = useSelector((state) => state.languageDirection.language);
  const currentAppLocale = AppLocale[language];
  const dispatch = useDispatch();
  const audioPlayer = useRef(null);
  const [channel, setChannel] = useState(null);
  const Restaurentid = useSelector(
    (state) => state.profileDetails?.restaurantDetails?.id
  );

  const credentials = useSelector((state) => state.loginAuth.token);

  const getNotification = useCallback(async () => {
    const getAllUnreadNotifications = (data) => {
      console.log("channel data", data);
      if (data.getAllMessage) {
        dispatch(pusherRealtimeSuccess(data.getAllMessage));
        // audioPlayer.current.play();
      } else {
        const message = getErrorMessage("Failed to connection");
        toast.error(message);
      }
      // setLoading(false);
    };

    let res = await getMessageIdentifier();

    if (res) {
      const channel = pusher.subscribe(
        `All-ChatBoat-messages${
          process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
        }`
      );
      console.log("channel", channel);
      if (channel) {
        channel.bind(
          `Event-All-ChatBoat-Messages${
            process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
          }`,
          getAllUnreadNotifications
        );
      }
      return () => {
        if (channel) {
          pusher.unsubscribe(
            `All-ChatBoat-messages${
              process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
            }`
          );
          channel.unbind(
            `Event-All-ChatBoat-Messagest${
              process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
            }`,
            getAllUnreadNotifications
          );
        }
      };
    }
  }, [dispatch]);

  const playAudio = useCallback(() => {
    if (audioPlayer.current) {
      audioPlayer.current.play();
    }
  }, [audioPlayer]);

  const onPusherData = useCallback(
    (data) => {
      if (data.unReadCount) {
        console.log("data23423",data);
        toast.info(`${data.getOrder}`, { theme: "colored" });
        playButton.current.click();
        dispatch(pusherRealtimeSuccess(data));
        getNotification();
      } else {
        const message = getErrorMessage("Failed to connection");
        toast.error(message);
      }
      // setLoading(false);
    },
    [dispatch, getNotification]
  );

  const getAllParams = window?.location?.search;

  const handleSuperAdminLogin = (paramsData) => {
    dispatch(loginSuccess(null));
    let data = paramsData?.replaceAll(`%22`, "").split("secretkey");
    const EndPoint =
      process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;
    axios({
      url: `${EndPoint}admin/loginAsRestaurantOwner?restaurent_id=${data?.[1]}`,
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${data?.[0]}`,
      },
    })
      .then((response) => {
        dispatch(loginSuccess(response?.data?.data));
      })
      .catch((err) => {
        const message = getErrorMessage(err, "Failed to connection");
        toast.error(message);
        dispatch(loginSuccess(null));
      });
  };

  useEffect(() => {
    console.log(getAllParams, "getAllParams");
    getAllParams && handleSuperAdminLogin(getAllParams?.replace(`?`, ""));
  }, []);

  useMemo(() => {
    if (channel)
    channel.bind(
      `Event-Sent-Single-Restaurent${
        process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
      }`,
        onPusherData
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (credentials && Restaurentid) {
      setChannel(
        pusher.subscribe(
          `Message-Send-Single-Restaurent${
            process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
          }`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, Restaurentid]);
  //test commemt

  return (
    <DndProvider backend={HTML5Backend}>
      <IntlProvider
        locale={currentAppLocale}
        defaultLocale="en"
        messages={currentAppLocale.messages}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <audio
          ref={audioPlayer}
          src="https://aqlutprodstoragmain.blob.core.windows.net/publicaqlut/notificationSound.mp3"
        />
        <button
          style={{ display: "none" }}
          ref={playButton}
          type="hidden"
          onClick={playAudio}
        />
        <AglutRoutes />
      </IntlProvider>
    </DndProvider>
  );
}

export default App;
