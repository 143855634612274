import React from "react";
import styled from "styled-components";
import { Bounce } from "react-reveal";
import Spin from "react-reveal/Spin";
import Flash from "react-reveal/Flash";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
//added a comment 

export default function DashboardCard({ item }) {
  return (
    <Bounce bottom>
      <Card style={{ background: item.backgroundColor }}>
        <div className="logo">
          <Flash>
            <img src={item.logo} alt="Logo" />
          </Flash>
        </div>
        <div className="content">
          <Fade left>
            <div>
              <sub>{item?.subheader}</sub>
              <header>{item?.header}</header>
            </div>
          </Fade>
          <Spin>
            <CountUp start={0} end={item.count} delay={1.5}
            />
          </Spin>
        </div>
      </Card>
    </Bounce>
  );
}

const Card = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 6px 18px rgba(61, 107, 192, 0.18);
  border-radius: 10px;
  display: flex;
  padding: 10px;
  .logo {
    width: 30%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70px;
      height: 70px;
    }
  }
  .content {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    padding: 10px 0;

    sub {
      font-size: 18px;
      line-height: 19px;
      color: #000000;
      opacity: 0.8;
    }

    header {
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      font-weight: 600;
    }
    p {
      font-size: 42px;
      line-height: 38px;
      color: #000000;
      font-weight: 700;
    }

    span {
      font-size: 42px;
          line-height: 38px;
          color: #000000;
          font-weight: 700;
    }

  }
`;
