import React from "react";
import Dialog from "@mui/material/Dialog";
import { Cancel } from "../../style/Gobalstyle";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";

export default function FeedbackModal({ open, handleClose, data }) {
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("datadata1212", data);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <FeedbackModalStyle>
          <div className="heading">
            User Overall Satisfaction with our restaurant
          </div>
          {data?.QrCode?.id && (
            <div style={{display:"flex"}}>
              <span>
                <span>Qr Code Id:</span> <span className="color">{data?.QrCode?.id}</span>
              </span>
              <span>
                <span>Qr Code Name:</span> <span className="color">{data?.QrCode?.name}</span>
              </span>
              <span>
                <span>Qr Code Group Name:</span>{" "}
                <span className="color">{data?.QrCode?.QrCodeGroup?.group_name}</span>
              </span>
            </div>
          )}
          <div>
            <p>Is this your first time at our restaurant ?</p>
            <div>
              <div className={data?.is_first_visit ? "btn active" : "btn"}>
                YES
              </div>
              <div className={!data?.is_first_visit ? "btn active" : "btn"}>
                NO
              </div>
            </div>
          </div>
          <div>
            <p>what is your overall satisfaction?</p>
            <div>
              {Array(Number(data?.gratification_rating))
                .fill("")
                .map(() => (
                  <i className="icon-Star star" />
                ))}
            </div>
          </div>
          <div>
            <p>How would you rate the hygiene ?</p>
            <div>
              {Array(Number(data?.sanitation_rating))
                .fill("")
                .map(() => (
                  <i className="icon-Star star" />
                ))}
            </div>
          </div>
          <div>
            <p>How would you rate the taste of our food ?</p>
            <div>
              {Array(Number(data?.taste_rating))
                .fill("")
                .map(() => (
                  <i className="icon-Star star" />
                ))}
            </div>
          </div>
          <div style={{ width: "90%" }}>
            <p>what do you think of our staff ?</p>
            <div>
              {Array(Number(data?.staff_feedback))
                .fill("")
                .map(() => (
                  <i className="icon-Star star" />
                ))}
            </div>
          </div>
          <div>
            <p>Would you come back to eat with us again ?</p>
            <div>
              <div className={data?.return_intent ? "btn active" : "btn"}>
                YES
              </div>
              <div className={!data?.return_intent ? "btn active" : "btn"}>
                NO
              </div>
            </div>
          </div>
          <div style={{ width: "90%", gap: "6px" }}>
            <p>Is there anything else you want to tell us ?</p>
            <p className="text">{data?.additional_comments}</p>
          </div>
          <div style={{ width: "90%", gap: "6px" }}>
            <p>Name</p>
            <p className="text">{data?.name}</p>
          </div>
          <div style={{ width: "90%", gap: "6px" }}>
            <p>Email address</p>
            <p className="text">{data?.email}</p>
          </div>
        </FeedbackModalStyle>
      </Dialog>
    </div>
  );
}

const FeedbackModalStyle = styled.div`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: rgb(87, 89, 98);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #f55a2c;
    border-radius: 10px;
  }

  .star {
    font-size: 24px;
    margin: 0 4px;
  }
  .heading {
    font-size: 24px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;

    p {
      text-align: center;
    }
  }
  .btn {
    width: 90px;
    margin-right: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    outline: none;
    user-select: none;
    cursor: pointer;
    border: 1px solid #f05a2c;
    color: #f05a2c;
    background-color: transparent;
    border-radius: 100px;
    padding: 6px 16px;
  }
  .active {
    background-color: #f05a2c50;
  }
  .color{
    color: #f05a2c;
  }
  .text {
    max-width: 500px;
    color: #f05a2c;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
  }

`;
