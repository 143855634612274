import { CircularProgress, Dialog, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButtonForForm,
} from "../../style/Gobalstyle";

import { Field, Form, Formik } from "formik";
import {
  BoxContent,
  CreateWrapper,
  InputWrap,
  MiddleContent,
} from "./IntegrationStyle";
import InputField from "../../validation/InputField";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { EditPosDetails } from "../../services/Collection";
import styled from "styled-components";

export default function EditPos({ open, handleClose, fetchData, data }) {
  const direction = useSelector((state) => state.languageDirection.direction);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(() => {
    if (data) {
      return {
        posUrl: data.pos_url,
        employeeNumber: data.employee_no,
        revenueCenter: data.revenue_center,
        orderidTender: data.service_tender_media_id,
        paymentidTender: data.payment_tender_media_id,
        modifierGroupID: data.modifier_major_group_id,
        takeaway_check_order_type: data.takeaway_check_order_type,
        delivery_check_order_type: data.delivery_check_order_type,
      };
    }
    return {
      posUrl: "",
      employeeNumber: "",
      revenueCenter: "",
      orderidTender: "",
      paymentidTender: "",
      modifierGroupID: "",
      takeaway_check_order_type: "",
      delivery_check_order_type: ""
    };
  }, [data]);

  // Yup is used for validation.
  let schema = yup.object().shape({
    posUrl: yup.string().required("Please enter POS url"),
    employeeNumber: yup.string().required("Please enter employee number"),
    revenueCenter: yup.string().required("Please enter revenue center number"),
    orderidTender: yup
      .string()
      .required("Please enter order id of tender media number"),
    paymentidTender: yup
      .string()
      .required("Please enter order payment id of tender media number"),
    modifierGroupID: yup.string().required("Please enter modifier group id"),
    takeaway_check_order_type: yup.string().required("Please enter takeaway check for order type"),
    delivery_check_order_type: yup.string().required("Please enter delivery check for order type"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    let req = {
      pos_url: values.posUrl,
      employee_no: values.employeeNumber,
      revenue_center: values.revenueCenter,
      service_tender_media_id: values.orderidTender,
      payment_tender_media_id: values.paymentidTender,
      modifier_major_group_id: values.modifierGroupID,
      takeaway_check_order_type: values.takeaway_check_order_type,
      delivery_check_order_type: values.delivery_check_order_type,
    };

    let res = await EditPosDetails(req);
    if (res.status === 200) {
      setLoading(false);
      handleClose();
      fetchData();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        // fullWidth={true}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <CreateWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div>
                <BoxContent>
                  <MiddleContent>
                    <header>
                      {/* <IntlMessage id="Menu.createMenu.Heading" /> */}
                      Edit POS Info
                    </header>
                  </MiddleContent>

                  <div>
                    <InputWrap dir={direction}>
                      <section>
                        <label>POS URL</label>
                      </section>

                      <Field
                        name="posUrl"
                        placeholder="POS URL "
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir={direction}>
                      <section>
                        <label>Employee Number</label>
                      </section>

                      <Field
                        name="employeeNumber"
                        placeholder="Employee Number"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir={direction}>
                      <section>
                        <label>Revenue Center</label>
                      </section>

                      <Field
                        name="revenueCenter"
                        placeholder="Revenue Center"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir={direction}>
                      <section>
                        <label>Order ID (Tender media)</label>
                      </section>

                      <Field
                        type="number"
                        name="orderidTender"
                        placeholder="Order ID (Tender media)"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir={direction}>
                      <section>
                        <label>Payment ID (Tender media)</label>
                      </section>

                      <Field
                        type="number"
                        name="paymentidTender"
                        placeholder="Payment ID (Tender media)"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir={direction}>
                      <section>
                        <label>Modifier Group ID (Major)</label>
                      </section>

                      <Field
                        type="number"
                        name="modifierGroupID"
                        placeholder="Modifier Group ID"
                        component={InputField}
                      />
                    </InputWrap>


                    <InputWrap dir={direction}>
                      <section>
                        <label>Takeaway check for order type</label>
                      </section>

                      <Field
                        type="number"
                        name="takeaway_check_order_type"
                        placeholder="Takeaway check for order type"
                        component={InputField}
                      />
                    </InputWrap>



                    <InputWrap dir={direction}>
                      <section>
                        <label>Delivery check for order type</label>
                      </section>

                      <Field
                        type="number"
                        name="delivery_check_order_type"
                        placeholder="Delivery check for order type"
                        component={InputField}
                      />
                    </InputWrap>



                  </div>
                </BoxContent>

                <MiddleContent style={{ marginTop: "20px" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#f55a2c" }} />
                  ) : (
                    <div style={{ display: "flex" }}>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        {/* <IntlMessage id="button.close" /> */}
                        Close
                      </BlackButtonMobile>
                      <OrangeButtonForForm type="submit">
                        {/* <IntlMessage id="button.SUBMIT" /> */}
                        submit
                      </OrangeButtonForForm>
                    </div>
                  )}
                </MiddleContent>
              </div>
            </Form>
          </Formik>
        </CreateWrapper>
      </Dialog>
    </div>
  );
}

const OrangeButton = styled.button`
  background: #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 20px;
  margin: 5px 10px;
  cursor: pointer;
  type: submit;
  @media (max-width: 490px) {
    font-size: 12px;
    padding: 10px;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;
