import React, { useRef, useState } from "react";
import { AglutLogo } from "../../../Utils/Images";
import { AuthBox, AuthWrapper, BoxContent, LogoWrapper } from "./Forgetstyle";
import { CircularProgress } from "@mui/material";
import { ForgotPasswordApi } from "../../../services/Authapi";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../Utils/ErrorMessage";


export default function ForgetPassword() {
  const [loading,setLoading]=useState(false)
  const emailRef = useRef()

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true)
    let res=await ForgotPasswordApi({"email":emailRef.current.value})
    //ForgotPasswordApi
    if (res.status === 200) {
      setLoading(false);
      toast.success(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  }

  return (
    <AuthWrapper>
      <section>
        <AuthBox>
          <LogoWrapper>
            <img src={AglutLogo} alt="AGLUT LOGO" />
          </LogoWrapper>
          <BoxContent>
            <header>Forgot Password</header>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Email</label>
                <input ref={emailRef} name="email" type="email" placeholder="Email" required/>
              </div>
              {loading ?
                <CircularProgress />
                :
                <button type="submit">Reset</button>
              }
            </form>
          </BoxContent>
        </AuthBox>
      </section>
    </AuthWrapper>
  );
}
