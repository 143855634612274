import React from "react";
import OtpInput from "react-otp-input";

export default function OtpGenrater({ numInputs, inputValues, otp,shouldAutoFocus }) {
  const getValues = (otp) => {
    inputValues(otp);
  };

  return (
    <div>
      <OtpInput
        value={otp}
        onChange={getValues}
        numInputs={numInputs}
        renderSeparator={<span style={{ margin: "0 5px" }}></span>}
        inputStyle={inputStyle}
        renderInput={(props) => <input {...props} />}
        shouldAutoFocus={shouldAutoFocus}
        
      />
    </div>
  );
}

const inputStyle = {
  width: "100%",
  height: "42px",
  border: "2px solid #f55a2c",
  borderRadius: "8px",
  margin: "20px 0",
};
