import React from "react";
import {
  BoxContainer,
  FilterButton,
  FilterSection,
  LoadingWrapper,
  NODATA,
  OrangeButton,
} from "../../../../style/Gobalstyle";
import QRTable from "../../QRTable";
import IntlMessage from "../../../../Utils/IntlMessage";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import PaginationRounded from "../../../../components/PaginationRounded";
import { useState } from "react";
import {
  FilterWrapper,
  SearchButton,
  SearchFEwrapper,
  ShowButton,
} from "../../QRStyle";
import { ExportFeature } from "../../../../Utils/ExportFiles";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import {
  deleteAuthCodes,
  getAllAuthCodeDetails,
  searchAuthCode,
} from "../../../../services/Collection";
import DeleteAllMethods from "./DeleteAllMethods";
import { useSelector } from "react-redux";
import UnlinkedQrCode from "./UnlinkedQrCode";
import FilterAuthCode from "./FilterAuthCode";
import styled from "styled-components";

export default function AuthQRCode({
  loadingAuthCode,
  authCodeData,
  authCodePage,
  TableActionAuthCodes,
  setPagenumberAuthCode,
  getNumberAuthCode,
  perPageListingAuthCode,
  getAllAuthCodes,
  getWord,
}) {
  const UserHeader = [
    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "Select",
      key: "select",
    },

    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "Auth Code",
      key: "auth_code",
    },

    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "QR Code",
      key: "name",
    },

    {
      //   id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "Status",
      key: "status",
    },

    {
      id: <IntlMessage id="qrMenu.qRCodes.Action" />,
      key: "Action",
    },
  ];
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;

  const [selectedCards, setSelectedCards] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const [loading, setLoading] = useState(false);
  const [openUnlinkedQrcodes, setOpenUnlinkedQrcodes] = useState(false);
  const authSearchWord = useSelector((state) => state?.qrCode?.searchAuthWord);

  console.log("authCodeData", authCodeData);

  const selectedItems = (payload) => {
    if (selectedCards?.length > 0) {
      if (
        selectedCards.every(
          (el) => el.qrcode_authcode !== payload.qrcode_authcode
        )
      ) {
        setSelectedCards([...selectedCards, payload]);
      } else {
        let updatedArr = selectedCards?.filter(
          (el) => el.qrcode_authcode !== payload.qrcode_authcode
        );
        setSelectedCards(updatedArr);
      }
    } else {
      setSelectedCards([payload]);
    }
  };

  const [openDeleteAlert, setOpenDeleteAlert] = useState({
    type: null,
    status: false,
    data: null,
  });

  const handleAllDelete = () => {
    setOpenDeleteAlert({
      type: "AllDelete",
      status: true,
      data: [],
    });
  };

  const handleSelectedDelete = () => {
    setOpenDeleteAlert({
      type: "selectedDelete",
      status: true,
      data: selectedCards,
    });
  };

  const handleConfirm = async (payload, type) => {
    setLoading(true);

    let req = null;
    if (type === "selectedDelete") {
      let ids = [];
      if (payload?.length > 0) {
        payload?.map((ele) => ids.push(ele.id));
      }
      req = {
        type: 2,
        auth_code: ids,
      };
    } else if (type === "AllDelete") {
      req = {
        type: 2,
      };
    }

    let res = await deleteAuthCodes(req);
    if (res.status === 200) {
      toast.info(res.message);
      setLoading(false);
      setOpenDeleteAlert({
        type: null,
        status: false,
        data: null,
      });
      getAllAuthCodes();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getNumber = (payload) => {
    getNumberAuthCode(payload);
  };

  const myValue = (pages) => {
    setPagenumberAuthCode(pages);
  };

  return (
    <div>
      {filterModal && (
        <FilterAuthCode
          open={filterModal}
          handleClose={() => setFilterModal(false)}
        />
      )}
      {openUnlinkedQrcodes && (
        <UnlinkedQrCode
          open={openUnlinkedQrcodes}
          handleClose={() => setOpenUnlinkedQrcodes(false)}
        />
      )}
      {openDeleteAlert?.status && (
        <DeleteAllMethods
          open={openDeleteAlert?.status}
          handleClose={() =>
            setOpenDeleteAlert({
              type: null,
              status: false,
              data: null,
            })
          }
          handleConfirm={handleConfirm}
          payload={openDeleteAlert?.data}
          type={openDeleteAlert?.type}
          loading={loading}
        />
      )}

      <BoxContainer>
        <FilterSection>
          <ShowButton>
            <p>
              <IntlMessage id="button.show" />
            </p>
            <input
              type="number"
              value={perPageListingAuthCode}
              onChange={(e) => getNumber(e.target.value)}
            />
          </ShowButton>
          <SearchFEwrapper>
            <FilterWrapper onClick={() => setOpenUnlinkedQrcodes(true)}>
              <FilterButton>
                <p>UNLINKED QR CODES</p>
              </FilterButton>
            </FilterWrapper>

            {selectedCards?.length > 0 && (
              <FilterWrapper onClick={() => handleSelectedDelete()}>
                <FilterButton>
                  <p>DELETE SELECTED</p>
                </FilterButton>
              </FilterWrapper>
            )}

            <FilterWrapper onClick={() => handleAllDelete()}>
              <FilterButton>
                <p>DELETE ALL</p>
              </FilterButton>
            </FilterWrapper>

            <SearchButton>
              <i className="icon-Search" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => getWord(e.target.value)}
              />
            </SearchButton>
            <FilterWrapper>
              <FilterButton onClick={() => setFilterModal(!filterModal)}>
                {authSearchWord != "" && <RedDot></RedDot>}
                <i className="icon-Filter" />
                <p>
                  <IntlMessage id="button.Filters" />
                </p>
              </FilterButton>
            </FilterWrapper>

            <FilterButton
              onClick={() =>
                ExportFeature(
                  `${aqlutstorage}${containerExport}AuthCodelist${RestaurentID}.xlsx` 
                )
              }
            >
              <i className="icon-Export" />
              <p>
                <IntlMessage id="button.Export" />
              </p>
            </FilterButton>
          </SearchFEwrapper>
        </FilterSection>

        {loadingAuthCode ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {authCodeData?.length === 0 ? (
              <NODATA>
                {/* <IntlMessage id="qrMenu.qRCodes.heading" /> */}
                NO DATA FOUND
              </NODATA>
            ) : (
              <QRTable
                header={UserHeader}
                tableData={authCodeData}
                action={TableActionAuthCodes}
                selectedItems={selectedItems}
                selectedCards={selectedCards}
              />
            )}
          </>
        )}

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <PaginationRounded
            PaginationRounded={authCodePage}
            onChangePage={(_page) => myValue(_page)}
          />
        </div>
      </BoxContainer>
    </div>
  );
}

const RedDot = styled.div`
  width: 18px;
  height: 18px;
  background: red;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
`;
