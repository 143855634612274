import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { discountListing } from "../../services/Collection";
import {
  BoxContainer,
  LoadingWrapper,
  NODATA,
  OrangeButton,
  SubHeader,
} from "../../style/Gobalstyle";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import IntlMessage from "../../Utils/IntlMessage";
import DiscountTable from "./DiscountTable";
import DiscountAppliedOn from "./DiscountAppliedOn";

export default function Discount() {
  const [loading, setLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const navigate = useNavigate();
  const discountPermission = useSelector(
    (state) => state?.loginAuth?.permissions?.discount
  );
  const [discountAppiledOn, setDiscountAppliedOn] = useState(false);

  const UserHeader = [
    {
      id: <IntlMessage id="invoicePayment.srNo" />,
      key: "SrNo",
    },
    {
      id: <IntlMessage id="disFreeItems.offerTitle" />,
      key: "title",
    },
    {
      id: <IntlMessage id="disFreeItems.Type" />,
      key: "discount_type",
    },
    {
      id: <IntlMessage id="Menu.createItem.Description" />,
      key: "description",
    },
    {
      id: <IntlMessage id="Menu.sectionItem.Action" />,
      key: "action",
    },
  ];

  const getDiscountListing = async () => {
    setLoading(true);
    let res = await discountListing();
    if (res.status === 200) {
      setDiscountList(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const TableAction = {
    apply: true,
    view: true,
    edit: discountPermission,
    delete: discountPermission,
    fetchdata: getDiscountListing,
  };

  useEffect(() => {
    getDiscountListing();
  }, []);

  return (
    <div>
      {discountAppiledOn && (
        <DiscountAppliedOn
          open={discountAppiledOn}
          handleClose={() => setDiscountAppliedOn(false)}
        />
      )}
      <SubHeader>
        <p>
          <IntlMessage id="disFreeItems.Heading" />
        </p>
        <div>
          {/* {discountPermission && (
            <OrangeButton onClick={() => setDiscountAppliedOn(true)}>
              Discount apply on
            </OrangeButton>
          )} */}

          {discountPermission && (
            <OrangeButton onClick={() => navigate("/aglut/discount/create")}>
              <IntlMessage id="button.createDis" />
            </OrangeButton>
          )}
            <OrangeButton onClick={() => navigate("/aglut/discount/customerdiscount")}>
              {/* <IntlMessage id="button.createDis" /> */}
             CUSTOMER DISCOUNT
            </OrangeButton>
        </div>
      </SubHeader>
      <BoxContainer>
        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {discountList?.length === 0 ? (
              <NODATA>
                <IntlMessage id="noData" />
              </NODATA>
            ) : (
              <DiscountTable
                header={UserHeader}
                tableData={discountList}
                action={TableAction}
              />
            )}
          </>
        )}
      </BoxContainer>
    </div>
  );
}
