import axios from "axios";
import React, { useState } from "react";
import { OrangeButton } from "../style/Gobalstyle";

export default function TestingPaymentGateway() {
  const SkipPay = async () => {
    let url = "https://skipcashtest.azurewebsites.net/api/v1/payments";
    let payloadobject = {
      uid: "01916FC2-411B-4465-A3A8-F7699AD2F757",
      keyId: "c8c1c22b-eb2f-4c2a-85a2-71d66a022619",
      amount: "500.00",
      firstName: "Jay",
      lastName: "Kum",
      email: "jay21@gmail.com",
      street: "phase5",
      city: "chandigarh",
      state: "CH",

      country: "IN",
      postalCode: "201012",
      transactionId: "jdbcdsjh",
      custom1: "dcbdhc",
    };

    try {
      let res = await axios({
        method: "post",
        url: url,
        data: payloadobject,
        headers: {
          Authorization: `6YV63mDleYo2F2oIbo+2YW8avdY3DvQ2fbJw+hMmMQM=`,
          "Content-type": "application/json; charset=utf-8",
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div>
      <OrangeButton onClick={SkipPay}>PAY</OrangeButton>
    </div>
  );
}
