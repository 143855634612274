import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Dialog,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    BlackButtonMobile,
    Cancel,
    OrangeButtonForForm,
} from "../../style/Gobalstyle";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { profileDetails, updatedDiscountAppiedon } from "../../services/Collection";

export default function DiscountAppliedOn({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const direction = useSelector((state) => state?.languageDirection?.direction);
    const [qrcodeData, setQrcodeData] = useState([
        { name: "Dine In", id: 1, isChecked: false },
        { name: "Takeaway", id: 2, isChecked: false },
        { name: "Delivery", id: 3, isChecked: false },
    ]);
    const [loading, setLoading] = useState(false);

    const handleSelectedItems = (isSelected, item) => {
        console.log(isSelected, item, "dslcdfh");
        let tempValues = [];
        for (let index = 0; index < qrcodeData.length; index++) {
            if (qrcodeData[index].id === item.id) {
                tempValues.push({ ...item, isChecked: isSelected })
            } else {
                tempValues.push(qrcodeData[index]);
            }
        }
        setQrcodeData(tempValues);
    };

    const getProfileDetails = async () => {
        setLoading(true);
        let res = await profileDetails();
        if (res.status === 200) {
            let temp = [];
            for (let index = 0; index < qrcodeData.length; index++) {
                if (qrcodeData[index].name === qrcodeData.name) {
                    temp.push({ ...qrcodeData[index], isChecked: true });
                } else {
                    temp.push({ ...qrcodeData[index], isChecked: true });
                }
            }
            setQrcodeData(temp);
            setLoading(false);
        } else {
            const message = getErrorMessage(res, "Failed to connection");
            toast.error(message);
            setLoading(false);
        }
    };

    const updateSelectedDiscountAppliedOn = async () => {
        setLoading(true);
        let payload = [];
        for (let index = 0; index < qrcodeData.length; index++) {
            if (qrcodeData[index]?.isChecked) {
                payload.push(qrcodeData[index].name);
            }
        };
        let req = { discount_applicable_on: payload };
        let res = await updatedDiscountAppiedon(req);
        if (res.status === 200) {
            setLoading(false);
            handleClose();
            const message = getErrorMessage(res, "successfully");
            toast.success(message);
        } else {
            const message = getErrorMessage(res, "Failed to connection");
            toast.error(message);
            setLoading(false);
        }
    };


    useEffect(() => {
        getProfileDetails()
    }, [])


    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <Cancel onClick={handleClose} dir={direction}>
                <i className="icon-CloseCross" />
            </Cancel>

            <DiscountAppliedWrapper>
                <header>
                    {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" /> */}
                    Discount Appied on
                </header>

                <div>
                    {qrcodeData?.map((item) => (
                        <section>
                            <div class="custom-checkbox">
                                <input
                                    type="checkbox"
                                    onClick={(e) => handleSelectedItems(e.target.checked, item)}
                                    checked={item.isChecked}
                                />
                                <svg viewBox="0 0 35.6 35.6">
                                    <circle
                                        class="background"
                                        cx="17.8"
                                        cy="17.8"
                                        r="17.8"
                                    ></circle>
                                    <circle class="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                                    <polyline
                                        class="check"
                                        points="11.78 18.12 15.55 22.23 25.17 12.87"
                                    ></polyline>
                                </svg>
                            </div>

                            <span>{item?.name}</span>
                        </section>
                    ))}
                </div>

                <div style={{ textAlign: "center" }}>
                    {loading ? (
                        <CircularProgress sx={{ color: "#f55a2c" }} />
                    ) : (
                        <>
                            <BlackButtonMobile onClick={() => handleClose()}>
                                <IntlMessage id="button.close" />
                            </BlackButtonMobile>
                            <OrangeButtonForForm type="submit" onClick={updateSelectedDiscountAppliedOn} >
                                <IntlMessage id="button.SUBMIT" />
                            </OrangeButtonForForm>
                        </>
                    )}
                </div>
            </DiscountAppliedWrapper>
        </Dialog>
    );
}

const DiscountAppliedWrapper = styled.div`
  width: 413px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 30px 20px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }

  header {
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }

  @media (max-width: 599px) {
    width: 100%;
    height: 100%;
  }

  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-conent: center;
    gap: 20px;

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      -webkit-letter-spacing: 0.05em;
      -moz-letter-spacing: 0.05em;
      -ms-letter-spacing: 0.05em;
      letter-spacing: 0.05em;
      color: #000000;
    }
  }

  div {
    margin: 10px 0;

    //custom checkbox style here
    .custom-checkbox {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 25px;
      .background {
        fill: #000;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      .stroke {
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 2px;
        stroke-dashoffset: 100;
        stroke-dasharray: 100;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      .check {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        stroke-dashoffset: 22;
        stroke-dasharray: 22;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      input[type="checkbox"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
        opacity: 0;
        -appearance: none;
        -webkit-appearance: none;

        &:hover {
          cursor: pointer;
          & + svg {
            .background {
              fill: #cecece;
            }
            .check {
              stroke-dashoffset: 0;
            }
          }
        }

        &:checked {
          & + svg {
            .background {
              fill: #f55a2c;
            }
            .stroke {
              stroke-dashoffset: 0;
            }
            .check {
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }
  }
`;
