import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { getOrderListing } from "../../../services/Collection";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { pusher } from "../../../pusher/PusherConfig";
import { useSelector } from "react-redux";
import { getNotificationMsg } from "../../../Utils/functions";
import { LoadingWrapper } from "../../../style/Gobalstyle";
import { CircularProgress } from "@mui/material";
import {
  CardWrapper,
  OrderAvailableCard,
  OrderOccupiedCard,
} from "../OrderStyle";
import { useNavigate } from "react-router-dom";
import IntlMessage from "../../../Utils/IntlMessage";
import { NODATA } from "../TAKEAWAY/Takeaway";
import PaginationRounded from "../../../components/PaginationRounded";
import moment from "moment";

export default function PrePayment() {
  const [loading, setLoading] = useState(false);
  const [orderPage, setOrderPage] = useState(null);
  const [pageNumber, setPagenumber] = useState(1);
  const [prePaymentData, setPrePaymentData] = useState(null);
  const Restaurentid = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const Notification = useSelector((state) => state?.pusherRealtime?.data);
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const navigate = useNavigate();

  const gotoOrderDetail = (id) => {
    navigate(`/aglut/orders/OrderDetails/${id}`, {
      state: { id: id, type: "PrePayment" },
    });
  };

  const orderDateFormat = (date) => {
    let orderDate = new Date(date * 1000);
    let formatDate = moment(orderDate).format("YYYY-MM-DD");
    let formatTime = moment(orderDate).format("HH:mm");

    return formatDate + "/" + formatTime;
  };

  const myValue = (pages) => {
    setPagenumber(pages);
    getDineInListing(pages);
  };

  const getDineInListing = useCallback(async (pages) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("order_type", 5);
    params.append("pageNumber", pages);
    let res = await getOrderListing(params.toString());
    if (res.status === 200) {
      setOrderPage(res.extraData);
    } else {
      const message = getErrorMessage("Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  }, []);

  const onPusherData = useCallback(
    (data) => {
      console.log(data, "----------PRE PAYAMENT----data");
      if (data) {
        const newUpdatedData = getNotificationMsg(data, Notification);
        setPrePaymentData(newUpdatedData);
      } else {
        const message = getErrorMessage("Failed to connection");
        toast.error(message);
      }
      setLoading(false);
    },
    [Notification]
  );

  useEffect(() => {
    getDineInListing();
    const channel = pusher.subscribe(
      `Pre_Paid_Orders${
        process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
      }`
    );
    if (channel)
      channel.bind(
        `Event-Pre-Paid${
          process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid + Restaurentid
        }`,
        onPusherData
      );

    return () => {
      if (channel) {
        pusher.unsubscribe(
          `Pre_Paid_Orders${
            process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
          }`
        );
        channel.unbind(
          `Event-Pre-Paid${
            process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid + Restaurentid
          }`,
          onPusherData
        );
      }
    };
  }, [Notification]);

  return (
    <div>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <CardWrapper>
          {prePaymentData && prePaymentData.length > 0 ? (
            prePaymentData?.map((iteration1) =>
              iteration1?.status !== "available" ? (
                <OrderOccupiedCard
                  dir={direction}
                  onClick={() => gotoOrderDetail(iteration1.id)}
                  key={iteration1?.id}
                >
                  {iteration1.Orders &&
                    iteration1?.Orders.map(
                      (el) =>
                        el?.message.length > 0 &&
                        el?.message?.map((ele) => (
                          <div className="Notification">
                            {el?.message.length > 0 && (
                              <span>{el?.message.length}</span>
                            )}

                            <p>{ele.message}</p>
                          </div>
                        ))
                    )}

                  <section>
                    <i className="icon-Dining" />
                  </section>
                  <div>
                    <label>
                      <IntlMessage id="Orders.Status" /> :{" "}
                    </label>
                    <span>{iteration1?.order_type}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.customerName" /> :{" "}
                    </label>
                    <span>{iteration1?.OrderItems?.[0]?.customer_name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.orderDate" />:{" "}
                    </label>
                    <span>{orderDateFormat(iteration1?.order_date)}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.orderStatus" /> :{" "}
                    </label>
                    <span>
                      {iteration1?.order_status === "1" ? (
                        <IntlMessage id="Orders.Pending" />
                      ) : iteration1?.order_status === "2" ? (
                        <IntlMessage id="Orders.Accepted" />
                      ) : iteration1?.order_status === "3" ? (
                        <IntlMessage id="Orders.Preparation" />
                      ) : iteration1?.order_status === "4" ? (
                        <IntlMessage id="Orders.Ready" />
                      ) : iteration1?.order_status === "5" ? (
                        <IntlMessage id="Orders.Cancel" />
                      ) : (
                        iteration1?.order_status === "6" && (
                          <IntlMessage id="Orders.completed" />
                        )
                      )}
                    </span>
                  </div>
                </OrderOccupiedCard>
              ) : (
                <OrderAvailableCard
                  dir={direction}
                  //  onClick={()=>gotoOrderDetail(iteration2.id)}
                  key={iteration1?.id}
                >
                  <section>
                    <i className="icon-Dining" />
                  </section>
                  <div>
                    <label>
                      <IntlMessage id="Orders.Status" /> :{" "}
                    </label>
                    <span>{iteration1?.status}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeName" /> :{" "}
                    </label>
                    <span>{iteration1?.name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeGroup" />:{" "}
                    </label>
                    <span>{iteration1?.qr_qroup_name}</span>
                  </div>
                </OrderAvailableCard>
              )
            )
          ) : (
            <NODATA>
              <IntlMessage id="noData" />
            </NODATA>
          )}
        </CardWrapper>
      )}

      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <PaginationRounded
          PaginationRounded={orderPage}
          onChangePage={(_page) => myValue(_page)}
        />
      </div>
    </div>
  );
}
