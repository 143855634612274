import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButton,
} from "../../../style/Gobalstyle";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../../Utils/IntlMessage";
import { CreateFilterWrapper, FilterFooter, WhiteButton, WhiteButtons } from "../QRStyle";

export default function QRMenuFilter({
  open,
  handleClose,
  getAllsection,
  notication,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state.languageDirection.direction);
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState(notication);

  const reset = () => {
    handleClose();
    getAllsection();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <CreateFilterWrapper>
          <header
            style={{ display: "flex", marginTop: "20px", padding: "0px 20px" }}
          >
            <i className="icon-Filters" />
            <p style={{ margin: "0px 10px" }}>
              <IntlMessage id="button.Filters" />
            </p>
          </header>

          <InputWrap dir={direction}>
            <div style={{ padding: "0px 20px", margin: "15px 0px" }}>
              <section>
                <label>
                  {/* <IntlMessage id="userManagement.firstName" /> */}
                  Group Name
                </label>
              </section>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <WhiteButton
                  onClick={() => setUserFilter("2")}
                  color={userFilter}
                >
                  Dine In
                </WhiteButton>
                <WhiteButtons
                  onClick={() => setUserFilter("1")}
                  color={userFilter}
                >
                  Takeaway
                </WhiteButtons>
              </div>
            </div>
            <div style={{ textAlign: "center", margin: "80px 0px 10px 0px" }}>
              {loading ? (
                <CircularProgress sx={{ color: "#f55a2c" }} />
              ) : (
                <>
                  <FilterFooter>
                    <WhiteButton onClick={reset}>
                      {/* <IntlMessage id="button.CHANGEPHOTO" />
                       */}
                      Reset
                    </WhiteButton>

                    <OrangeButton style={{ margin: "5px 0" }}>
                      Apply
                    </OrangeButton>

                    <BlackButtonMobile onClick={() => handleClose()}>
                      <IntlMessage id="button.close" />
                    </BlackButtonMobile>
                  </FilterFooter>
                </>
              )}
            </div>
          </InputWrap>
        </CreateFilterWrapper>
      </Dialog>
    </div>
  );
}


export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  width: 100%;
  // height: 100px;
  background: #f7f7f7;
  border: 1px solid #f0f1f7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  // padding: 0 10px;
  // margin: 5px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;