import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import {
  BoxContent,
  CreateWrapper,
  InputPageWrapper,
  InputWrap,
  MiddleContent,
} from "../Menu/MenuStyle";
import IntlMessage from "../../Utils/IntlMessage";
import TextArea from "../../validation/TextArea";
import InputField from "../../validation/InputField";
import {
  OrangeButtonForForm,
  BlackButtonMobile,
  Cancel,
} from "../../style/Gobalstyle";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import { DatePicker } from "antd";
// import moment from "moment";
import dayjs from "dayjs";
import {
  CreateMobileDiscountApi,
  EditMobileDiscountApi,
} from "../../services/Collection";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { toast } from "react-toastify";

export default function CustomerDiscountModal({
  open,
  handleClose,
  handleNewData,
  payload,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const direction = useSelector((state) => state?.languageDirection?.direction);

  const EnRegex = "([a-zA-Z]s*)+";
  const ArRegex = "[\u0600-\u06FF]";
  // Yup is used for validation.
  let schema = yup.object().shape({
    name: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter name"),
    description: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter description"),
    ar_description: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter description"),

    country_code: yup.string().required("Country code is required"),
    mobile_no: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{7,14}$/, "Phone number is not valid"),
    discount_percentage: yup
      .number()
      .required("Discount percentage is required")
      .min(0, "Discount percentage must be at least 0%")
      .max(100, "Discount percentage must be at most 100%"),
    expiry_date: yup.number().required("Expiry date is required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    let res = "";
    if (payload) {
      const obj = {
        discount_id: payload?.id,
        ...values,
      };
      res = await EditMobileDiscountApi(obj);
    } else {
      res = await CreateMobileDiscountApi(values);
    }
    if (res?.status === 200) {
      setLoading(false);
      handleClose();
      handleNewData();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <CreateWrapper>
          <Formik
            initialValues={{
              ar_description: payload?.ar_description ?? "",
              country_code: payload?.country_code ?? "",
              description: payload?.description ?? "",
              discount_percentage: payload?.discount_percentage ?? "",
              expiry_date: payload?.expiry_date ?? null,
              mobile_no: payload?.mobile_no ?? "",
              name: payload?.name ?? "",
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue, errors, touched }) => (
              <Form>
                <BoxContent>
                  {console.log("values", values)}
                  <MiddleContent>
                    <header>
                      {/* <IntlMessage id="Menu.createSection.Heading" /> */}
                      {payload ? "Edit" : "Create"} Discount
                    </header>
                  </MiddleContent>
                  <InputPageWrapper>
                    <InputWrap dir="ltr">
                      <section>
                        <label>Name</label>
                      </section>

                      <Field
                        type="text"
                        name="name"
                        placeholder="Name"
                        component={InputField}
                      />
                    </InputWrap>
                    <InputWrap dir="ltr">
                      <section>
                        <label>Phone Number</label>
                      </section>
                      <PhoneInput
                        name="mobile_no"
                        country={"qa"}
                        value={values?.country_code + values?.mobile_no || ""}
                        onChange={(value, data) => {
                          setFieldValue(`country_code`, "+" + data.dialCode);
                          setFieldValue(
                            `mobile_no`,
                            value.substring(data.dialCode.length, 20)
                          );
                        }}
                        containerStyle={{
                          width: "100%",
                          height: "42px",
                          background: "#fcfdfe",
                          border: "1px solid #f0f1f7",
                          borderRadius: "8px",
                          margin: "8px 0px 0px",
                        }}
                        inputStyle={{
                          border: "none",
                          width: "100%",
                          height: "40px",
                        }}
                        placeholder="Phone Number"
                      />
                      {touched.mobile_no && errors.mobile_no && (
                        <div className="error">{errors.mobile_no}</div>
                      )}
                    </InputWrap>
                  </InputPageWrapper>

                  <InputPageWrapper>
                    <InputWrap dir="ltr">
                      <section>
                        <label>Discount Percentage</label>
                      </section>

                      <Field
                        type="text"
                        name="discount_percentage"
                        placeholder="Discount Percentage"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrapTime>
                      <section>
                        <label>Expire Date</label>
                      </section>
                      <DatePicker
                        label="Basic date picker"
                        value={
                          values?.expiry_date
                            ? dayjs(Number(values?.expiry_date))
                            : null
                        }
                        onChange={(date, dateString) => {
                          const timestamp = date ? date.valueOf() : null;
                          setFieldValue("expiry_date", timestamp);
                        }}
                      />
                       {touched.expiry_date && errors.expiry_date && (
                        <div className="error">{errors.expiry_date}</div>
                      )}
                    </InputWrapTime>
                  </InputPageWrapper>

                  <InputPageWrapper>
                    <InputWrap dir="ltr">
                      <section>
                        <label>Description</label>
                      </section>

                      <Field
                        type="description"
                        name="description"
                        placeholder="Description"
                        component={TextArea}
                      />
                    </InputWrap>

                    <InputWrap dir="rtl">
                      <section>
                        <label> وصف</label>
                      </section>

                      <Field
                        type="description"
                        name="ar_description"
                        placeholder="وصف"
                        component={TextArea}
                      />
                    </InputWrap>
                  </InputPageWrapper>
                </BoxContent>
                <MiddleContent style={{ marginTop: "20px" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#f55a2c" }} />
                  ) : (
                    <div style={{ display: "flex" }}>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        <IntlMessage id="button.close" />
                      </BlackButtonMobile>
                      <OrangeButtonForForm type="submit">
                        <IntlMessage id="button.SUBMIT" />
                      </OrangeButtonForForm>
                    </div>
                  )}
                </MiddleContent>
              </Form>
            )}
          />
        </CreateWrapper>
      </Dialog>
    </div>
  );
}

export const InputWrapTime = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin: 5px 0px;

  i {
    font-size: 18px;
  }

  label {
    margin: 0 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .error {
    margin: 0;
    color: #f44336;
    font-size: 10px;
    font-weight: 500;
    text-align: start;
    padding: 5px 0;
  }
`;
