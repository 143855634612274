import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaginationRounded from "../../../components/PaginationRounded";
import { pusher } from "../../../pusher/PusherConfig";
import { getOrderListing } from "../../../services/Collection";
import { LoadingWrapper, NODATA } from "../../../style/Gobalstyle";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { getNotificationMsg } from "../../../Utils/functions";
import IntlMessage from "../../../Utils/IntlMessage";
import {
  CardWrapper,
  OrderAvailableCard,
  OrderOccupiedCard,
  TakeawayCard,
} from "../OrderStyle";

export default function Unified() {
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [takeawayData, setTakeawayData] = useState([]);
  const Notification = useSelector((state) => state?.pusherRealtime?.data);
  const Restaurentid = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const [orderPage, setOrderPage] = useState(null);
  const [pageNumber, setPagenumber] = useState(1);

  const gotoOrderDetail = (id) => {
    navigate(`/aglut/orders/OrderDetails/${id}`, {
      state: { id: id, type: "Dinein" },
    });
  };

  const myValue = (pages) => {
    setPagenumber(pages);
    getUnifiedOrderData(pages);
  };

  const onPusherData = useCallback(
    (data) => {
      console.log("dat---------a", data);
      if (data) {
        const newUpdatedData = getNotificationMsg(data, Notification);
        setTakeawayData(newUpdatedData);
      } else {
        const message = getErrorMessage("Failed to connection");
        toast.error(message);
      }
      setLoading(false);
    },
    [Notification]
  );

  const getUnifiedOrderData = useCallback(async (pages) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("order_type", 6);
    params.append("pageNumber", pages);
    let res = await getOrderListing(params.toString());
    if (res.status === 200) {
      setOrderPage(res.extraData);
    } else {
      const message = getErrorMessage("Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUnifiedOrderData();
    const channel = pusher.subscribe(
      `DELIVERY-ORDERS${
        process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
      }`
    );
    if (channel)
      channel.bind(
        `Delivery${
          process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
        }`,
        onPusherData
      );

    return () => {
      if (channel) {
        pusher.unsubscribe(
          `DELIVERY-ORDERS${
            process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
          }`
        );
        channel.unbind(
          `Delivery${
            process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
          }`,
          onPusherData
        );
      }
    };
  }, [Notification]);

  const orderDateFormat = (date) => {
    let orderDate = new Date(date * 1000);
    let formatDate = moment(orderDate).format("YYYY-MM-DD");
    let formatTime = moment(orderDate).format("HH:mm");

    return formatDate + "/" + formatTime;
  };

  let checkstatus = false;

  takeawayData?.map((el) => {
    if (el?.status == "occupied") checkstatus = true;
  });

  return (
    <div>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <CardWrapper>
          {takeawayData && checkstatus ? (
            takeawayData?.map((iteration1) =>
              iteration1?.Orders?.map((iteration3) => (
                <TakeawayCard
                  dir={direction}
                  onClick={() => gotoOrderDetail(iteration3?.order_id)}
                >
                  {iteration1.Orders &&
                    iteration1?.Orders.map(
                      (el) =>
                        el?.message.length > 0 &&
                        el?.message?.map((ele) => (
                          <div className="Notification">
                            {el?.message.length > 0 && (
                              <span>{el?.message.length}</span>
                            )}

                            <p>{ele.message}</p>
                          </div>
                        ))
                    )}

                  <section>
                    <i className="icon-Dining" />
                  </section>

                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeName" /> :{" "}
                    </label>
                    <span>{iteration1?.name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeGroup" /> :{" "}
                    </label>
                    <span>{iteration1?.qr_qroup_name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.orderStatus" /> :{" "}
                    </label>
                    <span>
                      {iteration3?.order_status === "1" ? (
                        <IntlMessage id="Orders.Pending" />
                      ) : iteration3?.order_status === "2" ? (
                        <IntlMessage id="Orders.Accepted" />
                      ) : iteration3?.order_status === "3" ? (
                        <IntlMessage id="Orders.Preparation" />
                      ) : iteration3?.order_status === "4" ? (
                        <IntlMessage id="Orders.Ready" />
                      ) : iteration3?.order_status === "5" ? (
                        <IntlMessage id="Orders.Cancel" />
                      ) : (
                        iteration3?.order_status === "6" && (
                          <IntlMessage id="Orders.completed" />
                        )
                      )}
                    </span>
                  </div>
                </TakeawayCard>
              ))
            )
          ) : (
            <NODATA>
              <IntlMessage id="noData" />
            </NODATA>
          )}
        </CardWrapper>
      )}

      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <PaginationRounded
          PaginationRounded={orderPage}
          onChangePage={(_page) => myValue(_page)}
        />
      </div>
    </div>
  );
}
