import {
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Cancel, LoadingWrapper } from "../../style/Gobalstyle";
import IntlMessage from "../../Utils/IntlMessage";
import { CardWrapper, OrderAvailableCard } from "./OrderStyle";

export default function ShiftTable({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <CardWrapper style={{ margin: "20px" }}>
         

            <OrderAvailableCard
              dir={direction}
              // key={iteration2?.id}
            >
              <section>
                <i className="icon-Dining" />
              </section>
              <div>
                <label>
                  <IntlMessage id="Orders.Status" /> :{" "}
                </label>
                <span>
                  {/* {iteration2?.status} */}
                  jsbd
                </span>
              </div>
              <div>
                <label>
                  <IntlMessage id="Orders.qrCodeName" /> :{" "}
                </label>
                <span>
                  {/* {iteration2?.name} */}
                  dfcds
                </span>
              </div>
              <div>
                <label>
                  <IntlMessage id="Orders.qrCodeGroup" />:{" "}
                </label>
                <span>
                  sadasdasd
                  {/* {iteration2?.qr_qroup_name}*/}
                </span>
              </div>

              {/* <ButtonWrapper>
              <BlackButtonMobile onClick={() => handleClose()}>
                <IntlMessage id="button.close" />
              </BlackButtonMobile>
              <OrangeButton onClick={() => setopenCompletedPayment(true)}>
                <IntlMessage id="Order.completed.payment.heading" />
              </OrangeButton>
            </ButtonWrapper> */}
            </OrderAvailableCard>




      

          </CardWrapper>
        )}
      </Dialog>
    </div>
  );
}
