import React, { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButton,
  SelectIcon,
} from "../../../style/Gobalstyle";
import { useSelector } from "react-redux";
import { DependentField } from "../../../validation/DependentField";
import { SelectInput } from "../../Menu/MenuStyle";
import styled from "styled-components";
import InputField from "../../../validation/InputField";
import { qrCodeListing } from "../../../services/Collection";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../../Utils/IntlMessage";

export default function OrderFilter({
  open,
  handleClose,
  sendFiltersValues,
  appliedFilter,
  resetFilters,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setqrCodeData] = useState([]);

  const initialValues = useMemo(() => {
    if (appliedFilter) {
      return {
        discount: appliedFilter?.discount,
        price: appliedFilter?.price,
        symbol: appliedFilter?.symbol,
        qrcode: appliedFilter?.qrcode,
        ordertype: appliedFilter?.ordertype,
        payment: appliedFilter?.payment,
        date: appliedFilter?.date,
        action: appliedFilter?.action,
      };
    }

    return {
      discount: "",
      price: "",
      symbol: "",
      qrcode: "",
      ordertype: "",
      payment: "",
      date: "",
      action: "",
    };
  }, [appliedFilter]);

  // This function is called to submit the form.

  const handleSubmit = async (values) => {
    sendFiltersValues(values);
  };

  const getQRCodeName = async () => {
    setLoading(true);
    let req = {
      pageNumber: 0,
      type: 1,
    };
    let res = await qrCodeListing(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getQRCodeName();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <CreateFilterWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue }) => (
              <Form>
                <header
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    padding: "0px 20px",
                  }}
                >
                  <i className="icon-Filters" />
                  <p style={{ margin: "0px 10px" }}>
                    <IntlMessage id="button.Filters" />
                  </p>
                </header>

                <InputWraper dir={direction}>
                  <div style={{ padding: "0px 20px", margin: "15px 0px" }}>
                    <section>
                      <label>
                        {/* <IntlMessage id="userManagement.firstName" /> */}
                        Discount
                      </label>
                    </section>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <WhiteButton
                        onClick={(e) => {
                          setFieldValue("discount", "1");
                        }}
                        name="discount"
                        color={values.discount}
                      >
                        Yes
                      </WhiteButton>
                      <WhiteButtons
                        onClick={() => {
                          setFieldValue("discount", "2");
                        }}
                        value={values.discount}
                        color={values.discount}
                        name="discount"
                      >
                        {/* <IntlMessage id="button.CHANGEPHOTO" />
                         */}
                        No
                      </WhiteButtons>
                    </div>
                  </div>

                  <InputWrap
                    dir={direction}
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <section>
                      <label>
                        {/* <IntlMessage id="userManagement.Email" /> */}
                        Price
                      </label>
                    </section>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Field
                        style={{ width: "90%" }}
                        dir="ltr"
                        type="number"
                        placeholder="Enter Price"
                        name="price"
                        component={InputField}
                      />
                      <Field
                        name="symbol"
                        component={DependentField}
                        getChanges={() => function Close() {}}
                        options={[
                          { name: "Less than to (<=)", id: 2 },
                          { name: "Greater than to (>=)", id: 3 },
                          { name: "Equal to (=)", id: 1 },
                        ]}
                        getOptionLabel={(option) =>
                          option ? option?.name : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Price Range"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </InputWrap>

                  <InputWrap
                    dir={direction}
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <section>
                      <label>QR Code Name</label>
                    </section>

                    <Field
                      name="qrcode"
                      component={DependentField}
                      getChanges={() => function Close() {}}
                      options={qrCodeData}
                      getOptionLabel={(option) => (option ? option?.name : "")}
                      renderInput={(params) => (
                        <div
                          ref={params.InputProps.ref}
                          style={{ position: "relative" }}
                        >
                          <SelectInput
                            placeholder="Enter Qr Code"
                            Dir={direction}
                            type="text"
                            {...params.inputProps}
                          />
                          <SelectIcon
                            className="icon-DropDownArrow"
                            dir="ltr"
                          />
                        </div>
                      )}
                    />
                  </InputWrap>

                  <InputWrap
                    dir={direction}
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <section>
                      <label>
                        {/* <IntlMessage id="userManagement.Email" /> */}
                        Order Type
                      </label>
                    </section>
                    <Field
                      name="ordertype"
                      component={DependentField}
                      getChanges={() => function Close() {}}
                      options={[{ name: "Dine In" }, { name: "Takeaway" }]}
                      getOptionLabel={(option) => (option ? option?.name : "")}
                      renderInput={(params) => (
                        <div
                          ref={params.InputProps.ref}
                          style={{ position: "relative" }}
                        >
                          <SelectInput
                            placeholder="Enter Order Type"
                            Dir={direction}
                            type="text"
                            {...params.inputProps}
                          />
                          <SelectIcon
                            className="icon-DropDownArrow"
                            dir="ltr"
                          />
                        </div>
                      )}
                    />
                  </InputWrap>

                  <InputWrap
                    dir={direction}
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <section>
                      <label>
                        {/* <IntlMessage id="userManagement.Email" /> */}
                        Payment Mode
                      </label>
                    </section>
                    <Field
                      name="payment"
                      component={DependentField}
                      getChanges={() => function Close() {}}
                      options={[
                        { name: "Full", id: 1 },
                        { name: "Partial", id: 2 },
                        { name: "Specific", id: 3 },
                      ]}
                      getOptionLabel={(option) => (option ? option?.name : "")}
                      renderInput={(params) => (
                        <div
                          ref={params.InputProps.ref}
                          style={{ position: "relative" }}
                        >
                          <SelectInput
                            placeholder="Enter Payment Mode"
                            Dir={direction}
                            type="text"
                            {...params.inputProps}
                          />
                          <SelectIcon
                            className="icon-DropDownArrow"
                            dir="ltr"
                          />
                        </div>
                      )}
                    />
                  </InputWrap>

                  <InputWrap
                    dir={direction}
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <section>
                      <label>
                        {/* <IntlMessage id="userManagement.Email" /> */}
                        Date
                      </label>
                    </section>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Field
                        style={{ width: "90%" }}
                        dir="ltr"
                        type="date"
                        name="date"
                        component={InputField}
                      />
                      <Field
                        name="action"
                        component={DependentField}
                        getChanges={() => function Close() {}}
                        options={[
                          { name: "Less than to (<=)", id: "2" },
                          { name: "Greater than to (>=)", id: "3" },
                          { name: "Equal to (=)", id: "1" },
                        ]}
                        getOptionLabel={(option) =>
                          option ? option?.name : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Range"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </InputWrap>

                  <div
                    style={{ textAlign: "center", margin: "20px 0px 10px 0px" }}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "#f55a2c" }} />
                    ) : (
                      <>
                        <FilterFooter>
                          <ResetButton
                            style={{ color: "#F55A2C" }}
                            onClick={() => resetFilters()}
                          >
                            Reset
                          </ResetButton>

                          <OrangeButton
                            style={{ margin: "5px 0" }}
                            type="submit"
                            onClick={() => {
                              setTimeout(() => {
                                handleClose();
                              }, 1000);
                            }}
                          >
                            Apply
                          </OrangeButton>

                          <BlackButtonMobile onClick={() => handleClose()}>
                            <IntlMessage id="button.close" />
                          </BlackButtonMobile>
                        </FilterFooter>
                      </>
                    )}
                  </div>

                  {/* <div
                    style={{ textAlign: "center", margin: "20px 0px 10px 0px" }}
                  >
                    {false ? (
                      <CircularProgress sx={{ color: "#f55a2c" }} />
                    ) : (
                      <>
                        <FilterFooter>
                          <ResetButton
                            style={{ color: "#F55A2C" }}
                            onClick={() => resetFilters()}
                          >
                            Reset
                          </ResetButton>

            
                  <OrangeButton
                            type="sumbit"
                            style={{ margin: "5px 0" }}
                          >
                            Apply
                          </OrangeButton> 
                         

                          <BlackButtonMobile onClick={() => handleClose()}>
                            <IntlMessage id="button.close" />
                          </BlackButtonMobile>
                        </FilterFooter>
                      </>
                    )}
                  </div> */}
                </InputWraper>
              </Form>
            )}
          />
        </CreateFilterWrapper>
      </Dialog>
    </div>
  );
}

export const FilterFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 20px;
  gap: 10px;

  button {
    width: 180px;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SelectIconDiscount = styled.i`
  position: absolute;
  top: 20px;
  right: ${({ dir }) => dir === "ltr" && "20px"};
  left: ${({ dir }) => dir === "rtl" && "20px"};
  font-size: 12px !important;
`;

export const CreateFilterWrapper = styled.div`
  width: 413px;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
//   padding: 0px 20px;
::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }


  @media (max-width: 599px) {
    width: 100%;
    height: 100%;
  }

  header {
    width:100%
    height:100%
    background:red;
  }

  p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
width: 33px;
height: 15px;
  }
`;

export const InputWraper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  width: 100%;
  // height: 100px;
  background: #f7f7f7;
  border: 1px solid #f0f1f7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  // padding: 0 10px;
  // margin: 5px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  section {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }
`;

export const ChangeButton = styled.button`
  width: 48%;
  background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => (color === "1" ? "white" : "black")};
  background: ${({ color }) => (color === "2" ? "#F55A2C" : "#ffffff")};
  color: black;
  padding: 10px 0px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  @media (max-width: 430px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 380px) {
    margin: 5px 10px;
    padding: 10px 20px;
  }
`;

export const Normalbutton = styled.button`
  width: 48%;
  background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => (color === "1" ? "white" : "black")};
  background: ${({ color }) => (color === "1" ? "#F55A2C" : "#ffffff")};
  color: black;
  padding: 10px 0px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  @media (max-width: 430px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 380px) {
    margin: 5px 10px;
    padding: 10px 20px;
  }
`;

export const InputWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    width: 100%;
  }

  i {
    font-size: 18px;
  }

  label {
    margin: 0 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #242424;
  }

  input {
    width: 100%;
    height: 42px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 0 20px;
    margin: 5px 0 0 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  input:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }

  textarea {
    width: 100%;
    height: 58px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 8px 0 0 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  textarea:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }

  select {
    width: 100%;
    height: 42px;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 0 10px;
    margin: 5px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  select:focus {
    outline: none;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 8px #f55a2c;
  }
`;

export const WhiteButton = styled.div`
  width: 48%;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => (color === "1" ? "white" : "black")};
  background: ${({ color }) => (color === "1" ? "#F55A2C" : "#ffffff")};
  padding: 10px 75px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  @media (max-width: 430px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 380px) {
    margin: 5px 10px;
    padding: 10px 20px;
  }
`;

export const WhiteButtons = styled.div`
  width: 48%;
  // background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => (color === "2" ? "white" : "black")};
  background: ${({ color }) => (color === "2" ? "#F55A2C" : "#ffffff")};
  padding: 10px 75px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  @media (max-width: 430px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 380px) {
    margin: 5px 10px;
    padding: 10px 20px;
  }
`;

export const ResetButton = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: black;
  padding: 10px 0px;
  margin: 5px 0px;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  @media (max-width: 430px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 380px) {
    margin: 5px 10px;
    padding: 10px 20px;
  }
`;
