import { CircularProgress } from "@mui/material";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaginationRounded from "../../../components/PaginationRounded";
import { pusher } from "../../../pusher/PusherConfig";
import { getOrderListing } from "../../../services/Collection";
import { LoadingWrapper, NODATA } from "../../../style/Gobalstyle";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { getNotificationMsg } from "../../../Utils/functions";
import IntlMessage from "../../../Utils/IntlMessage";
import {
  CardWrapper,
  OrderAvailableCard,
  OrderOccupiedCard,
} from "../OrderStyle";

export default function DineIn() {
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dineInData, setDineInData] = useState(null);
  const Notification = useSelector((state) => state?.pusherRealtime?.data);
  const Restaurentid = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const [orderPage, setOrderPage] = useState(null);
  const [pageNumber, setPagenumber] = useState(1);

  const gotoOrderDetail = (id) => {
    navigate(`/aglut/orders/OrderDetails/${id}`, {
      state: { id: id, type: "Dinein" },
    });
  };

  const myValue = (pages) => {
    setPagenumber(pages);
    getDineInListing(pages);
  };

  const onPusherData = useCallback(
    (data) => {
      console.log("DineIN", data);
      if (data) {
        const newUpdatedData = getNotificationMsg(data, Notification);
        setDineInData(newUpdatedData);
      } else {
        const message = getErrorMessage("Failed to connection");
        toast.error(message);
      }
      setLoading(false);
    },
    [Notification]
  );

  const getDineInListing = useCallback(async (pages) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("order_type", 1);
    params.append("pageNumber", pages);
    let res = await getOrderListing(params.toString());
    if (res.status === 200) {
      setOrderPage(res.extraData);
    } else {
      const message = getErrorMessage("Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDineInListing();
    const channel = pusher.subscribe(
      `DINE-IN-ORDERS${
        process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
      }`
    );
    console.log("DineIN", channel);
    if (channel)
    channel.bind(
      `Dine-In-Orders${
        process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
      }`,
      onPusherData
      );
      

    return () => {
      if (channel) {
        pusher.unsubscribe(
          `DINE-IN-ORDERS${
            process.env.REACT_APP_PUSHER_UNIQUE_CHANNEL_NAME + Restaurentid
          }`
        );
        channel.unbind(
          `Dine-In-Orders${
            process.env.REACT_APP_PUSHER_UNIQUE_EVENT_NAME + Restaurentid
          }`,
          onPusherData
        );
      }
    };
  }, [Notification]);

  return (
    <div>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <CardWrapper>
          {dineInData && dineInData.length > 0 ? (
            dineInData?.map((iteration1) =>
              iteration1?.status !== "available" ? (
                <OrderOccupiedCard
                  dir={direction}
                  onClick={() =>
                    gotoOrderDetail(iteration1.Orders[0]?.order_id)
                  }
                  key={iteration1?.id}
                >
                  {/* {console.log('iteration1' , iteration1)} */}
                  {iteration1.Orders &&
                    iteration1?.Orders.map(
                      (el) =>
                        el?.message.length > 0 &&
                        el?.message?.map((ele) => (
                          <div className="Notification">
                            {el?.message.length > 0 && (
                              <span>{el?.message.length}</span>
                            )}

                            <p>{ele.message}</p>
                          </div>
                        ))
                    )}

                  <section>
                    <i className="icon-Dining" />
                  </section>
                  <div>
                    <label>
                      <IntlMessage id="Orders.Status" /> :{" "}
                    </label>
                    <span>{iteration1?.status}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeName" /> :{" "}
                    </label>
                    <span>{iteration1?.name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeGroup" />:{" "}
                    </label>
                    <span>{iteration1?.qr_qroup_name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.orderStatus" /> :{" "}
                    </label>
                    <span>
                      {iteration1?.Orders[0]?.order_status === "1" ? (
                        <IntlMessage id="Orders.Pending" />
                      ) : iteration1?.Orders[0]?.order_status === "2" ? (
                        <IntlMessage id="Orders.Accepted" />
                      ) : iteration1?.Orders[0]?.order_status === "3" ? (
                        <IntlMessage id="Orders.Preparation" />
                      ) : iteration1?.Orders[0]?.order_status === "4" ? (
                        <IntlMessage id="Orders.Ready" />
                      ) : iteration1?.Orders[0]?.order_status === "5" ? (
                        <IntlMessage id="Orders.Cancel" />
                      ) : (
                        iteration1?.Orders[0]?.order_status === "6" && (
                          <IntlMessage id="Orders.completed" />
                        )
                      )}
                    </span>
                  </div>
                </OrderOccupiedCard>
              ) : (
                <OrderAvailableCard
                  dir={direction}
                  //  onClick={()=>gotoOrderDetail(iteration2.id)}
                  key={iteration1?.id}
                >
                  <section>
                    <i className="icon-Dining" />
                  </section>
                  <div>
                    <label>
                      <IntlMessage id="Orders.Status" /> :{" "}
                    </label>
                    <span>{iteration1?.status}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeName" /> :{" "}
                    </label>
                    <span>{iteration1?.name}</span>
                  </div>
                  <div>
                    <label>
                      <IntlMessage id="Orders.qrCodeGroup" />:{" "}
                    </label>
                    <span>{iteration1?.qr_qroup_name}</span>
                  </div>
                </OrderAvailableCard>
              )
            )
          ) : (
            <NODATA>
              <IntlMessage id="noData" />
            </NODATA>
          )}
        </CardWrapper>
      )}

      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <PaginationRounded
          PaginationRounded={orderPage}
          onChangePage={(_page) => myValue(_page)}
        />
      </div>
    </div>
  );
}
