import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Delete from "../../components/Delete";
import {
  CustomerDiscountApi,
  DeleteMobileDiscountApi,
} from "../../services/Collection";
import {
  FilterButton,
  LoadingWrapper,
  OrangeButton,
  SubHeader,
} from "../../style/Gobalstyle";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import {
  FilterSection,
  SearchButton,
  SearchFEwrapper,
} from "../Feedback/Feedback";
import CustomerDiscountModal from "./CustomerDiscountModal";
import { ExportFeature } from "../../Utils/ExportFiles";
import IntlMessage from "../../Utils/IntlMessage";

export const CustomerDiscount = () => {
  const [customerListing, setCustomerListing] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const data = search?.length > 0 ? search : customerListing;

  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );

  const openDeleteModal = (val) => {
    setSelected(val);
    setOpenDelete(true);
  };

  const openEditModal = (val) => {
    setSelected(val);
    setModal(true);
    setOpenEdit(true);
  }; 

  const handleCreateEdit = () => {
    setModal(false);
    setOpenEdit(false);
  };

  const handleDeleteAction = async (id) => {
    setDeleteLoading(true);
    const res = await DeleteMobileDiscountApi(id);

    if (res?.status == 200) {
      handleCustomerListing();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    setOpenDelete(false);
    setDeleteLoading(false);
  };

  const handleCustomerListing = async () => {
    setLoading(true);
    const res = await CustomerDiscountApi();

    if (res?.status == 200) {
      setCustomerListing(res?.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleSearch = (word) => {
    if (word) {
      let searchData = customerListing?.filter(
        (list) =>
          list?.name?.toLowerCase()?.includes(word?.toLowerCase()) ||
          list?.mobile_no?.includes(word)
      );
      setSearch(searchData);
      return;
    }
    setSearch([]);
  };

  const handleExportFeature = () => {
    ExportFeature(
      `${aqlutstorage}${containerExport}GetCustomerDiscount${RestaurentID}.xlsx`
    );
  };

  useEffect(() => {
    handleCustomerListing();
  }, []);

  return (
    <CustomerDiscountStyle>
      {modal && (
        <CustomerDiscountModal
          open={modal}
          handleClose={handleCreateEdit}
          handleNewData={handleCustomerListing}
          payload={openEdit && selected}
        />
      )}

      {openDelete && (
        <Delete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          payload={selected}
          handleConfirm={handleDeleteAction}
          loading={deleteLoading}
        />
      )}
      <SubHeader>
        <p>CUSTOMER DISCOUNT</p>
        <div>
          <OrangeButton onClick={() => setModal(true)}>
            {/* <IntlMessage id="button.createDis" /> */}
            CREATE DISCOUNT
          </OrangeButton>
        </div>
      </SubHeader>
      <FilterSection style={{ justifyContent: "end", marginTop: "10px" }}>
        <SearchFEwrapper>
          <SearchButton>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </SearchButton>
        </SearchFEwrapper>
        <FilterButton onClick={handleExportFeature}>
          <i className="icon-Export" />
          <p>
            <IntlMessage id="button.Export" />
          </p>
        </FilterButton>
      </FilterSection>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <div className="cardsection">
          {data?.map((ele) => {
            return (
              <CardStyle>
                <div>
                  <span>User Name :</span>
                  <span className="detail">{ele?.name}</span>
                </div>
                <div>
                  <span>Mobile Number :</span>
                  <span className="detail">{ele?.mobile_no}</span>
                </div>
                <div>
                  <span>Discount Percentage :</span>
                  <span className="detail">{ele?.discount_percentage}</span>
                </div>
                <div>
                  <span>Expiry Date :</span>
                  <span className="detail">
                    {dayjs(Number(ele?.expiry_date)).format("YYYY-MM-DD")}
                  </span>
                </div>
                <div>
                  <span>Country Code :</span>
                  <span className="detail">{ele?.country_code}</span>
                </div>
                <div>
                  <span>Description :</span>
                  <span className="detail">{ele?.description}</span>
                </div>
                <div className="actions">
                  <span onClick={() => openEditModal(ele)}>Edit</span>
                  <div className="dot"></div>
                  <span onClick={() => openDeleteModal(ele)}>Delete</span>
                </div>
              </CardStyle>
            );
          })}
        </div>
      )}
    </CustomerDiscountStyle>
  );
};

const CustomerDiscountStyle = styled.div`
  .cardsection {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
`;
const CardStyle = styled.div`
  position: relative;
  width: 350px;
  box-shadow: 0 0 10px #dddbdb;
  border: 1px solid #c5c2c2;
  border-radius: 8px;
  padding: 5px 15px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #595959;
  background: white;
  overflow: hidden;

  div {
    margin: 5px 0;
  }

  .detail {
    font-size: 15px;
    opacity: 0.6;
    padding-left: 10px;
  }

  .actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #f55a2c;
    color: white;
    padding: 5px;
    border-bottom-left-radius: 8px;
    margin: 0;

    span {
      cursor: pointer;
    }
  }

  .dot {
    margin: 0 5px;
    height: 5px;
    background: white;
    border: 1px solid white;
    width: 5px;
    border-radius: 5px;
  }
`;
