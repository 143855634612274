import React, { useEffect, useState } from "react";
import {
  BoxContainer,
  FilterButton,
  FilterSection,
  LoadingWrapper,
  NODATA,
} from "../../../style/Gobalstyle";
import OrderTable from "../OrderTable";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import {
  getOrderFilter,
  getOrderListing,
  searchCompletedOrder,
} from "../../../services/Collection";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import PaginationRounded from "../../../components/PaginationRounded";
import IntlMessage from "../../../Utils/IntlMessage";
import OrderFilter from "./OrderFilter";
import * as moment from "moment";
import { useSelector } from "react-redux";

export default function Completed() {
  const UserHeader = [
    {
      id: <IntlMessage id="Order.completed.orderId" />,
      key: "id",
    },

    {
      id: <IntlMessage id="Order.completed.date" />,
      key: "order_date",
    },
    {
      id: <IntlMessage id="Order.completed.orderType" />,
      key: "order_type",
    },
    {
      id: <IntlMessage id="Order.completed.QR Code Name" />,
      key: "qr_code_name",
    },

    {
      id: <IntlMessage id="Order.completed.modeOfPayment" />,
      key: "mode_of_payment",
      key2: "transaction_number"
    },
    {
      id: <IntlMessage id="Order.completed.price" />,
      key: "total",
    },

    {
      id: <IntlMessage id="Order.completed.discount" />,
      key: "discount",
    },

    {
      id: <IntlMessage id="Order.orderDeatils.action" />,
      key: "Action",
    },
  ];

  const TableAction = {
    apply: true,
    showDetail: true,
    // showDetailPath:"/aglut/orders/completed/details/"
  };

  const [loading, setLoading] = useState(false);
  const [completedData, setCompletedData] = useState(null);
  const [sectionItemPage, setsectionItemPage] = useState(null);
  const [pageNumber, setPagenumber] = useState(1);
  const [openFilterSection, setOpenFilterSection] = useState(false);
  let [filterlength, setFilterLength] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(null);
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );

  const getCompletedListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("order_type", 3);
    params.append("pageNumber", pageNumber);
    let res = await getOrderListing(params.toString());
    if (res.status === 200) {
      setCompletedData(res.data);
      setsectionItemPage(res.extraData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const OpenFilterUser = () => {
    setOpenFilterSection(true);
  };

  const getWord = async (word) => {
    setLoading(true);
    const req = {
      search: word,
      pageNumber: 1,
    };

    let res = await searchCompletedOrder(req);
    if (res.status === 200) {
      setCompletedData(res.data);
      setLoading(false);
    }
  };

  const myValue = (pages) => {
    setPagenumber(pages);
  };

  const sendFiltersValues = async (values) => {
    let updateDate = moment(values.date).format("YYYY-MM-DD");
    setAppliedFilter(values);
    let length = [];

    for (const val in values) {
      if (values[val]) {
        length.push(`${val}:${values[val]}`);
      }
    }
    setFilterLength(length.length);

    const params = new URLSearchParams();
    if (values.discount) params.append("discount", values.discount);
    params.append("order_type", 3);
    if (values.payment) params.append("payment_type", values.payment.id);
    if (values.ordertype) params.append("qr_code_type", values.ordertype.name);
    if (values.date && updateDate) params.append("order_date", updateDate);
    if (values.action) params.append("action", values.action.id);
    if (values.price) params.append("price", values.price);
    if (values.symbol) params.append("price_action", values.symbol.id);
    if (values.qrcode) params.append("qr_code_id", values.qrcode.id);
    if (pageNumber) params.append("pageNumber", pageNumber);

    let res = await getOrderFilter(params.toString());
    if (res.status === 200) {
      setCompletedData(res.data);
      setsectionItemPage(res.extraData);
      toast.info(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const resetFilters = () => {
    setAppliedFilter(null);
    setFilterLength([]);
    setOpenFilterSection(false);
    getCompletedListing();
  };

  useEffect(() => {
    if (appliedFilter !== null) {
      sendFiltersValues(appliedFilter);
    } else {
      getCompletedListing();
    }
  }, [pageNumber]);

  return (
    <div>
      {openFilterSection && (
        <OrderFilter
          open={openFilterSection}
          handleClose={() => setOpenFilterSection(false)}
          sendFiltersValues={sendFiltersValues}
          appliedFilter={appliedFilter}
          resetFilters={resetFilters}
        />
      )}
      <BoxContainer>
        <FilterSection>
          <ShowButton>
            <p>
              <IntlMessage id="button.show" />
            </p>
            <input type="number" value={10} />
          </ShowButton>
          <SearchFEwrapper>
            <SearchButton>
              <i className="icon-Search" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => getWord(e.target.value)}
              />
            </SearchButton>
            <FilterWrapper>
              <FilterButton onClick={OpenFilterUser}>
                {filterlength > 0 && <Count>{filterlength}</Count>}
                <i className="icon-Filter" />
                <p>
                  <IntlMessage id="button.Filters" />
                </p>
              </FilterButton>
            </FilterWrapper>
          </SearchFEwrapper>
        </FilterSection>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {completedData?.length === 0 ? (
              <NODATA style={{ position: "relative" }}>
                <IntlMessage id="noData" />
              </NODATA>
            ) : (
              <OrderTable
                header={UserHeader}
                tableData={completedData}
                action={TableAction}
                subheader={[]}
                subcontents={[]}
              />
            )}
          </>
        )}

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <PaginationRounded
            PaginationRounded={sectionItemPage}
            onChangePage={(_page) => myValue(_page)}
          />
        </div>
      </BoxContainer>
    </div>
  );
}

const ShowButton = styled.div`
  display: flex;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
    width: 40px;
    margin-top: 25px;
  }

  input {
    width: 51px;
    height: 23px;
    background: #ffffff;
    margin: 20px 6px 0 6px;
    padding: 0 5px;
    border: 1px solid #e8e8e8;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const SearchButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 13px 10px;
  margin: 0 6px 0 6px;
  cursor: pointer;

  i {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  input {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 5px 0 6px;
    opacity: 0.9;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 745px) {
    margin: 10px 0px;
  }
`;

const SearchFEwrapper = styled.div`
  display: flex;
  @media (max-width: 745px) {
    display: unset;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Count = styled.div`
  position: absolute;
  background: #f55a2c;
  color: white;
  border-radius: 50%;
  bottom: 30px;
  left: 90px;
  padding: 4px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 10px;
`;
