import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderTabCount } from "../../services/Collection";
import { OrangeButton, SubHeader } from "../../style/Gobalstyle";
import IntlMessage from "../../Utils/IntlMessage";
import AddOrder from "./AddOrder";
import Completed from "./COMPLETED/Completed";
import DineIn from "./DINEIN/DineIn";
import { orderSection } from "./OrderStore";
import { TabButton } from "./OrderStyle";
import Takeaway from "./TAKEAWAY/Takeaway";
import Unified from "./UNIFIED/Unified";
import PrePayment from "./PREPAYMENT/PrePayment";

export default function Orders() {
  const [openAddOrder, setOpenAddCreate] = useState();
  const dispatch = useDispatch();
  const selectTab = useSelector(
    (state) => state?.RestaurantOrder?.activeSelectedSection
  );
  const [dineCount, setDineCount] = useState(0);
  const [unifiedCount, setUnifiedCount] = useState(0);
  const [takeawayCount, setTakeawayCount] = useState(0);
  const [prePaymentCount, setPrePaymentCount] = useState(0);
  const [deliveryCount, setDeliveryCount] = useState(0);

  const changeActiveTab = (tabValue) => {
    dispatch(orderSection({ tabValue }));
  };

  const getTabCount = async () => {
    let res = await OrderTabCount();
    if (res.status === 200) {
      setDineCount(res?.data?.dineinCount);
      setUnifiedCount(res?.data?.unifiedCount);
      setTakeawayCount(res?.data?.allTakeawayOrders);
      setPrePaymentCount(res?.data?.allPrePaidOrders);
      setDeliveryCount(res?.data?.allDeliveryOrders);
    }
  };

  useEffect(() => {
    getTabCount();
  }, [selectTab]);

  return (
    <div>
      {openAddOrder && (
        <AddOrder
          open={openAddOrder}
          handleClose={() => setOpenAddCreate(false)}
        />
      )}

      <SubHeader>
        <p>
          <IntlMessage id="Orders.heading" />
        </p>
        {selectTab !== 3 && (
          <OrangeButton onClick={() => setOpenAddCreate(true)}>
            + <IntlMessage id="button.ADDORDER" />
          </OrangeButton>
        )}
      </SubHeader>
      <TabButton>
        <div
          onClick={() => changeActiveTab(1)}
          className={selectTab === 1 && "active"}
        >
          <IntlMessage id="Orders.dineIn" />
          <span>{dineCount}</span>
        </div>
        {/* <div
          onClick={() => changeActiveTab(2)}
          className={selectTab === 2 && "active"}
        >
          <IntlMessage id="Orders.takeaway" />
        </div> */}

        <div
          onClick={() => changeActiveTab(4)}
          className={selectTab === 4 && "active"}
        >
          <IntlMessage id="Orders.Takeaway/Delivery" />

          <span>{takeawayCount}</span>
        </div>

        <div
          onClick={() => changeActiveTab(6)}
          className={selectTab === 6 && "active"}
        >
          {/* <IntlMessage id="Orders.Takeaway/Delivery" /> */}
          Delivery
          <span>{deliveryCount}</span>
        </div>

        {/* 
        <div
          onClick={() => changeActiveTab(5)}
          className={selectTab === 5 && "active"}
        >
          <IntlMessage id="Orders.Takeaway/Delivery" />
          Pre-Payment
          <span>{prePaymentCount}</span>
        </div> */}

        <div
          onClick={() => changeActiveTab(3)}
          className={selectTab === 3 && "active"}
        >
          <IntlMessage id="Orders.COMPLETED" />
        </div>
      </TabButton>

      {selectTab === 1 && <DineIn />}
      {selectTab === 4 && <Takeaway />}
      {selectTab === 3 && <Completed />}
      {selectTab === 6 && <Unified />}
      {selectTab === 5 && <PrePayment />}
    </div>
  );
}
