import { createSlice } from "@reduxjs/toolkit";

export const QrCodeSlice = createSlice({
  name: "QrCodeSlice",
  initialState: {
    activeTab: 1,
    searchAuthWord: "",
  },
  reducers: {
    selectTabUpdate: (state, data) => {
      if (data.payload) {
        state.activeTab = data.payload;
      } else {
        state.activeTab = 1;
      }
    },
    searchForAuthCode: (state, data) => {
      if (data) {
        state.searchAuthWord = data.payload;
      }
    },
  },
});

export const { selectTabUpdate, searchForAuthCode } = QrCodeSlice.actions;
export default QrCodeSlice.reducer;
