import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { feedbackListing, searchFeedback } from "../../services/Collection";
import {
  BoxContainer,
  FilterButton,
  LoadingWrapper,
  NODATA,
  SubHeader,
} from "../../style/Gobalstyle";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import { CardWrapper, FeedbackCard } from "./FeedbackStyle";
import styled from "styled-components";
import { ExportFeature } from "../../Utils/ExportFiles";
import IntlMessage from "../../Utils/IntlMessage";
import FeedbackFilter from "./FeedbackFilter";
import { TabButton } from "../Orders/OrderStyle";
import FeedbackModal from "./FeedbackModal";

export default function Feedback() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerExport = process.env.REACT_APP_CONTAINER_EXPORTFILE;
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notication, setNotifaction] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [openFilterSection, setOpenFilterSection] = useState(false);
  const RestaurentID = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.id
  );
  const [selectTab, setSelectedTab] = useState(2);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const getfeedbackListing = async () => {
    setLoading(true);
    setNotifaction("");
    let res = await feedbackListing();
    if (res.status === 200) {
      let modifyData = res?.data?.map((ele) => {
        if (ele?.type == 2 || ele?.type == 3) {
          return { ...ele, final_rating: ele?.gratification_rating ?? 0 };
        } else if (ele?.type == 1) {
          let totalOfRating =
            Number(ele?.gratification_rating) +
            Number(ele?.sanitation_rating) +
            Number(ele?.staff_feedback) +
            Number(ele?.taste_rating);
          let newRating = ((totalOfRating / 20) * 100) / 20;
          return { ...ele, final_rating: Math.round(newRating) };
        } else {
          return ele;
        }
      });
      setFeedbackData(modifyData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getWord = async (word) => {
    setLoading(true);

    let res = await searchFeedback(word);
    if (res.status === 200) {
      let modifyData = res?.data?.map((ele) => {
        if (ele?.type == 2 || ele?.type == 3) {
          return { ...ele, final_rating: ele?.gratification_rating ?? 0 };
        } else if (ele?.type == 1) {
          let totalOfRating =
            Number(ele?.gratification_rating) +
            Number(ele?.sanitation_rating) +
            Number(ele?.staff_feedback) +
            Number(ele?.taste_rating);
          let newRating = ((totalOfRating / 20) * 100) / 20;
          return { ...ele, final_rating: Math.round(newRating) };
        } else {
          return ele;
        }
      });
      setFeedbackData(modifyData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const sendFiltersValues = async (values) => {
    setLoading(true);
    setNotifaction(values);
    let res = await feedbackListing(values);
    if (res.status === 200) {
      let modifyData = res?.data?.map((ele) => {
        if (ele?.type == 2 || ele?.type == 3) {
          return { ...ele, final_rating: ele?.gratification_rating ?? 0 };
        } else if (ele?.type == 1) {
          let totalOfRating =
            Number(ele?.gratification_rating) +
            Number(ele?.sanitation_rating) +
            Number(ele?.staff_feedback) +
            Number(ele?.taste_rating);
          let newRating = ((totalOfRating / 20) * 100) / 20;
          return { ...ele, final_rating: Math.round(newRating) };
        } else {
          return ele;
        }
      });
      setFeedbackData(modifyData);
      setLoading(false);
      setOpenFilterSection(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleModalActions = (iteration) => {
    setModalData(iteration);
    setOpenModal(true);
  };

  const OpenFilterUser = () => {
    setOpenFilterSection(true);
  };

  useEffect(() => {
    getfeedbackListing();
  }, []);

  return (
    <div>
      {openModal && (
        <FeedbackModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          data={modalData}
        />
      )}

      {openFilterSection && (
        <FeedbackFilter
          open={openFilterSection}
          handleClose={() => setOpenFilterSection(false)}
          getAllsection={getfeedbackListing}
          sendFiltersValues={sendFiltersValues}
          notication={notication}
        />
      )}
      <SubHeader>
        <p>Feedback Management</p>
      </SubHeader>

      <BoxContainer>
        <FilterSection>
          <SearchFEwrapper>
            <SearchButton>
              <i className="icon-Search" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => getWord(e.target.value)}
              />
            </SearchButton>
            <FilterWrapper>
              <FilterButton onClick={OpenFilterUser}>
                {notication && <RedDot></RedDot>}

                <i className="icon-Filter" />
                <p>
                  <IntlMessage id="button.Filters" />
                </p>
              </FilterButton>

              <FilterButton
                onClick={() =>
                  ExportFeature(
                    `${aqlutstorage}${containerExport}Feedbacklist${RestaurentID}.xlsx`
                  )
                }
              >
                <i className="icon-Export" />
                <p>
                  <IntlMessage id="button.Export" />
                </p>
              </FilterButton>
            </FilterWrapper>
          </SearchFEwrapper>
        </FilterSection>

        <TabButton margin={"20px"}>
          <div
            onClick={() => setSelectedTab(2)}
            className={selectTab === 2 && "active"}
          >
            {/* <IntlMessage id="Orders.dineIn" /> */}
            Users
          </div>
          <div
            onClick={() => setSelectedTab(1)}
            className={selectTab === 1 && "active"}
          >
            {/* <IntlMessage id="Orders.dineIn" /> */}
            Anonymous Users
          </div>
        </TabButton>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {feedbackData && feedbackData?.length === 0 ? (
              <NODATA>NO DATA FOUND</NODATA>
            ) : (
              <CardWrapper>
                {feedbackData?.length > 0 &&
                  feedbackData?.map((iteration) => {
                    return (
                      ((selectTab == 2 && iteration?.type != 1) ||
                        (selectTab == 1 && iteration?.type == 1)) && (
                        <FeedbackCard
                          dir={direction}
                          onClick={() => {
                            selectTab == 1
                              ? handleModalActions(iteration)
                              : navigate(
                                  `/aglut/feedback/summary/${iteration.order_id}`
                                );
                          }}
                        >
                          <span>
                            {iteration?.type == 1
                              ? "View Detail"
                              : "Order Detail"}
                          </span>
                          <header>
                            {iteration?.type == 1
                              ? iteration?.name
                              : iteration?.Customer?.name}
                          </header>
                          <label>
                            {" "}
                            {moment(iteration.createdAt).format("LT")}
                          </label>{" "}
                          <label>
                            {" "}
                            {moment(iteration.createdAt).format("DD MMM YYYY")}
                          </label>
                          {(iteration?.type == 2 ||
                            iteration?.iteration == 3) && (
                            <p style={{ margin: "2px 0", fontWeight: 500 }}>
                              User overall satisfaction with our restaurant.
                            </p>
                          )}
                          <div>
                            {iteration.type == 4 && (
                              <h6>
                                {iteration.rate_of_exeperience === "smile1" ? (
                                  <i className="icon-Star" />
                                ) : iteration.rate_of_exeperience ===
                                  "smile2" ? (
                                  <>
                                    {" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />
                                  </>
                                ) : iteration.rate_of_exeperience ===
                                  "smile3" ? (
                                  <>
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />
                                  </>
                                ) : iteration.rate_of_exeperience ===
                                  "smile4" ? (
                                  <>
                                    {" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                  </>
                                ) : iteration.rate_of_exeperience ===
                                  "smile5" ? (
                                  <>
                                    {" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />{" "}
                                    <i className="icon-Star" />
                                  </>
                                ) : (
                                  ""
                                )}
                              </h6>
                            )}
                            <h6>
                              {iteration?.type != 4 &&
                                Array(Number(iteration?.final_rating) || 0)
                                  ?.fill("")
                                  ?.map(() => <i className="icon-Star" />)}
                            </h6>
                            <p style={{ margin: "5px 0px" }}>
                              {iteration.thoughts}
                            </p>
                          </div>
                        </FeedbackCard>
                      )
                    );
                  })}
              </CardWrapper>
            )}
          </>
        )}
      </BoxContainer>
    </div>
  );
}

export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 745px) {
    // margin:10px 0px;
    display: unset;
  }
`;

export const SearchButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 13px 10px;
  margin: 0 6px 0 6px;
  cursor: pointer;

  i {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  input {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 5px 0 6px;
    opacity: 0.9;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 745px) {
    margin: 10px 0px;
    // display:"unset";
  }
`;

export const SearchFEwrapper = styled.div`
  display: flex;
  @media (max-width: 745px) {
    display: unset;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 745px) {
    display: "unset";
  }
`;

const ShowButton = styled.div`
  display: flex;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
    width: 40px;
    margin-top: 25px;
  }

  input {
    width: 51px;
    height: 23px;
    background: #ffffff;
    margin: 20px 6px 0 6px;
    padding: 0 5px;
    border: 1px solid #e8e8e8;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
    @media (max-width: 745px) {
      display: "unset";
    }
  }
`;

const RedDot = styled.div`
  width: 12px;
  height: 12px;
  background: red;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
`;
