import { createSlice } from "@reduxjs/toolkit";

export const dashboardModule = createSlice({
    name: "dashboard",
    initialState: {
        activeTab: 1,
    },
    reducers: {
        dashboardActiveTab: (state, action) => {
            if (action.payload) {
                state.activeTab = action.payload;
            }
        },
    },
});

export const { dashboardActiveTab } = dashboardModule.actions;
export default dashboardModule.reducer;