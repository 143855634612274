import React, { useCallback, useEffect, useState } from "react";
import { LoadingWrapper, NODATA, SubHeader } from "../../../style/Gobalstyle";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../../Utils/IntlMessage";
import { CardWrapper } from "../MenuStyle";
import DragDropCard from "./DragDropCard";
import update from "immutability-helper";
import DragDropTable from "../SectionItem/DragDropTable";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { updateSubSectionPosition, viewSubSectionMenu } from "../../../services/Collection";

export default function ViewSubSection() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [allSubSection, setAllSubSection] = useState([]);

  const updateDropPosition = async (dragIndex, DropIndex, data, item) => {
    setLoading(true);
    let req = {
      section_id: item.id,
      position: data.position,
    };
    let res = await updateSubSectionPosition(req);
    if (res.status === 200) {
        getDetails(location?.state?.id);
    } else {
        getDetails(location?.state?.id);
        const message = getErrorMessage(res, "Failed to connection");
        toast.error(message);
    }
  };

  const moveCard = useCallback((dragIndex, DropIndex, el, items) => {
    updateDropPosition(dragIndex, DropIndex, el, items);

    setAllSubSection((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [DropIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const UserHeader = [
    {
      id: "Name",
      key: "section_name",
    },
    {
      id: "Arb Name",
      key: "ar_section_name",
    },
    {
      id: "Menu",
      key: "menu_name",
    },
    {
      id: "Section",
      key: "main_section_name",
    },
    {
      id: "Action",
      key: "Action",
    },
    {
      id: "",
      key: "status",
    },
  ];


  const getDetails = async () => {
    setLoading(true);
    let res = await viewSubSectionMenu(location?.state?.id);
    if (res.status === 200) {
      let result = manupilateData(res.data);
      setAllSubSection(result);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const manupilateData = (payload) => {
    let tempArray = [];
    let tempObject = {};
    for (let i = 0; i < payload?.Sections.length; i++) {
      tempObject = {
        ...payload?.Sections[i],
        menu_name: payload?.Menu?.name,
        menu_name_ar: payload?.Menu?.ar_name,
        main_section_name: payload?.section_name,
        main_section_name_arb: payload?.ar_section_name,
      };
      tempArray.push(tempObject);
    }
    return tempArray;
  };

  const TableAction = {
    apply: true,
    view: false,
    edit: true,
    delete: true,
    toggle: false,
    // actionOfView: "CreateSectionItem",
    actionOfEdit: "EditSubSection",
    actionOfDelete: "DeleteSubSection",
    // actionOfToggle: "actionOfToggleSectionItem",
    alldata: getDetails,
    // isPosEnable: isPosEnable
  };


  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <SubHeader>
        <p>
          {/* <IntlMessage id="Menu.dessertSection.heading" /> */}
          Sub Section
        </p>
      </SubHeader>

      {loading ? (
        <LoadingWrapper>
          <CircularProgress sx={{ color: "#f55a2c" }} />
        </LoadingWrapper>
      ) : (
        <>
          {allSubSection.length === 0 ? (
            <NODATA>
              <IntlMessage id="noData" />
            </NODATA>
          ) : (
            // <CardWrapper>
            //     {allSubSection.map((el, index) => renderMenuCard(el, index))}
            // </CardWrapper>

            <DragDropTable
              header={UserHeader}
              tableData={allSubSection}
              action={TableAction}
              moveCard={moveCard}
              // perPageListing={perPageListing}
            />
          )}
        </>
      )}
    </div>
  );
}
