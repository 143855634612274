import { CircularProgress } from "@mui/material";
import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState } from "react";
import { AglutLogo } from "../../../Utils/Images";
import {
  AuthBox,
  AuthWrapper,
  BoxContent,
  LogoWrapper,
} from "../Forget/Forgetstyle";
import * as Yup from "yup";
import { color } from "@mui/system";
import { resetPassword } from "../../../services/Collection";
import { toast } from "react-toastify";
import { IconsWrap } from "../Login/Login";
import { Restaurant } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const NewPassword = () => {
  const [newpasswordType, setNewPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  const navigate = useNavigate();

  const getResID = window?.location?.search?.substring(1)?.split("=");

  const restaurant_id = getResID[1];
  console.log(getResID[1], "getResID");
  const initialValues = {
    NewPassword: "",
    ConfirmPassword: "",
  };
  const validation = Yup.object().shape({
    NewPassword: Yup.string().required(),
    ConfirmPassword: Yup.string()
      .label("confirm password")
      .required()
      .oneOf([Yup.ref("NewPassword"), null], "Passwords must match"),
  });

  const onSubmit = async (values) => {
    let req = {
      restaurent_id: restaurant_id,
      new_password: values?.ConfirmPassword,
    };

    let res = await resetPassword(req);

    if (res.status == 200) {
      navigate("/login");
      toast.success(res.message);
    } else {
      toast.error(res?.message);
    }
  };

  const toggleNewPassword = () => {
    if (newpasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  return (
    <AuthWrapper>
      <section>
        <AuthBox>
          <LogoWrapper>
            <img src={AglutLogo} alt="AGLUT LOGO" />
          </LogoWrapper>
          <BoxContent>
            <header>Forgot Password</header>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validation}
            >
              <Form>
                <div style={{ position: "relative" }}>
                  <label>New Password</label>
                  <Field
                    name="NewPassword"
                    type={newpasswordType}
                    placeholder="New Password"
                    required
                  />
                  <IconsWrap>
                    <i className="icon-View" onClick={toggleNewPassword} />
                  </IconsWrap>
                </div>
                <div style={{ position: "relative" }}>
                  <label>Confirm Password</label>
                  <Field
                    name="ConfirmPassword"
                    type={confirmpasswordType}
                    placeholder="Confirm Password"
                    required
                  />
                  <IconsWrap>
                    <i className="icon-View" onClick={toggleConfirmPassword} />
                  </IconsWrap>

                  <div style={{ color: "red", textAlign: "left" }}>
                    <ErrorMessage name="ConfirmPassword" />
                  </div>
                </div>
                {/* {loading ?
              <CircularProgress />
              : */}
                <button type="submit">Submit</button>
                {/* } */}
              </Form>
            </Formik>
          </BoxContent>
        </AuthBox>
      </section>
    </AuthWrapper>
  );
};
