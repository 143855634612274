import React from "react";
import { CreateQRWrapper } from "../../QRStyle";
import { BoxContent } from "../../../Menu/MenuStyle";
import { CircularProgress } from "@mui/material";
import {
  BlackButtonMobile,
  OrangeButtonForForm,
} from "../../../../style/Gobalstyle";
import IntlMessage from "../../../../Utils/IntlMessage";
import { useState } from "react";
import OtpGenrater from "../../../../components/OtpGenrater";
import { toast } from "react-toastify";
import { GenerateAuthCode } from "../../../../services/Collection";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";

export default function GenerateInbulk({ open, handleClose, getAllAuthCodes }) {
  const [manuallyGenerate, setManuallyGenerate] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const inputValues = (values) => {
    setOtp(values);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    let AuthCode = null;
    if (manuallyGenerate) {
      if (otp.length == 5) {
        AuthCode = otp;
      } else {
        toast.warning("You should enter atleast 5 digits");
        setLoading(false);
        return;
      }
    } else {
      AuthCode = Math.floor(Math.random() * 90000) + 10000;
    }

    let req = {
      auth_code: AuthCode,
    };
    let res = await GenerateAuthCode();
    if (res.status === 200) {
      setLoading(false);
      toast.info(res.message);
      handleClose();
      getAllAuthCodes();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <div>
      <CreateQRWrapper>
        <BoxContent>
          <header>
            {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" /> */}
            Authcode will be generated for all of your QRCode and will be
            attached with automatically.
          </header>

          {/* <p
            className="ManullyGenrate"
            style={{ cursor: "pointer" }}
            onClick={() => setManuallyGenerate(!manuallyGenerate)}
          >
            Manually Generate
          </p> */}

          {/* <h4 className="AutoGenrate">Auto Generated Auth Code</h4> */}

          {/* {manuallyGenerate && (
            <OtpGenrater numInputs={5} inputValues={inputValues} otp={otp} />
          )} */}

          <div style={{ textAlign: "center" }}>
            {loading ? (
              <CircularProgress sx={{ color: "#f55a2c" }} />
            ) : (
              <>
                <BlackButtonMobile onClick={() => handleClose()}>
                  <IntlMessage id="button.close" />
                </BlackButtonMobile>
                <OrangeButtonForForm type="submit" onClick={handleSubmit}>
                  {/* <IntlMessage id="button.SUBMIT" /> */}
                  Generate
                </OrangeButtonForForm>
              </>
            )}
          </div>
        </BoxContent>
      </CreateQRWrapper>
    </div>
  );
}
