import BackCover from "../assets/BackCover.jpg";
import AglutLogo from "../assets/AglutLogo.svg";
import AglutLogoInPink from "../assets/LogoPink.svg";
import AglutBackground from "../assets/Background.png";
import ProfilePicture from "../assets/Profile.png";
import MenusectionImage1 from "../assets/MainMenu.png";
import MenusectionImage2 from "../assets/MainMenu1.png";
import MenusectionImage3 from "../assets/MenuSection.png";
import IntegrationPOS from "../assets/IntegrationPOS.png";
import IntegrationPrinter from "../assets/IntegrationPrinter.png";
import IntegrationWIFI from "../assets/IntegrationWIFI.png";
import QRCodeIcon from "../assets/qrCode.png";
import ColorPick from "../assets/colorPicker.png";
import Bycash from "../assets/Bycash.png";
import bycard from "../assets/bycard.png";
import onlinePay from "../assets/onlinePay.png";
import CashOnDelivery from "../assets/cashOnDelivery.png";
import DashboardMenuItems from "../assets/dashboardMenuItems.svg";
import DashboardPendingOrder from "../assets/dashboardPendingOrder.svg";
import DashboardSales from "../assets/dashboardSales.svg";
import DashboardUniqueCustomer from "../assets/dashboardUniqueCustomer.svg";
import DashboardClosedOrder from "../assets/dashboardClosedOrder.svg";
import DashboardDiscountAmount from "../assets/dashboardDiscountAmount.svg";

export {
  DashboardMenuItems,
  DashboardPendingOrder,
  DashboardSales,
  DashboardUniqueCustomer,
  DashboardClosedOrder,
  DashboardDiscountAmount,
};

export {
  BackCover,
  AglutLogo,
  AglutBackground,
  ProfilePicture,
  MenusectionImage1,
  MenusectionImage2,
  MenusectionImage3,
  QRCodeIcon,
  IntegrationPOS,
  IntegrationPrinter,
  IntegrationWIFI,
  ColorPick,
  Bycash,
  bycard,
  onlinePay,
  CashOnDelivery,
  AglutLogoInPink
};
