import { createSlice } from "@reduxjs/toolkit";

export const integrationSlice = createSlice({
  name: "integrationModule",
  initialState: {
    isPosEnable: false,
  },

  reducers: {
    PosRealtimeStatus: (state, action) => {
      if (action.payload) {
        state.isPosEnable = action.payload?.pos_integration_status;
      }
    },
  },
});

export const { PosRealtimeStatus } = integrationSlice.actions;
export default integrationSlice.reducer;
