import {
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButtonForForm,
  RemoveImage,
} from "../../../style/Gobalstyle";
import { CreateQRWrapper, InputWrap } from "../UserManagementStyle";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import IntlMessage from "../../../Utils/IntlMessage";
import InputField from "../../../validation/InputField";
import { MiddleContent, Upload } from "../../Menu/MenuStyle";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import { createMobileUser } from "../../../services/Collection";

export default function CreateMobileUser({ open, handleClose, listingCalled }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const changePhoto = (file) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  // Yup is used for validation.
  const schema = yup.object().shape({
    name: yup.string().required("Please enter name"),
    email: yup
      .string()
      .email("Not a proper email")
      .required("Please enter email"),
      newPassword: yup
      .string()
      .required("New Password password is reqiured")
      .min(8, "New password must be greater than 8 words"),
  });

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    console.log(values , "valuesssssssssssssssssss");
    setLoading(true);

    // create payload for api
    var fd = new FormData();
    fd.append("name", values.name);
    fd.append("email", values.email);
    fd.append("password", values.newPassword);
    fd.append("designation", values.designation);
    if (image) {
      fd.append("image", image, image?.name);
    }

    let res = await createMobileUser(fd);
    if (res.status === 200) {
      setLoading(false);
      handleClose();
      listingCalled();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      toast.error(message);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <CreateQRWrapper>
          <MiddleContent>
            <header>
              <IntlMessage id="userManagement.addUser.heading" />
            </header>
            <Upload>
              {preview ? (
                <>
                  <img src={preview} alt="Profile" />

                  <RemoveImage>
                    {/* <i className="icon-Cross" /> */}
                    <input
                      type="file"
                      accept=".jpg , .png"
                      onChange={(e) => changePhoto(e?.target?.files[0])}
                    />
                  </RemoveImage>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept=".jpg , .png"
                    onChange={(e) => changePhoto(e?.target?.files[0])}
                  />
                  <i className="icon-Upload" />
                </>
              )}
            </Upload>
          </MiddleContent>

          <Formik
            initialValues={{
              name: "",
              email: "",
              newPassword: "",
              designation: 1,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue }) => (
              <Form>
                <InputWrap dir={direction}>
                  <section>
                    <label>
                      {/* <IntlMessage id="userManagement.firstName" /> */}
                      Name
                    </label>
                  </section>
                  <Field
                    dir="ltr"
                    type="text"
                    placeholder="Enter name"
                    name={`name`}
                    component={InputField}
                  />
                </InputWrap>

                <InputWrap dir={direction}>
                  <section>
                    <label>
                      <IntlMessage id="userManagement.Email" />
                    </label>
                  </section>
                  <Field
                    dir="ltr"
                    type="email"
                    placeholder="Enter email"
                    name={`email`}
                    component={InputField}
                  />
                </InputWrap>


                <InputWrap dir={direction}>
                  <section>
                    <label>
                      <IntlMessage id="profileSection.changePassowrd.newPass" />
                    </label>
                  </section>

                  <Field
                    dir="ltr"
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    component={InputField}
                  />
                </InputWrap>

                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#66433F" }} />
                  ) : (
                    <>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        <IntlMessage id="button.close" />
                      </BlackButtonMobile>
                      <OrangeButtonForForm>
                        <IntlMessage id="button.SUBMIT" />
                      </OrangeButtonForForm>
                    </>
                  )}
                </div>
              </Form>
            )}
          />
        </CreateQRWrapper>
      </Dialog>
    </div>
  );
}
