import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  BoxContent,
  CreateWrapper,
  InputPageWrapper,
  InputWrap,
  MiddleContent,
  SelectInput,
} from "../MenuStyle";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButton,
  OrangeButtonForForm,
  SelectIcon,
} from "../../../style/Gobalstyle";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../../validation/InputField";
import { useSelector } from "react-redux";
import { DependentField } from "../../../validation/DependentField";
import {
  createMenuSection,
  mainSectionListing,
  menuListing,
} from "../../../services/Collection";
import TextArea from "../../../validation/TextArea";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { CircularProgress, Switch, TextField } from "@mui/material";
import IntlMessage from "../../../Utils/IntlMessage";
import styled from "styled-components";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";

export default function CreateSection({ open, handleClose, getAllsection }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const direction = useSelector((state) => state?.languageDirection?.direction);
  const [menusList, setMenusList] = useState([]);
  const [mainSectionList, setMainSectionList] = useState([]);

  const changePhoto = (file) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  let sectionType = [
    {
      name: "Main Section",
      type: "1",
    },
    {
      name: "Sub Section",
      type: "2",
    },
  ];

  const EnRegex = "([a-zA-Z]s*)+";
  const ArRegex = "[\u0600-\u06FF]";
  // Yup is used for validation.
  let schema = yup.object().shape({
    name: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter menu name"),
    nameAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter menu name"),
    // menus: yup.object().required("required"),

    menus: yup.object().when("sectiontype", {
      is: (sectiontype) => sectiontype?.type === "1" && true,
      then: yup.object().required("required"),
    }),

    sections: yup.object().when("sectiontype", {
      is: (sectiontype) => sectiontype?.type === "2" && true,
      then: yup.object().required("required"),
    }),

    // menusAr: yup.object().required("required"),
    // sortOrderID: yup.number().required("required"),
    description: yup
      .string()
      .matches(EnRegex, "Only English letters allow")
      .required("Please enter menu description"),
    descriptionAr: yup
      .string()
      .matches(ArRegex, "Only Arabic letters allow")
      .required("Please enter menu description"),
  });

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    setLoading(true);
    var fd = new FormData();
    if (values?.sectiontype?.type === "1") {
      fd.append("section_type", values?.sectiontype?.type);
      fd.append("menu_id", values.menus.id);
      fd.append("section_name", values.name);
      fd.append("ar_section_name", values.nameAr);
      fd.append("desc", values.description);
      fd.append("ar_desc", values.descriptionAr);
      fd.append("is_maximize", values.isMaximize === "Y" ? true : false);
      fd.append("is_scheduled", values.is_scheduled);
      fd.append("start_time", values.start_time);
      fd.append("end_time", values.end_time);
      if (image) {
        fd.append("image", image, image?.name);
      }
    } else {
      fd.append("section_type", values?.sectiontype?.type);
      // fd.append("menu_id", values.menus.id);
      fd.append("section_name", values.name);
      fd.append("ar_section_name", values.nameAr);
      fd.append("desc", values.description);
      fd.append("ar_desc", values.descriptionAr);
      fd.append("main_section_id", values.sections?.id);
      fd.append("image", null);
    }

    let res = await createMenuSection(fd);
    if (res.status === 200) {
      setLoading(false);
      handleClose();
      getAllsection();
      toast.info(res.message);
    } else {
      handleClose();
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getAllMenus = async () => {
    let res = await menuListing();
    if (res.status === 200) {
      setMenusList(res.data);
    }
  };

  const getAllMainSections = async () => {
    let res = await mainSectionListing();
    if (res.status === 200) {
      setMainSectionList(res.data);
    }
  };

  useEffect(() => {
    getAllMenus();
    getAllMainSections();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>
        <CreateWrapper>
          <Formik
            initialValues={{
              name: "",
              nameAr: "",
              menus: "",
              sectiontype: {
                name: "Main Section",
                type: "1",
              },
              isMaximize: "Y",
              sections: "",
              description: "",
              descriptionAr: "",
              is_scheduled: false,
              start_time: null,
              end_time: null,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue }) => (
              <Form>
                <BoxContent>
                  <MiddleContent>
                    <header>
                      <IntlMessage id="Menu.createSection.Heading" />
                    </header>
                    {values && values?.sectiontype?.type === "1" && (
                      <Upload>
                        {preview ? (
                          <>
                            <img src={preview} alt="Profile" />

                            <RemoveImage style={{ width: "220px" }}>
                              <i className="icon-Cross" />
                              <input
                                type="file"
                                accept=".jpg , .png"
                                onChange={(e) =>
                                  changePhoto(e?.target?.files[0])
                                }
                              />
                            </RemoveImage>
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              accept=".jpg , .png"
                              onChange={(e) => changePhoto(e?.target?.files[0])}
                            />
                            <i className="icon-Upload" />
                          </>
                        )}
                      </Upload>
                    )}
                  </MiddleContent>

                  {values && values?.sectiontype?.type === "1" && (
                    <InputWrap dir="ltr">
                      <section>
                        <label>Default Maximize</label>
                      </section>

                      <Switch
                        name="isMaximize"
                        value="Y"
                        checked={values.isMaximize === "Y"}
                        onChange={(event, checked) => {
                          setFieldValue("isMaximize", checked ? "Y" : "N");
                        }}
                      />
                    </InputWrap>
                  )}

                  <InputWrap dir="ltr">
                    <section>
                      <label>Section Type</label>
                    </section>

                    <Field
                      name="sectiontype"
                      component={DependentField}
                      getChanges={() => function Close() {}}
                      options={sectionType}
                      // defaultValue={{
                      //   name: "Main Section",
                      //   type: 1
                      // }}
                      getOptionLabel={(option) => (option ? option?.name : "")}
                      renderInput={(params) => (
                        <div
                          ref={params.InputProps.ref}
                          style={{ position: "relative" }}
                        >
                          <SelectInput
                            placeholder="Section type"
                            Dir={direction}
                            type="text"
                            {...params.inputProps}
                          />
                          <SelectIcon
                            className="icon-DropDownArrow"
                            dir="ltr"
                          />
                        </div>
                      )}
                    />
                  </InputWrap>

                  <InputPageWrapper>
                    <InputWrap dir="ltr">
                      <section>
                        <label>Name</label>
                      </section>

                      <Field
                        type="text"
                        name="name"
                        placeholder="Name"
                        component={InputField}
                      />
                    </InputWrap>

                    <InputWrap dir="rtl">
                      <section>
                        <label> اسم</label>
                      </section>

                      <Field
                        type="text"
                        name="nameAr"
                        placeholder="اسم"
                        component={InputField}
                      />
                    </InputWrap>

                    {values && values?.sectiontype?.type === "1" && (
                      <>
                        <InputWrap dir="ltr">
                          <section>
                            <label>Menus</label>
                          </section>

                          <Field
                            name="menus"
                            component={DependentField}
                            getChanges={() => function Close() {}}
                            options={menusList}
                            getOptionLabel={(option) =>
                              option ? option?.name : ""
                            }
                            renderInput={(params) => (
                              <div
                                ref={params.InputProps.ref}
                                style={{ position: "relative" }}
                              >
                                <SelectInput
                                  placeholder="Menus"
                                  Dir={direction}
                                  type="text"
                                  {...params.inputProps}
                                />
                                <SelectIcon
                                  className="icon-DropDownArrow"
                                  dir="ltr"
                                />
                              </div>
                            )}
                          />
                        </InputWrap>

                        <InputWrap dir="rtl">
                          <section>
                            <label> القوائم</label>
                          </section>
                          <Field
                            name="menus"
                            component={DependentField}
                            getChanges={() => function Close() {}}
                            options={menusList}
                            getOptionLabel={(option) =>
                              option ? option.ar_name : ""
                            }
                            renderInput={(params) => (
                              <div
                                ref={params.InputProps.ref}
                                style={{ position: "relative" }}
                              >
                                <SelectInput
                                  placeholder="القوائم"
                                  Dir={direction}
                                  type="text"
                                  {...params.inputProps}
                                />

                                <SelectIcon
                                  className="icon-DropDownArrow"
                                  dir="rtl"
                                />
                              </div>
                            )}
                          />
                        </InputWrap>
                      </>
                    )}

                    {values && values?.sectiontype?.type === "2" && (
                      <>
                        <InputWrap dir="ltr">
                          <section>
                            <label>Sections</label>
                          </section>

                          <Field
                            name="sections"
                            component={DependentField}
                            getChanges={() => function Close() {}}
                            options={mainSectionList}
                            getOptionLabel={(option) =>
                              option ? option?.section_name : ""
                            }
                            renderInput={(params) => (
                              <div
                                ref={params.InputProps.ref}
                                style={{ position: "relative" }}
                              >
                                <SelectInput
                                  placeholder="Sections"
                                  Dir={direction}
                                  type="text"
                                  {...params.inputProps}
                                />
                                <SelectIcon
                                  className="icon-DropDownArrow"
                                  dir="ltr"
                                />
                              </div>
                            )}
                          />
                        </InputWrap>

                        <InputWrap dir="rtl">
                          <section>
                            <label>الأقسام</label>
                          </section>
                          <Field
                            name="sections"
                            component={DependentField}
                            getChanges={() => function Close() {}}
                            options={mainSectionList}
                            getOptionLabel={(option) =>
                              option ? option.ar_section_name : ""
                            }
                            renderInput={(params) => (
                              <div
                                ref={params.InputProps.ref}
                                style={{ position: "relative" }}
                              >
                                <SelectInput
                                  placeholder="الأقسام"
                                  Dir={direction}
                                  type="text"
                                  {...params.inputProps}
                                />

                                <SelectIcon
                                  className="icon-DropDownArrow"
                                  dir="rtl"
                                />
                              </div>
                            )}
                          />
                        </InputWrap>
                      </>
                    )}
                  </InputPageWrapper>
                  {values?.sectiontype?.type == 1 && (
                    <InputWrap dir="ltr">
                      <section>
                        <label>Scheduled Time</label>
                      </section>

                      <Switch
                        name="is_scheduled"
                        value={values?.is_scheduled}
                        checked={values.is_scheduled}
                        onChange={(event, checked) => {
                          setFieldValue("is_scheduled", checked);
                        }}
                      />
                    </InputWrap>
                  )}
                  <InputPageWrapper>
                    {values?.sectiontype?.type == 1 && (
                      <InputWrapTime dir="ltr">
                        <section>
                          <label>Start Time</label>
                        </section>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            disabled={!values?.is_scheduled}
                            value={moment(values?.start_time, "h:mm A")}
                            onChange={(newValue) => {
                              setFieldValue(
                                "start_time",
                                moment(newValue).format("h:mm A")
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </InputWrapTime>
                    )}

                    {values?.sectiontype?.type == 1 && (
                      <InputWrapTime dir="ltr">
                        <section>
                          <label>End Time</label>
                        </section>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            disabled={!values?.is_scheduled}
                            value={moment(values?.end_time, "h:mm A")}
                            onChange={(newValue) => {
                              setFieldValue(
                                "end_time",
                                moment(newValue).format("h:mm A")
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </InputWrapTime>
                    )}

                    <InputWrap dir="ltr">
                      <section>
                        <label>Description</label>
                      </section>

                      <Field
                        type="description"
                        name="description"
                        placeholder="Description"
                        component={TextArea}
                      />
                    </InputWrap>

                    <InputWrap dir="rtl">
                      <section>
                        <label> وصف</label>
                      </section>

                      <Field
                        type="description"
                        name="descriptionAr"
                        placeholder="وصف"
                        component={TextArea}
                      />
                    </InputWrap>
                  </InputPageWrapper>
                </BoxContent>
                <MiddleContent style={{ marginTop: "20px" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#f55a2c" }} />
                  ) : (
                    <div style={{ display: "flex" }}>
                      <BlackButtonMobile onClick={() => handleClose()}>
                        <IntlMessage id="button.close" />
                      </BlackButtonMobile>
                      <OrangeButtonForForm type="submit">
                        <IntlMessage id="button.SUBMIT" />
                      </OrangeButtonForForm>
                    </div>
                  )}
                </MiddleContent>
              </Form>
            )}
          />
        </CreateWrapper>
      </Dialog>
    </div>
  );
}

export const Upload = styled.div`
  width: 220px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  input {
    width: 220px;
    height: 120px;
    opacity: -1;
    position: absolute;
  }

  img {
    width: 220px;
    height: 120px;
    position: absolute;
    border: 1px solid #e6e6f1;
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const RemoveImage = styled.div`
  position: relative;
  width: 220px;
  height: 120px;
  i {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 15px;
    color: red;
    background: #000;
    border-radius: 50%;
    padding: 7px;
    // margin: 5px;
    cursor: pointer;
  }

  input {
    position: absolute;
  }
`;

export const InputWrapTime = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin: 5px 0px;

  i {
    font-size: 18px;
  }

  label {
    margin: 0 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
`;
