import React, { useMemo } from "react";
import {
  CreateFilterWrapper,
  InputWrap,
  SelectIconDiscount,
} from "../../QRStyle";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButtonForForm,
  WhiteButton,
} from "../../../../style/Gobalstyle";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  BoxContent,
  DeleteButton,
  InputWrapOptionModule,
  ModifiersWrapper,
  SelectInput,
} from "../../../Menu/MenuStyle";
import OtpGenrater from "../../../../components/OtpGenrater";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../../../Utils/IntlMessage";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../Utils/ErrorMessage";
import { editAuthCode, qrCodeListing } from "../../../../services/Collection";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { DependentField } from "../../../../validation/DependentField";
import { useEffect } from "react";

export default function EditAuthCode({ open, handleClose, data, fetchData }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const direction = useSelector((state) => state.languageDirection.direction);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [otp, setOtp] = useState(data?.auth_code);
  const [qrCodeData, setqrCodeData] = useState([]);

  const inputValues = (values) => {
    setOtp(values);
  };

  const initialValues = useMemo(() => {
    if (data?.QrcodesAuthcodes?.length > 0) {
      let initial = data?.QrcodesAuthcodes?.map((el) => ({
        qrcode: {
          ...el?.QrCode,
          ...el?.QrCode?.QrCodeGroup,
          QrCodeGroup: null,
          qrid: el?.QrCode?.id,
        },
      }));
      return {
        qrCodes: initial,
      };
    } else {
      return {
        qrCodes: [{ qrcode: data }],
      };
    }
  }, [data]);

  let schema = yup.object().shape({
    modifier: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Please enter name"),
        price: yup.string().required("Please enter price"),
        calories: yup.string().optional("Please enter caloris"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    setLoadingGenerate(true);

    let arr = values?.qrCodes?.map(
      (item) => item?.qrcode?.qrid || item?.qrcode?.id
    );

    let req = {
      qr_code_id: arr,
      auth_code_id: data?.auth_code_id || data?.id,
      auth_code: otp,
    };

    let res = await editAuthCode(req);
    if (res.status === 200) {
      setLoadingGenerate(false);
      toast.info(res.message);
      handleClose();
      fetchData();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoadingGenerate(false);
    }
  };

  const getAllQrCodes = async () => {
    let req = {
      pageNumber: 0,
      type: 1,
    };
    let res = await qrCodeListing(req);
    if (res.status === 200) {
      setqrCodeData(res.data);
    }
  };

  useEffect(() => {
    getAllQrCodes();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <BoxContent style={{ margin: "20px 0", padding: "0 20px" }}>
          <header style={{ textAlign: "center" }}>
            {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.Heading" /> */}
            Edit Authorization Code
          </header>

          <OtpGenrater
            numInputs={5}
            inputValues={inputValues}
            otp={otp}
            shouldAutoFocus={false}
          />

          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue }) => (
              <Form>
                <InputWrapOptionModule dir={direction}>
                  <label style={{ fontSize: "18px", marginBottom: "20px" }}>
                    {/* <IntlMessage id="qrMenu.qRCodes.createQrCode.menuGroup" /> */}
                    QR Code
                  </label>
                </InputWrapOptionModule>

                <FieldArray
                  name="qrCodes"
                  render={(arrayHelpers) => {
                    const qrCodes = values?.qrCodes;
                    return (
                      <div>
                        {qrCodes && qrCodes.length > 0
                          ? qrCodes?.map((qrCodes, index) => (
                              <div key={index}>
                                <InputWrap
                                  dir={direction}
                                  style={{ margin: "0" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <Field
                                      name={`qrCodes.${index}.qrcode`}
                                      component={DependentField}
                                      options={qrCodeData}
                                      getChanges={() => function Close() {}}
                                      getOptionLabel={(option) =>
                                        option
                                          ? option?.group_name +
                                            " - " +
                                            option?.name
                                          : ""
                                      }
                                      renderInput={(params) => (
                                        <div
                                          ref={params.InputProps.ref}
                                          style={{ position: "relative" }}
                                        >
                                          <SelectInput
                                            placeholder="QR Code"
                                            Dir={direction}
                                            type="text"
                                            r
                                            {...params.inputProps}
                                          />

                                          <SelectIconDiscount
                                            className="icon-DropDownArrow"
                                            dir="ltr"
                                          />
                                        </div>
                                      )}
                                    />

                                    <div
                                      style={{ padding: "5px 2px 0px 12px" }}
                                    >
                                      <DeleteButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <i className="icon-Delete" />
                                      </DeleteButton>
                                    </div>
                                  </div>
                                </InputWrap>
                              </div>
                            ))
                          : null}

                        <div className="justifyLeft">
                          <WhiteButton
                            onClick={() => arrayHelpers.push({ qrcode: null })}
                          >
                            + Add more qr codes
                            {/* <IntlMessage id="button.addModifier" /> */}
                          </WhiteButton>
                        </div>

                        <div style={{ textAlign: "center" }}>
                          {loadingGenerate ? (
                            <CircularProgress sx={{ color: "#f55a2c" }} />
                          ) : (
                            <>
                              <BlackButtonMobile onClick={() => handleClose()}>
                                <IntlMessage id="button.close" />
                              </BlackButtonMobile>
                              <OrangeButtonForForm type="submit">
                                <IntlMessage id="button.SUBMIT" />
                              </OrangeButtonForForm>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          />
        </BoxContent>
      </Dialog>
    </div>
  );
}
