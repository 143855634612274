import { Alert, Box, CircularProgress, Skeleton } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  getlinkedQrcodeList,
  getPosDetails,
  getPosItemListing,
  getPosModifierListing,
  getPosTableListing,
  getRestaurentItemListing,
  getResturantModifierListing,
  profileDetails,
  qrCodeListing,
  searchSyncedItem,
  searchSyncedModifier,
  searchSyncedQrTable,
  SyncingResItemWithPosItem,
  SyncingResModifierWithPosModifier,
  SyncingResTableWithPosTable,
  updatePOSinDB,
  updatePOSModifiersinDB,
  updatePOSTableinDB,
} from "../../services/Collection";
import {
  BlackButton,
  BoxContainer,
  LoadingWrapper,
  NODATA,
  OrangeButton,
  SelectIcon,
  SubHeader,
} from "../../style/Gobalstyle";
import { getErrorMessage } from "../../Utils/ErrorMessage";
import EditPos from "./EditPos";
import PosSetupDetails from "./PosSetupDetails";
import * as yup from "yup";
import { DependentField } from "../../validation/DependentField";
import { SelectInput } from "../Menu/MenuStyle";
import { useDispatch, useSelector } from "react-redux";
import IntegrationTable from "./IntegrationTable";
import { TableHeader } from "./IntegrationStyle";
import { profileUpdated } from "../Profile/ProfileStore";
import PaginationRounded from "../../components/PaginationRounded";
import { Tooltip } from "antd";

export default function POS() {
  const [openPosSetup, setOpenPosSetup] = useState(false);
  const [openPosEdit, setOpenPosEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [posInfo, setPosInfo] = useState(null);
  const [matchingPlatform, setMatchingPlatform] = useState(false);
  const [matchingQrCode, setMatchingQrCode] = useState(false);
  const [matchingModifiers, setMatchingModifiers] = useState(false);

  const direction = useSelector((state) => state.languageDirection.direction);
  const [restaurentItemListing, setRestaurentItemListing] = useState([]);
  const [posItemListing, setPosItemListing] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [sectionItemData, setsectionItemData] = useState([]);
  const [posIsEnable, setPosIsEnable] = useState(false);
  const [skeletonLoadingForQr, setSkeletonLoadingForQr] = useState(false);
  const [skeletonLoadingForModifiers, setSkeletonLoadingForModifiers] =
    useState(false);
  const [restaurentTableListing, setRestaurentTableListing] = useState([]);
  const [posTableListing, setPosTableListing] = useState([]);
  const [qrCodeData, setQrCodeData] = useState([]);
  const [posModifiersListing, setPosModifiersListing] = useState([]);
  const [restaurentModifiersListing, setRestaurentModifiersListing] = useState(
    []
  );
  const [sycnedItemsPage, setSycnedItemsPage] = useState();
  const [sycnedTablePage, setSycnedTablePage] = useState();
  const [sycnedModifierPage, setSycnedModifierPage] = useState();
  const [modifiersData, setModifiersData] = useState([]);
  const dispatch = useDispatch();
  const [sectionItempageNumber, setSectionItemPagenumber] = useState(1);
  const [qrTablepageNumber, setQrTablePagenumber] = useState(1);

  const [sectionItemsLoading, setSectionItemsLoading] = useState(false);
  const [sectionTableLoading, setSectionTableLoading] = useState(false);
  const [sectionModifiersLoading, setSectionModifiersLoading] = useState(false);
  const [dataFechingFromDBLoader, setDataFechingFromDBLoader] = useState(false);

  const MatchingDataHeader = [
    {
      // id: <IntlMessage id="Menu.sectionItem.sortOrderId" />,
      id: "Section Name",
      key: "section_name",
    },
    {
      // id: <IntlMessage id="Menu.sectionItem.sortOrderId" />,
      id: "POS item",
      key: "pos_item_name",
    },
    {
      // id: <IntlMessage id="Menu.sectionItem.sortOrderId" />,
      id: "Restaurant's item",
      key: "name",
    },
    {
      // id: <IntlMessage id="Menu.sectionItem.Action" />,
      id: "POS price",
      key: "posPrice",
    },
    {
      // id: <IntlMessage id="Menu.sectionItem.sortOrderId" />,
      id: "Restaurant's price",
      key: "individualPrice",
    },
    {
      // id: <IntlMessage id="Menu.sectionItem.Action" />,
      id: "Action",
      key: "Action",
    },
  ];

  const MatchingQrTableHeader = [
    {
      // id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "Name",
      key: "name",
    },
    {
      // id: <IntlMessage id="qrMenu.qRCodes.qrSource" />,
      id: "Group Type",
      key: "group_type",
    },
    {
      // id: <IntlMessage id="qrMenu.qRCodes.groupName" />,

      id: "Group Name",
      key: "group_name",
    },

    {
      // id: <IntlMessage id="qrMenu.qRCodes.groupName" />,

      id: "POS Table ID",
      key: "pos_table_id",
    },

    {
      // id: <IntlMessage id="qrMenu.qRCodes.groupName" />,

      id: "POS Table Name",
      key: "string_text",
    },

    {
      // id: <IntlMessage id="qrMenu.qRCodes.Action" />,

      id: "Action",
      key: "Action",
    },
  ];

  const MatchingModifierHeader = [
    {
      // id: <IntlMessage id="qrMenu.qRCodes.qrCodeName" />,
      id: "POS modifiers",
      key: "pos_option_item_name",
    },
    {
      // id: <IntlMessage id="qrMenu.qRCodes.qrSource" />,
      id: "Restaurant's modifiers",
      key: "name",
    },
    {
      // id: <IntlMessage id="qrMenu.qRCodes.groupName" />,

      id: "POS price",
      key: "pos_option_item_price",
    },

    {
      // id: <IntlMessage id="qrMenu.qRCodes.groupName" />,

      id: "Restaurant's price",
      key: "price",
    },

    {
      // id: <IntlMessage id="qrMenu.qRCodes.Action" />,

      id: "Action",
      key: "Action",
    },
  ];

  // Yup is used for validation.
  let schema = yup.object().shape({
    restaurentItem: yup.object().required("Please selecte an item"),
    posItem: yup.object().required("Please selecte an item"),
  });

  const permissionsData = useSelector((state) => state?.loginAuth?.permissions);

  // This function is called to submit the form.
  const handleSubmit = async (values) => {
    setSkeletonLoading(true);

    let req = {
      pos_id: values.posItem.pos_item_id,
      pos_item_price: Number(values.posItem.pos_item_price),
      pos_item_name: values.posItem.pos_item_name,
      item_id: values.restaurentItem.id,
      item_price_id: values.restaurentItem.individualPriceID,
      total_pos_items: posItemListing.length,
    };

    let res = await SyncingResItemWithPosItem(req);
    if (res.status === 200) {
      toast.info(res.message);
      AlreadyMatchingItems();
      RestaurentAllItems();
      PosAllItems();
      getProfileDetails();
      setSkeletonLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSkeletonLoading(false);
    }
  };

  const getPOSInfo = async () => {
    setLoading(true);
    let res = await getPosDetails();
    if (res.status === 200) {
      setPosInfo(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const RestaurentAllItems = async () => {
    // setSkeletonLoading(true);
    let req = {
      type: 1,
      pageNumber: 0,
    };
    let res = await getRestaurentItemListing(req);
    if (res.status === 200) {
      manipulateData(res.data);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      // setSkeletonLoading(false);
    }
  };

  const manipulateData = (listing) => {
    let newArr = [];
    listing?.map((integration) =>
      integration?.ItemPrices?.map((integration2) => {
        newArr.push({
          ...integration,
          individualPrice: integration2.price,
          individualPriceID: integration2.id,
        });
      })
    );
    if (newArr) setRestaurentItemListing(newArr);
    // setSkeletonLoading(false);
  };

  const PosAllItems = async () => {
    let res = await getPosItemListing();
    if (res.status === 200) {
      setPosItemListing(res.data);
      // setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      // setLoading(false);
    }
  };

  const AlreadyMatchingItems = async () => {
    setSectionItemsLoading(true);
    let req = {
      type: 2,
      pageNumber: 1,
    };

    let res = await getRestaurentItemListing(req);
    if (res.status === 200) {
      manipulateDataForTable(res?.data);
      setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSycnedItemsPage(res.extraData);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionItemsLoading(false);
    }
  };

  const manipulateDataForTable = (listing) => {
    let newArr = [];
    listing?.map((integration) =>
      integration?.ItemPrices?.map((integration2) => {
        if (integration2.pos_item_price) {
          newArr.push({
            ...integration,
            individualPrice: integration2.price,
            individualPriceID: integration2.id,
            posPrice: integration2.pos_item_price,
            pos_item_id: integration2.pos_item_id,
            pos_item_name: integration2.pos_item_name,
          });
        }
      })
    );
    if (newArr) setsectionItemData(newArr);
    setSectionItemsLoading(false);
    // setSkeletonLoading(false);
  };

  // Yup is used for validation.
  let schemaQR = yup.object().shape({
    posTable: yup.object().required("Please selecte an table"),
    restaurentTable: yup.object().required("Please selecte an table"),
  });

  // This function is called to submit the form.

  const handleSubmitQrcode = async (values) => {
    setSkeletonLoadingForQr(true);
    let req = {
      pos_table_id: values.posTable.pos_table_id,
      qr_code_id: values.restaurentTable.id,
    };

    let res = await SyncingResTableWithPosTable(req);
    if (res.status === 200) {
      toast.info(res.message);
      AlreadyMatchingTable();
      RestaurentQRTableListing();
      PosQRTableListing();
      AlreadyMatchingItems();
      getProfileDetails();
      setSkeletonLoadingForQr(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSkeletonLoadingForQr(false);
    }
  };

  const RestaurentQRTableListing = async () => {
    let req = {
      pageNumber: 0,
      type: 2,
    };
    let res = await qrCodeListing(req);
    if (res.status === 200) {
      setRestaurentTableListing(res.data);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  const PosQRTableListing = async () => {
    let res = await getPosTableListing();
    if (res.status === 200) {
      setPosTableListing(res.data);
      // setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      // setLoading(false);
    }
  };

  const AlreadyMatchingTable = async () => {
    setSectionTableLoading(true);
    let params = new URLSearchParams();
    params.append("pageNumber", 1);
    params.append("perPage", 5);

    let res = await getlinkedQrcodeList(params.toString());
    if (res.status === 200) {
      setQrCodeData(res?.data);
      setSycnedTablePage(res?.extraData);
      // setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSectionTableLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionTableLoading(false);
    }
  };

  const getProfileDetails = async () => {
    let res = await profileDetails();
    if (res.status === 200) {
      dispatch(profileUpdated(res.data));
      setLoading(false);
    }
  };

  // Yup is used for validation.
  let schemaModifiers = yup.object().shape({
    posModifiers: yup.object().required("Please selecte an table"),
    restaurentModifiers: yup.object().required("Please selecte an table"),
  });

  // This function is called to submit the form.
  const handleSubmitschemaModifiers = async (values) => {
    setSkeletonLoadingForModifiers(true);
    let req = {
      option_item_id: values.restaurentModifiers.id,
      pos_option_item_id: values.posModifiers.pos_option_item_id,
      pos_option_item_price:
        values.posModifiers.pos_option_item_price.toString(),
      option_item_price: values.restaurentModifiers.price,
      pos_option_item_name: values.posModifiers.pos_option_item_name,
    };

    let res = await SyncingResModifierWithPosModifier(req);
    if (res.status === 200) {
      toast.info(res.message);
      RestaurentModifiers();
      PosModifiers();
      setSkeletonLoadingForModifiers(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSkeletonLoadingForModifiers(false);
    }
  };

  const updatePOSDB = async () => {
    setDataFechingFromDBLoader(true);
    let res = await updatePOSinDB();
    if (res.status === 200) {
      toast.info("Restuarant items updated with POS successfully");
      setDataFechingFromDBLoader(false);
      window.location.reload();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setDataFechingFromDBLoader(false);
      window.location.reload();
      toast.error(message);
    }
  };

  const updatePOSTableDB = async () => {
    setDataFechingFromDBLoader(true);
    let res = await updatePOSTableinDB();
    if (res.status === 200) {
      setDataFechingFromDBLoader(false);
      toast.info("Restuarant table updated with POS successfully");
      window.location.reload();
    } else {
      setDataFechingFromDBLoader(false);
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      window.location.reload();
    }
  };

  const updatePOSModifierDB = async () => {
    setDataFechingFromDBLoader(true);
    let res = await updatePOSModifiersinDB();
    if (res.status === 200) {
      toast.info("Restuarant modifiers updated with POS successfully");
      setDataFechingFromDBLoader(false);
      window.location.reload();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setDataFechingFromDBLoader(false);
      window.location.reload();
      toast.error(message);
    }
  };

  const PosModifiers = async () => {
    let res = await getPosModifierListing();
    if (res.status === 200) {
      setPosModifiersListing(res.data);
      // setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      // setLoading(false);
    }
  };

  const RestaurentModifiers = async () => {
    // setSkeletonLoading(true);
    let res = await getResturantModifierListing();
    if (res.status === 200) {
      setRestaurentModifiersListing(res.data);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      // setSkeletonLoading(false);
    }
  };

  const AlreadyMatchingModifiers = async () => {
    setSectionModifiersLoading(true);
    let params = new URLSearchParams();
    params.append("type", 2);
    let res = await getResturantModifierListing(params.toString());
    if (res.status === 200) {
      setModifiersData(res?.data);
      setSycnedModifierPage(res?.extraData);
      setSectionModifiersLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionModifiersLoading(false);
    }
  };

  const changePageOfSectionItems = async (pages) => {
    setSectionItemsLoading(true);
    let req = {
      type: 2,
      pageNumber: pages,
    };

    let res = await getRestaurentItemListing(req);
    if (res.status === 200) {
      manipulateDataForTable(res?.data);
      setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSycnedItemsPage(res.extraData);
      // setAllRecord(res.totalRecords);
      sectionItemsLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      sectionItemsLoading(false);
    }
    setSectionItemPagenumber(pages);
  };

  const getSearchOfSyncedItems = async (word) => {
    setSectionItemsLoading(true);
    const req = {
      search: word,
      pageNumber: sectionItempageNumber,
      perPage: 5,
    };

    let res = await searchSyncedItem(req);
    if (res.status === 200) {
      manipulateDataForTable(res?.data);
      setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSycnedItemsPage(res.extraData);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message, { theme: "colored" });
      setSectionItemsLoading(false);
    }
  };

  const changePageOfSectionTable = async (pages) => {
    setSectionTableLoading(true);
    setQrTablePagenumber(pages);
    let params = new URLSearchParams();
    params.append("pageNumber", pages);
    params.append("perPage", 5);

    let res = await getlinkedQrcodeList(params.toString());
    if (res.status === 200) {
      setQrCodeData(res?.data);
      setSycnedTablePage(res?.extraData);
      // setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSectionTableLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionTableLoading(false);
    }
  };

  const getSearchOfSyncedTable = async (word) => {
    setSectionTableLoading(true);

    let params = new URLSearchParams();
    params.append("search", word);
    params.append("pageNumber", qrTablepageNumber);
    params.append("perPage", 5);

    let res = await searchSyncedQrTable(params.toString());
    if (res.status === 200) {
      setQrCodeData(res?.data);
      setSycnedTablePage(res?.extraData);
      // setPosIsEnable(res?.pos_status?.pos_integration_status);
      setSectionTableLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionTableLoading(false);
    }
  };

  const changePageOfSectionModifiers = async (pages) => {
    setSycnedModifierPage(pages);
    setSectionModifiersLoading(true);
    let params = new URLSearchParams();
    params.append("type", 2);
    params.append("pageNumber", pages);
    params.append("perPage", 5);
    let res = await getResturantModifierListing(params.toString());
    if (res.status === 200) {
      setModifiersData(res?.data);
      setSycnedModifierPage(res?.extraData);
      setSectionModifiersLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionModifiersLoading(false);
    }
  };

  const getSearchOfSyncedModifiers = async (word) => {
    setSectionModifiersLoading(true);

    let params = new URLSearchParams();
    params.append("search", word);
    params.append("pageNumber", sycnedModifierPage);
    params.append("perPage", 5);

    let res = await searchSyncedModifier(params.toString());
    if (res.status === 200) {
      setModifiersData(res?.data);
      setSectionModifiersLoading(false);
      setSycnedModifierPage(res.extraData);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setSectionModifiersLoading(false);
    }
  };

  const TableAction = {
    apply: true,
    unlink: true,
    getPosStatus: getProfileDetails,
    positem: posItemListing,
    tableName: "ItemSyncing",
    RestaurentAllItems: AlreadyMatchingItems,
    PosAllItems: PosAllItems,
  };

  const TableQrAction = {
    apply: true,
    unlink: true,
    fetchData: AlreadyMatchingTable,
    positem: posItemListing,
    tableName: "QrSyncing",
    RestaurentQRTableListing: RestaurentQRTableListing,
    PosQRTableListing: PosQRTableListing,
    getPosStatus: getProfileDetails,
  };

  const TableModifierAction = {
    apply: true,
    unlink: true,
    fetchData: AlreadyMatchingModifiers,
    // positem: PosModifiers,
    tableName: "ModifierSyncing",
    RestaurentModifierListing: RestaurentModifiers,
    PosModifierListing: PosModifiers,
    getPosStatus: getProfileDetails,
  };

  useEffect(() => {
    //POS DETAILS
    getPOSInfo();

    //LINKING POS ITEMS TO RESTURANTS ITEMS
    RestaurentAllItems();
    PosAllItems();
    AlreadyMatchingItems();

    //LINKING POS TABLE TO RESTURANT TABLES
    RestaurentQRTableListing();
    PosQRTableListing();
    AlreadyMatchingTable();

    // LISTING MODIFIERS
    RestaurentModifiers();
    PosModifiers();
    AlreadyMatchingModifiers();

    //POS STATUS
    getProfileDetails();
  }, []);

  if (dataFechingFromDBLoader) {
    return (
      <LoadingWrapper>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      {openPosSetup && (
        <PosSetupDetails
          open={openPosSetup}
          handleClose={() => setOpenPosSetup(false)}
          fetchData={getPOSInfo}
        />
      )}

      {openPosEdit && (
        <EditPos
          open={openPosEdit}
          handleClose={() => setOpenPosEdit(false)}
          fetchData={getPOSInfo}
          data={posInfo}
        />
      )}
      {posIsEnable ? (
        <Alert
          sx={{ fontSize: 16, margin: "0 0 20px 0", background: "#d9f7d9" }}
          severity="success"
        >
          Sycned successfully — check it out!
        </Alert>
      ) : (
        <Alert
          sx={{ fontSize: 16, margin: "0 0 20px 0", background: "#f9e0de" }}
          severity="error"
        >
          POS disable now — Link All Items & Tables to enable it!
        </Alert>
      )}
      <SubHeader>
        <MicrossimphonyPOSbox>
          <p>Micros simphony POS </p>

          {permissionsData?.integration && (
            <div className="pos-orange-btns">
              <OrangeButton
                className="withAnimation"
                onClick={() => setMatchingModifiers(!matchingModifiers)}
              >
                {/* <IntlMessage id="button.Plans" /> */}
                Matching Modifiers
              </OrangeButton>

              <OrangeButton
                className="withAnimation"
                onClick={() => setMatchingQrCode(!matchingQrCode)}
              >
                {/* <IntlMessage id="button.Plans" /> */}
                Matching Qr Code
              </OrangeButton>

              <OrangeButton
                className="withAnimation"
                onClick={() => setMatchingPlatform(!matchingPlatform)}
              >
                {/* <IntlMessage id="button.Plans" /> */}
                Matching
              </OrangeButton>

              <OrangeButton onClick={() => setOpenPosSetup(true)}>
                {/* <IntlMessage id="button.Plans" /> */}
                Create POS setup
              </OrangeButton>
            </div>
          )}
        </MicrossimphonyPOSbox>
      </SubHeader>
      {matchingPlatform && (
        <BoxContainer className="withAnimation">
          <p>Matching Items</p>
          {skeletonLoading ? (
            <MatchingWrapper>
              <section>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
              </section>
              <div>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
              </div>
            </MatchingWrapper>
          ) : (
            <Formik
              initialValues={{
                posItem: "",
                restaurentItem: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              <Form>
                <MatchingWrapper>
                  <section>
                    <div>
                      <label>POS Items Listing</label>

                      <Field
                        name="posItem"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={posItemListing}
                        getOptionLabel={(option) =>
                          option
                            ? option?.pos_item_name +
                            " - " +
                            option?.pos_item_price
                            : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="POS"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>

                    <div>
                      <label>Aqlut Items Listing</label>

                      <Field
                        name="restaurentItem"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={restaurentItemListing}
                        getOptionLabel={(option) =>
                          option
                            ? option?.name + " - " + option?.individualPrice
                            : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Aqlut"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </section>
                  <div>
                    <BlackButton>Link</BlackButton>
                    <OrangeButton
                      style={{ background: "#979797" }}
                      onClick={() => setMatchingPlatform(false)}
                    >
                      Close
                    </OrangeButton>
                  </div>
                </MatchingWrapper>
              </Form>
            </Formik>
          )}
        </BoxContainer>
      )}

      {matchingQrCode && (
        <BoxContainer className="withAnimation">
          <p>Matching QR Tables</p>
          {skeletonLoadingForQr ? (
            <MatchingWrapper>
              <section>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
              </section>
              <div>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
              </div>
            </MatchingWrapper>
          ) : (
            <Formik
              initialValues={{
                posTable: "",
                restaurentTable: "",
              }}
              validationSchema={schemaQR}
              onSubmit={handleSubmitQrcode}
            >
              <Form>
                <MatchingWrapper>
                  <section>
                    <div>
                      <label>POS Table Listing</label>

                      <Field
                        name="posTable"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={posTableListing}
                        getOptionLabel={(option) =>
                          option
                            ? option?.string_text + " - " + option?.pos_table_id
                            : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="POS Table"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>

                    <div>
                      <label>Aqlut Table Listing</label>

                      <Field
                        name="restaurentTable"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={restaurentTableListing}
                        getOptionLabel={(option) =>
                          option ? option?.name : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Restuarant Table"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </section>
                  <div>
                    <BlackButton>Link</BlackButton>
                    <OrangeButton
                      style={{ background: "#979797" }}
                      onClick={() => setMatchingQrCode(false)}
                    >
                      Close
                    </OrangeButton>
                  </div>
                </MatchingWrapper>
              </Form>
            </Formik>
          )}
        </BoxContainer>
      )}

      {matchingModifiers && (
        <BoxContainer className="withAnimation">
          <p>Matching Modifiers</p>
          {skeletonLoadingForModifiers ? (
            <MatchingWrapper>
              <section>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
                <div>
                  <Skeleton animation="wave">
                    <div>
                      <label>POS Items Listing</label>
                    </div>
                    <p>Dummy</p>
                  </Skeleton>
                  <Skeleton animation="wave">
                    <div>
                      <label>Lorem Ipsum is simply dummy text of the ssa</label>
                    </div>
                    <p>Lorem</p>
                    <p>Lorem</p>
                    <p>Lorem</p>
                  </Skeleton>
                </div>
              </section>
              <div>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
                <Skeleton animation="wave">
                  <h1>Lorem</h1>
                  <p>Ipsum</p>
                </Skeleton>
              </div>
            </MatchingWrapper>
          ) : (
            <Formik
              initialValues={{
                posModifiers: "",
                restaurentModifiers: "",
              }}
              validationSchema={schemaModifiers}
              onSubmit={handleSubmitschemaModifiers}
            >
              <Form>
                <MatchingWrapper>
                  <section>
                    <div>
                      <label>POS Modifiers Listing</label>

                      <Field
                        name="posModifiers"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={posModifiersListing}
                        getOptionLabel={(option) =>
                          option
                            ? option?.pos_option_item_name +
                            " - " +
                            option?.pos_option_item_price
                            : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Pos Modifiers"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>

                    <div>
                      <label>Aqlut Modifiers Listing</label>

                      <Field
                        name="restaurentModifiers"
                        component={DependentField}
                        getChanges={() => function Close() { }}
                        options={restaurentModifiersListing}
                        getOptionLabel={(option) =>
                          option ? option?.name + " - " + option?.price : ""
                        }
                        renderInput={(params) => (
                          <div
                            ref={params.InputProps.ref}
                            style={{ position: "relative" }}
                          >
                            <SelectInput
                              placeholder="Restaurent Modifiers"
                              Dir={direction}
                              type="text"
                              {...params.inputProps}
                            />
                            <SelectIcon
                              className="icon-DropDownArrow"
                              dir="ltr"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </section>
                  <div>
                    <BlackButton>Link</BlackButton>
                    <OrangeButton
                      style={{ background: "#979797" }}
                      onClick={() => setMatchingPlatform(false)}
                    >
                      Close
                    </OrangeButton>
                  </div>
                </MatchingWrapper>
              </Form>
            </Formik>
          )}
        </BoxContainer>
      )}

      <BoxContainer className="withAnimation">
        {loading ? (
          <LoadingWrapper style={{ minHeight: "90px" }}>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <PosDetailsWrapper>
            <section>
              <div
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  alignItems: "baseline",
                }}
              >
                <label>POS URL:</label>
                {/* <span>{posInfo?.pos_url}</span> */}

                <p
                  style={{
                    cursor: "context-menu",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <Tooltip placement="topLeft" title={posInfo?.pos_url}>
                    {posInfo?.pos_url}
                  </Tooltip>
                </p>
              </div>

              <div>
                <label>Employee Number:</label>
                <span>{posInfo?.employee_no}</span>
              </div>

              <div>
                <label>Revenue Center Number:</label>
                <span>{posInfo?.revenue_center}</span>
              </div>

              <div>
                <label>Order ID (Tender media):</label>
                <span>{posInfo?.service_tender_media_id}</span>
              </div>

              <div>
                <label>Order Payment ID (Tender media):</label>
                <span>{posInfo?.payment_tender_media_id}</span>
              </div>

              <div>
                <label>Modifier Group ID (Major):</label>
                <span>{posInfo?.modifier_major_group_id}</span>
              </div>


              <div>
                <label>Takeaway check for order type:</label>
                <span>{posInfo?.takeaway_check_order_type}</span>
              </div>



              <div>
                <label>Delivery check for order type:</label>
                <span>{posInfo?.delivery_check_order_type}</span>
              </div>


            </section>
            <section className="edit-posbtnWrapper">
              {permissionsData?.integration && (
                <BlackButton onClick={() => setOpenPosEdit(true)}>
                  Edit POS
                </BlackButton>
              )}
            </section>
          </PosDetailsWrapper>
        )}
      </BoxContainer>

      <BoxContainer>
        <TableHeader>
          <MicrossimphonyPOSbox>
            <p>Sycned Items</p>

            <ButtonAndAlertBox>
              {permissionsData?.integration && (
                <OrangeButton
                  className="withAnimation"
                  onClick={() => updatePOSDB()}
                >
                  Update items with POS items
                </OrangeButton>
              )}

              {restaurentItemListing.length === 0 ? (
                <span className="successAlert">
                  <Alert sx={{ fontSize: 16 }} severity="success">
                    Sycned successfully — check it out!
                  </Alert>
                </span>
              ) : (
                <span className="blink">
                  <Alert sx={{ fontSize: 16 }} severity="warning">
                    POS disable now — Link All Items to enable it!
                  </Alert>
                </span>
              )}
            </ButtonAndAlertBox>
          </MicrossimphonyPOSbox>
        </TableHeader>

        <SearchFEwrapper
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "10px 0 0 0",
          }}
        >
          <SearchButton style={{ width: "50%" }}>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => getSearchOfSyncedItems(e.target.value)}
            />
          </SearchButton>
        </SearchFEwrapper>

        {sectionItemsLoading ? (
          <LoadingWrapper style={{ minHeight: "90px" }}>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {sectionItemData.length > 0 ? (
              <IntegrationTable
                header={MatchingDataHeader}
                tableData={sectionItemData}
                action={TableAction}
              />
            ) : (
              <NODATA>SORRY, You didn't sync any item</NODATA>
            )}
          </>
        )}

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <PaginationRounded
            PaginationRounded={sycnedItemsPage}
            onChangePage={(_page) => changePageOfSectionItems(_page)}
          />
        </div>
      </BoxContainer>

      <BoxContainer>
        <TableHeader>
          <MicrossimphonyPOSbox>
            <p>Sycned Table</p>

            <ButtonAndAlertBox>
              {permissionsData?.integration && (
                <OrangeButton
                  className="withAnimation"
                  onClick={() => updatePOSTableDB()}
                >
                  {/* <IntlMessage id="button.Plans" /> */}
                  Update table with POS table
                </OrangeButton>
              )}
              {restaurentTableListing.length === 0 ? (
                <span className="successAlert">
                  <Alert sx={{ fontSize: 16 }} severity="success">
                    Sycned successfully — check it out!
                  </Alert>
                </span>
              ) : (
                <span className="blink">
                  <Alert sx={{ fontSize: 16 }} severity="warning">
                    POS disable now — Link All Items to enable it!
                  </Alert>
                </span>
              )}
            </ButtonAndAlertBox>
          </MicrossimphonyPOSbox>
        </TableHeader>

        <SearchFEwrapper
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "10px 0 0 0",
          }}
        >
          <SearchButton style={{ width: "50%" }}>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => getSearchOfSyncedTable(e.target.value)}
            />
          </SearchButton>
        </SearchFEwrapper>

        {sectionTableLoading ? (
          <LoadingWrapper style={{ minHeight: "90px" }}>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {qrCodeData.length > 0 ? (
              <IntegrationTable
                header={MatchingQrTableHeader}
                tableData={qrCodeData}
                action={TableQrAction}
              />
            ) : (
              <NODATA>SORRY, You didn't sync any QR Table</NODATA>
            )}
          </>
        )}

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <PaginationRounded
            PaginationRounded={sycnedTablePage}
            onChangePage={(_page) => changePageOfSectionTable(_page)}
          />
        </div>
      </BoxContainer>

      <BoxContainer>
        <TableHeader>
          <MicrossimphonyPOSbox>
            <p>Sycned Modifiers</p>

            <ButtonAndAlertBox>
              {permissionsData?.integration && (
                <OrangeButton
                  className="withAnimation"
                  onClick={() => updatePOSModifierDB()}
                >
                  {/* <IntlMessage id="button.Plans" /> */}
                  Update modifiers with POS modifiers
                </OrangeButton>
              )}
              {restaurentModifiersListing.length === 0 ? (
                <span className="successAlert">
                  <Alert sx={{ fontSize: 16 }} severity="success">
                    Sycned successfully — check it out!
                  </Alert>
                </span>
              ) : (
                <span className="blink">
                  <Alert sx={{ fontSize: 16 }} severity="warning">
                    POS disable now — Link All Items to enable it!
                  </Alert>
                </span>
              )}
            </ButtonAndAlertBox>
          </MicrossimphonyPOSbox>
        </TableHeader>

        <SearchFEwrapper
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "10px 0 0 0",
          }}
        >
          <SearchButton style={{ width: "50%" }}>
            <i className="icon-Search" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => getSearchOfSyncedModifiers(e.target.value)}
            />
          </SearchButton>
        </SearchFEwrapper>

        {sectionModifiersLoading ? (
          <LoadingWrapper style={{ minHeight: "90px" }}>
            <CircularProgress sx={{ color: "#f55a2c" }} />
          </LoadingWrapper>
        ) : (
          <>
            {modifiersData.length > 0 ? (
              <IntegrationTable
                header={MatchingModifierHeader}
                tableData={modifiersData}
                action={TableModifierAction}
              />
            ) : (
              <NODATA>SORRY, You didn't sync any modifiers</NODATA>
            )}
          </>
        )}

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <PaginationRounded
            PaginationRounded={sycnedModifierPage}
            onChangePage={(_page) => changePageOfSectionModifiers(_page)}
          />
        </div>
      </BoxContainer>
    </div>
  );
}

const SearchFEwrapper = styled.div`
  display: flex;
  @media (max-width: 745px) {
    display: unset;
  }
`;

const SearchButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 13px 10px;
  margin: 0 6px 0 6px;
  cursor: pointer;

  i {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  input {
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 5px 0 6px;
    opacity: 0.9;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 745px) {
    margin: 10px 0px;
  }
`;

const PosDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    margin: 5px 0;
  }
  label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #000000;
  }

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #646464;
    margin: 0 10px;
  }

  @media (max-width: 837px) {
    flex-direction: column;
  }
  .edit-posbtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
  }
`;

const MatchingWrapper = styled.div`
  width: 100%;

  display: flex;
  section {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;

    button {
      whitespace: nowrap;
    }
  }

  label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #000000;
    margin: 0 10px;
  }
`;

const MicrossimphonyPOSbox = styled.div`
  width: 100%;
  display: flex;
  ${"" /* gap: 50px; */}
  justify-content: space-between;

  @media (max-width: 1255px) {
    flex-direction: column;
    gap: 0;
  }
`;

const ButtonAndAlertBox = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1255px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 655px) {
    margin-top: 5px;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  ${"" /* @media (max-width: 765px) {
    gap: 5px;
    grid-template-columns: repeat(1, 1fr);

    button {
      width: 100%;
    }
  } */
  }
`;
