import React, { useEffect, useState } from "react";
import {
  BoxContainer,
  FilterSection,
  LoadingWrapper,
  NODATA,
  OrangeButton,
  SubHeader,
} from "../../../style/Gobalstyle";
import IntlMessage from "../../../Utils/IntlMessage";
import { SearchButton, SearchFEwrapper } from "../../QRMenu/QRStyle";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  deleteMobileUser,
  listOfMobileUser,
} from "../../../services/Collection";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import { toast } from "react-toastify";
import CreateMobileUser from "./CreateMobileUser";
import EditMobileUser from "./EditMobileUser";
import Delete from "../../../components/Delete";
import ResetPasswordMobile from "./ResetPasswordMobile";

export default function MobileManagement() {
  const direction = useSelector((state) => state?.languageDirection?.direction);

  const [loading, setLoading] = useState(false);
  const [mobileManagmentData, setmobileManagementData] = useState([]);
  const [openCreateMobileUser, setOpenCreateMobileUser] = useState(false);
  const [openEditMobileUser, setOpenEditMobileUser] = useState(false);
  const [clickedUser, setClickedUser] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  const OpenCreateUser = () => {
    setOpenCreateMobileUser(true);
  };
  const OpenEditUser = (payload) => {
    setClickedUser(payload);
    setOpenEditMobileUser(true);
  };
  const OpenDeleteUser = (payload) => {
    setClickedUser(payload);
    setOpenDelete(true);
  };
  const OpenResetPassword = (payload) => {
    setClickedUser(payload);
    setOpenResetPassword(true);
  };
  const [searchData, setSearchData] = useState([]);
  const getWord = async (word) => {
    // setLoading(true);
    let handleCase = word?.toLowerCase();

    let filterResult = mobileManagmentData?.filter((el) => {
      if (
        el.name?.toLowerCase().includes(handleCase) ||
        el.email?.toLowerCase().includes(handleCase)
      ) {
        return el;
      }
    });
    setSearchData(filterResult);
    console.log(filterResult, "filtyersssssss");

    // let res = await searchUserManagement(req);
    // if (res.status === 200) {
    //   setuserManagementData(res.data);
    //   setUserManagmentPage(res.extraData);
    //   setLoading(false);
    // } else {
    //   const message = getErrorMessage(res, "Failed to connection");
    //   toast.error(message);
    //   setLoading(false);
    // }
  };

  const handleConfirm = async (id) => {
    console.log(id, "iddiididididi");
    let res = await deleteMobileUser(id);
    if (res.status === 200) {
      setLoading(false);
      setOpenDelete(false);
      getMobileUserListing();
      toast.info(res.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      setLoading(false);
      setOpenDelete(false);
      toast.error(message);
    }
  };

  const getMobileUserListing = async () => {
    setLoading(true);
    let res = await listOfMobileUser();
    if (res.status === 200) {
      setmobileManagementData(res.data);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMobileUserListing();
  }, []);

  return (
    <div>
      {openCreateMobileUser && (
        <CreateMobileUser
          open={openCreateMobileUser}
          handleClose={() => setOpenCreateMobileUser(false)}
          listingCalled={getMobileUserListing}
        />
      )}

      {openEditMobileUser && (
        <EditMobileUser
          open={openEditMobileUser}
          handleClose={() => setOpenEditMobileUser(false)}
          listingCalled={getMobileUserListing}
          payload={clickedUser}
        />
      )}

      {openDelete && (
        <Delete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          payload={clickedUser}
          handleConfirm={handleConfirm}
        />
      )}

      {openResetPassword && (
        <ResetPasswordMobile
          open={openResetPassword}
          handleClose={() => setOpenResetPassword(false)}
          payload={clickedUser}
        />
      )}

      <SubHeader>
        <p>
          {/* <IntlMessage id="userManagement.heading" /> */}
          Mobile Management
        </p>

        <OrangeButton onClick={OpenCreateUser}>
          <IntlMessage id="button.addUser" />
        </OrangeButton>
      </SubHeader>

      <BoxContainer>
        <FilterSection style={{ justifyContent: "end" }}>
          <SearchFEwrapper>
            <SearchButton>
              <i className="icon-Search" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => getWord(e.target.value)}
              />
            </SearchButton>
          </SearchFEwrapper>
        </FilterSection>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress sx={{ color: "#66433F" }} />
          </LoadingWrapper>
        ) : (
          <>
            {mobileManagmentData?.length <= 0 ? (
              <NODATA>
                <IntlMessage id="noData" />
              </NODATA>
            ) : (
              <CardWrapper>
                {searchData?.length > 0 ? (
                  <>
                    {searchData?.map((el) => (
                      <MobileManagementWrapper dir={direction}>
                        <span className="resetpassword">
                          <label onClick={() => OpenResetPassword(el)}>
                            Reset Password
                          </label>
                          <li></li>
                          <label onClick={() => OpenEditUser(el)}>Edit</label>
                          <li></li>
                          <label onClick={() => OpenDeleteUser(el)}>
                            Delete
                          </label>
                        </span>

                        <div>
                          <label>Username: </label>
                          <span>{el?.name}</span>
                        </div>

                        <div>
                          <label>Email: </label>
                          <span>{el?.email}</span>
                        </div>

                        <div>
                          <label>Password: </label>
                          <span>********</span>
                        </div>

                        <div>
                          <label>Designation: </label>
                          <span>
                            {el?.designation == 1 ? "Mobile Users" : "Others"}
                          </span>
                        </div>
                      </MobileManagementWrapper>
                    ))}
                  </>
                ) : (
                  <>
                    {mobileManagmentData?.length > 0 &&
                      mobileManagmentData?.map((el) => (
                        <MobileManagementWrapper dir={direction}>
                          <span className="resetpassword">
                            <label onClick={() => OpenResetPassword(el)}>
                              Reset Password
                            </label>
                            <li></li>
                            <label onClick={() => OpenEditUser(el)}>Edit</label>
                            <li></li>
                            <label onClick={() => OpenDeleteUser(el)}>
                              Delete
                            </label>
                          </span>

                          <div>
                            <label>Username: </label>
                            <span>{el?.name}</span>
                          </div>

                          <div>
                            <label>Email: </label>
                            <span>{el?.email}</span>
                          </div>

                          <div>
                            <label>Password: </label>
                            <span>********</span>
                          </div>

                          <div>
                            <label>Designation: </label>
                            <span>
                              {el?.designation == 1 ? "Mobile Users" : "Others"}
                            </span>
                          </div>
                        </MobileManagementWrapper>
                      ))}
                  </>
                )}
              </CardWrapper>
            )}
          </>
        )}
      </BoxContainer>
    </div>
  );
}

const MobileManagementWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: relative;
  padding: 20px;
  cursor: pointer;

  .resetpassword {
    display: flex;
    position: absolute;
    padding: 2px 12px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    background: #66433f;
    border-radius: 0px 6px;
    top: 0;
    // right: 0;
    left: ${({ dir }) => dir === "rtl" && 0};
    right: ${({ dir }) => dir === "ltr" && 0};

    label {
      cursor: pointer;
    }

    li {
      padding: 0 0 0 14px;
    }
  }

  div {
    padding: 5px 0;
    label {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #595959;
    }

    span {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #595959;
      opacity: 0.6;
      padding: 0 2px;
    }
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px 0 10px 0;

  @media (max-width: 895px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
